import { Component, isDevMode, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { Papa } from 'ngx-papaparse';
import { Router } from '@angular/router';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
@Component({
  selector: 'app-realtor-templates',
  templateUrl: './realtor-templates.component.html',
  styleUrls: ['./realtor-templates.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RealtorTemplatesComponent implements OnInit {

  lisitings: any;
  cols: any[];
  exportColumns: any[];
  userProfile: any;
  token: string = '';
  xClientId: string;

  displayBasic: boolean = false;
  displayAgents: boolean = false;
  displayShowings: boolean = false;
  displayAddTemplate: boolean = false;

  templateName: string = '';
  _firstName: string = '{ firstName }';
  _appointDateTime: string = '{ appointDateTime }';
  _propertyAddress: string = '{ propertyAddress }';
  _realtorNameAndAddress: string = '{ realtorNameAndAddress }';

  constructor(
    private primengConfig: PrimeNGConfig,
    private spinner: NgxSpinnerService,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private realtorService: RealtorService,
    private papa: Papa,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.token = sessionStorage.getItem('msal.idtoken');
  }

  ngOnInit(): void {
    if (isDevMode()) {
      this.xClientId = 'FEE8CC43-AFD8-4DA8-A4E3-6639745C4428';
    } else {
      this.xClientId = 'C606AF7F-9DA0-4360-B878-27DFA8A43238';
    }
    this.primengConfig.ripple = true;
    this.getUserDetails();
    this.getAllListings('all');
  }

  getUserDetails() {
    this.spinner.show();
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.professionalService.getUserInfo(url, this.token).subscribe(
      (response) => {
        this.userProfile = response;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getAllListings(commend) {
    let params = {
      cmd: commend,
      date: JSON.stringify(new Date()).slice(1, 11),
    };
    let url =
      this.environmentService.getIA9LightningUrl() +
      'dashboard/property/listings/status/' +
      params.cmd +
      '/' +
      params.date +
      '?code=' +
      this.environmentService.getIA9LightningKey();
    this.professionalService
      .getALlListing(url, this.token, this.xClientId)
      .subscribe(
        (response: any) => {
          this.lisitings = response.data;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  getDate(date) {
    if (date) {
      return new Date(date).toISOString().slice(0, 10);
    } else {
      return null;
    }
  }

  downloadListingAsPDF() {
    const csvColumns = [
      'LISTING ID',
      'ADDRESS',
      'SELLER',
      'LIST DATE',
    ];
    const csvRows: string[][] = [];
    let row: string[] = [];
    this.lisitings.forEach((x) => {
      row = [];
      row.push(x.listingId);
      row.push(x.address.full);
      row.push(x.seller?.sellerName);
      row.push(this.getDate(x.listDate));
      csvRows.push(row);
    });
    const reportCsv = this.papa.unparse({ fields: csvColumns, data: csvRows });
    const blob = new Blob([reportCsv], { type: 'text/csv; charset=utf-8' });
    FileSaver.saveAs(blob, 'Listing' + '.csv');
  }

  editTemplate() {
    this.displayBasic = true;
  }

  previewTemplate() {
    this.displayAgents = true;
  }

  addTemplate() {
    this.displayAddTemplate = true;
  }

  deleteTemplate() {
    this.confirmationService.confirm({
      message: 'Do you want to delete this template?',
      header: 'Delete Template',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted' });
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
            break;
        }
      }
    });
  }


}
