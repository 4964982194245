import { Component, OnInit } from '@angular/core';
import { ConsumerService } from '../../services/consumer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isDevMode } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-new-landing',
  templateUrl: './new-landing.component.html',
  styleUrls: ['./new-landing.component.scss'],
})
export class NewLandingComponent implements OnInit {
  isDevEnv: boolean;
  agentId: any = null;
  agentMid: any = null;
  agentOid: any = null;
  agentCode: any = null;
  agentInfo: any;
  mailText: string;
  queryParms: any;
  nerenForm: FormGroup;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private consumerService: ConsumerService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.isDevEnv = isDevMode();

    this.nerenForm = this.fb.group({
      businessName: ['', Validators.required],
      fullname: ['', Validators.required],
      mobileNo: ['', Validators.required],
      agentEmail: ['', Validators.email]
    });

    this.agentId = this.activatedRoute.snapshot.queryParams['aid'];
    this.agentMid = this.activatedRoute.snapshot.queryParams['mid'];
    this.agentCode = this.activatedRoute.snapshot.queryParams['code'];
    this.agentOid = this.activatedRoute.snapshot.queryParams['oid'];
    this.activatedRoute.queryParams.subscribe((result) => {
      this.queryParms = result;
    });
    if (this.agentId && this.agentMid) {
      let payload = { aid: this.agentId, mid: this.agentMid };
      this.consumerService.getAgentDetails(payload).subscribe((x) => {
        this.agentInfo = x.data;
        if (this.agentInfo.isEnrolled === true) {
          let url = "";
          let queryparam = `${"?oid=" + this.queryParms.oid + "&mid=" + this.queryParms.mid + "&aid=" + this.queryParms.aid + "&code=" + this.queryParms.code}`;
          if (isDevMode()) {
            url = "https://staging.domii.io/lightning-setup/setup" + queryparam;
          } else {
            url = "https://secure.domii.io/lightning-setup/setup" + queryparam;
          }
          window.open(url, "_self");
        } else {
          this.nerenForm.controls['businessName'].setValue(
            this.agentInfo.businessName
          );
          this.nerenForm.controls['fullname'].setValue(
            this.agentInfo.agentFirstName + ' ' + this.agentInfo.agentLastName
          );
          this.nerenForm.controls['mobileNo'].setValue(this.agentInfo.mobileNo);
          this.nerenForm.controls['agentEmail'].setValue(
            this.agentInfo.agentEmail
          );
        }
      });
    }
  }

  onSubmit() {
    let body: any = {};
    body.FirstName =
      this.agentInfo && this.agentInfo.agentFirstName
        ? this.agentInfo.agentFirstName
        : this.nerenForm.controls['fullname'].value;
    body.LastName =
      this.agentInfo && this.agentInfo.agentLastName
        ? this.agentInfo.agentLastName
        : '';
    body.Phone = this.nerenForm.controls['mobileNo'].value;
    body.Email = this.nerenForm.controls['agentEmail'].value;
    body.CompanyName = this.nerenForm.controls['businessName'].value;

    if (this.agentId) {
      this.consumerService
        .enrollAgentId(this.agentId, this.agentCode, body)
        .subscribe((x) => {
          let url = "";
          let queryparam = `${"?oid=" +
            this.queryParms.oid +
            "&mid=" +
            this.queryParms.mid +
            "&aid=" +
            this.queryParms.aid +
            "&code=" +
            this.queryParms.code
            }`;
          if (isDevMode()) {
            url = "https://staging.domii.io/lightning-setup/setup" + queryparam;
          } else {
            url = "https://secure.domii.io/lightning-setup/setup" + queryparam;
          }
          window.open(url, "_self");
        });
    } else {
      this.router.navigate(['/realtorsignup'], { queryParams: { data: JSON.stringify(body) }, skipLocationChange: true });
      // this.router.navigate([`/realtorsignup`], { state: { data: JSON.stringify(body) } });
    }
  }
}
