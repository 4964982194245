import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as dropin from 'braintree-web-drop-in';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MarketMiiService } from 'src/app/services/marketMii/market-mii.service';

@Component({
  selector: 'app-postcard-payment',
  templateUrl: './postcard-payment.component.html',
  styleUrls: ['./postcard-payment.component.scss'],
  providers: [ConfirmationService, MessageService]

})
export class PostcardPaymentComponent implements OnInit {

  xClientId: string;
  dropInInstance: dropin.DropinModel;
  validateCode: string = ''
  displayErrorPopup: boolean = false;
  errorMessage: string = '';
  userProfile: any;
  marketmiiDetails: any;
  realtorFN: string = '';
  _payload = {
    "PaymentMethodToken": '',
    "Code": '',
  };

  constructor(
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private marketMiiService: MarketMiiService
  ) {
    this.validateCode = this.route.snapshot.params['code'];
    this._payload.Code = this.validateCode;
    this.xClientId = this.environmentService.getXClientId();
  }

  ngOnInit(): void {
    this.validate();
  }

  validate() {
    let url = this.environmentService.getIA9LightningUrl() + 'postcard/postcardverify/' + this.validateCode + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.marketMiiService.validateCode(url, this.xClientId).subscribe(
      (response: any) => {
        this.marketmiiDetails = response.data;
        this.realtorFN = response.data['firstname'] + ' ' + response.data['lastName'];
        this.createDropIn();
        this.spinner.hide();
      },
      (error) => {
        if (error.error.statusCode === 2003) {
          this.router.navigate(['/postcard/messages'], {
            state: {
              message: error.error.description,
            }
          });
        } else if (error.error.statusCode === 12007) {
          this.router.navigate(['/postcard/messages'], {
            state: {
              message: error.error.description,
            }
          });
        }
        this.spinner.hide();
      }
    );
  }

  onSubmit(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dropInInstance.requestPaymentMethod().then((payload) => {
          this._payload.PaymentMethodToken = payload.nonce;
          const url = this.environmentService.getIA9LightningUrl() + 'payment/paymentpostcard?code=' + this.environmentService.getIA9LightningKey();
          this.spinner.show();
          this.marketMiiService.postcardPayment(url, this._payload, this.xClientId).subscribe((response) => {
            this.spinner.hide();
            if (response['isSuccess'] === true) {
              this.router.navigate(['/postcard/messages'], {
                state: {
                  message: response['description'],
                }
              });
            }
          },
            (error) => {
              this.displayErrorPopup = true;
              this.spinner.hide();
            });
        }).catch((error) => { console.log(error) });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected.' });
      }
    });
  }


  createDropIn() {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
    this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        dropin.create({
          authorization: response.data,
          container: document.getElementById('dropin-container'),
          card: {
            cardholderName: {
              required: true
            }
          }
        }).then((dropinInstance) => {
          this.dropInInstance = dropinInstance;
        }).catch((error) => { console.log(error) });
      }
    }, (error) => {
      console.log(error);
    });
  }
}