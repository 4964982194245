import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HubspotContactModel } from '../../models/hubspotContactModel';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HubspotService {

  constructor(private http: HttpClient) { }

  createContact(payload: HubspotContactModel, uriPath: string) {
    if (payload.mls_affliation == null) {
      payload.mls_affliation = "none";
    }
    var data = {
      "fields": [
        {
          "name": "firstname",
          "value": payload.firstname
        },
        {
          "name": "lastname",
          "value": payload.lastname
        },
        {
          "name": "company",
          "value": payload.company
        },
        {
          "name": "mls_affliation",
          "value": payload.mls_affliation
        },
        {
          "name": "mobilephone",
          "value": payload.mobilephone
        },
        {
          "name": "email",
          "value": payload.email
        },
        {
          "name": "bt_plan",
          "value": payload.bt_plan
        },
        {
          "name": "bt_plan_id",
          "value": payload.bt_plan_id
        },
        {
          "name": "professional_type",
          "value": payload.professional_type
        },
        {
          "name": "address",
          "value": payload.address
        },
        {
          "name": "city",
          "value": payload.city
        },
        {
          "name": "state",
          "value": payload.state
        },
        {
          "name": "zip_code",
          "value": payload.zip_code
        }
      ],
      "context": {
        "hutk": this.getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        "pageUri": window.location.origin + uriPath,// todo update this based on environment.
        "pageName": "Domii Professional Signup"
      }
    }
    var finalPayload = JSON.stringify(data);
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    var url = environment.HubspotSignupFormUrl;
    return this.http.post(url, finalPayload, { headers });
  }

  submitCancellationRequest(firstName: string, lastName: string, email: string, mobile: string, reason: string) {
    var data = {
      "fields": [
        {
          "name": "firstname",
          "value": firstName
        },
        {
          "name": "lastname",
          "value": lastName
        },
        {
          "name": "mobilephone",
          "value": mobile
        },
        {
          "name": "email",
          "value": email
        },
        {
          "name": "message",
          "value": reason
        }
      ],
      "context": {
        "hutk": this.getCookie("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        "pageUri": window.location.origin + "/profile",
        "pageName": "Domii Cancel Subscription Request"
      }
    }
    var finalPayload = JSON.stringify(data);
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    var url = environment.HubspotCancelPlanRequestFormUrl;
    return this.http.post(url, finalPayload, { headers });
  }

  // Given a cookie key `name`, returns the value of
  // the cookie or `null`, if the key is not found.
  // name: hubspotutk for hubspot
  getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(";")
        .map(c => c.trim())
        .filter(cookie => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }
}
