import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from './services/environment/environment.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent {
  title = 'domiiSite-ui';
  imageBasePath = './assets/images/';
  code: any = '';
  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private environmentService: EnvironmentService,
    private titleService: Title,
    public router: Router,
  ) {
    this._document.getElementById('favIcon').setAttribute('href', this.imageBasePath + this.environmentService.getFavicon());
    this.titleService.setTitle(this.environmentService.getTitle());

    const currentUrl = this.router.url;
    
    if (!currentUrl.startsWith('/upgrade/payment/')) {
      this.titleService.setTitle('domii® - The Ultimate Real Estate Leads Prospector - Membership Pricing Plan');
    }

    if (this.router.url === '/dashboard/open-house') {
      this.router.navigate(['/dashboard/open-house'], { queryParams: { aid: '', X_CLIENT_ID: this.environmentService.getXClientId() } });
    }
  }
}