import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as dropin from 'braintree-web-drop-in';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-renew-membership-plan',
  templateUrl: './renew-membership-plan.component.html',
  styleUrls: ['./renew-membership-plan.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RenewMembershipPlanComponent implements OnInit {

  membershipRealtorsPlans: [] = [] as any;
  xClientId: string;
  dropInInstance: dropin.DropinModel;
  token: string = '';
  _email: string = '';
  _agentId: string = '';
  activeMembership: any;
  
  _payload = {
    "email": '',
    "paymentMethodToken": '',
    "MembershipPlanCode": '',
    "AgentId": ''
  }
  displayErrorPopup: boolean = false;

  constructor(
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private realtorService: RealtorService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.route.queryParams
      .subscribe(params => {
        this._email = params.email;
        this._agentId = params.agentid;
        this.activeMembership = params.activeMembership;
      });
    this._payload.email = this._email;
    this._payload.AgentId = this._agentId;

    this.token = sessionStorage.getItem('msal.idtoken');
    this.xClientId = this.environmentService.getXClientId();
  }

  ngOnInit(): void {
    this.createDropIn();
    this.retrievePlans();
  }

  createDropIn() {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
    this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        dropin.create({
          authorization: response.data,
          container: document.getElementById('dropin-container')
        }).then((dropinInstance) => {
          this.dropInInstance = dropinInstance;
        }).catch((error) => { console.log(error) });
      }
    }, (error) => {
      console.log(error);
    });
  }

  retrievePlans() {
    const url = this.environmentService.getIA9LightningUrl() + 'payment/realtorupgrade/plans?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.professionalService.getMembershipPlansRealtors(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        this.membershipRealtorsPlans = response?.data;
        this.spinner.hide();
      }
    }, (error) => {
      this.spinner.hide();
    });
  }

  radioChecked(data, i) {
    this.membershipRealtorsPlans.forEach((item: any) => {
      if (item.uid !== data.uid) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    })
    this._payload.MembershipPlanCode = data.code;
  }

  onSubscribe(event: Event) {
    if (this._payload.MembershipPlanCode === '') {
      this.toastr.warning('Please select a plan!..');
      return false;
    }
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dropInInstance.requestPaymentMethod().then((payload) => {
          this._payload.paymentMethodToken = payload.nonce;
          const url = this.environmentService.getIA9LightningUrl() + 'payment/subscribe?code=' + this.environmentService.getIA9LightningKey();
          this.spinner.show();
          this.realtorService.subscribePlan(url, this._payload, this.xClientId, this.token).subscribe((response) => {
            this.spinner.hide();
            if (response['isSuccess'] === true) {
              this.messageService.add({ severity: 'info', summary: 'Subscribe', detail: 'Subscribed to new membership plan.' });
              // this.router.navigate(['/dashboard']);
              this.router.navigate(['/membership/realtors']);
            }
          },
            (error) => {
              this.displayErrorPopup = true;
              this.spinner.hide();
            });
        }).catch((error) => { console.log(error) });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected.' });
      }
    });
  }
}
