import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RealtorService } from '../../services/realtors/realtor.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { CommonConstants } from 'src/app/constants/commonConstants';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddZipCodeModel } from 'src/app/models/addZipCodeModel';
import { DatePipe } from '@angular/common';
import {
	FormBuilder,
	FormGroup,
	FormArray,
	FormControl,
	ValidatorFn,
	Validators
} from '@angular/forms';

@Component({
	selector: 'app-add-zip-codes',
	templateUrl: './add-zip-codes.component.html',
	styleUrls: ['./add-zip-codes.component.scss'],
})
export class AddZipCodesComponent implements OnInit {
	submitted: boolean = false;
	xClientId: string;
	agentCode: string = '';
	fullName: string = '';
	emailAddress: string = '';
	errorMessage: string = '';
	allowMoreZipCodes: boolean;
	url: string = '';
	hasAtLeastOneValue: boolean;
	hasValidZipcodeFormat: boolean;

	toastVisible = false;
	toastMessage = '';
	toastType = '';

	androidStoreUrl:string;
	iOSStoreUrl:string;
	year: string;
	companyBrand: string = '';

	keyValueFA = new FormArray([this.newKeyValueFG]);

	addZipCodesForm = new FormGroup({
		keyValue: this.keyValueFA,
	});

	constructor(
		private realtorService: RealtorService,
		private environmentService: EnvironmentService,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private fb: FormBuilder,
		private datePipe: DatePipe
	) {
		this.agentCode = this.route.snapshot.queryParams['code'];
		this.xClientId = this.environmentService.getXClientId();
		this.companyBrand = CommonConstants.imageBasePath + this.environmentService.getCompanyBrand();
	}

	ngOnInit() {
		this.allowMoreZipCodes = true;
		this.url = this.environmentService.getIA9LightningUrl() + 'agent/my?agentCode=' + this.agentCode + '&code=' + this.environmentService.getIA9LightningKey();
		this.spinner.show();
		this.realtorService.validateCode(this.url, this.xClientId).subscribe((response: any) => {
			this.spinner.hide();
			this.allowMoreZipCodes = response.data.allowMoreZipCodes;
			this.fullName = `${response.data.firstName} ${response.data.lastName}`
			this.emailAddress = `${response.data.email}`
		},
		(error) => {
			this.spinner.hide();
			this.errorMessage = error.error.description;
			document.getElementById('openAddZipCodeFailedBtn').click();
		});

		for (let i = 0; i < 4; i++) {
			this.addNewKeyValueFG();
		}

		this.androidStoreUrl=this.environmentService.getAndroidStoreUrl();
		this.iOSStoreUrl=this.environmentService.getiOSStoreUrl();
		this.year = this.datePipe.transform(new Date(), 'yyyy');
	}

	onSubmit() {
		const regexPattern = /^\d{5}(?:[-\s]\d{4})?$/;
		const zipCodeValue = this.addZipCodesForm.getRawValue().keyValue.filter(obj => (obj.zipCode > ""));
		const zipCodeValueIsNumeric = this.addZipCodesForm.getRawValue().keyValue.filter(obj => {
			return (obj.zipCode !== null && regexPattern.test(obj.zipCode) === false)
		});
		
		this.hasAtLeastOneValue = true;
		this.hasValidZipcodeFormat = true;
		this.submitted = true;
		if(zipCodeValue.length === 0){
			this.hasAtLeastOneValue = false;
			return;
		}

		if(zipCodeValueIsNumeric.length !== 0){
			this.hasValidZipcodeFormat = false;
			return;
		}
		
		const addZipCode = new AddZipCodeModel();
		this.hasAtLeastOneValue = true;
		this.hasValidZipcodeFormat = true;
		this.spinner.show();
		addZipCode.zipCodes = zipCodeValue.map(obj => obj.zipCode);
		addZipCode.personId = "";
		this.realtorService.addZipCode(this.url, addZipCode, this.xClientId).subscribe((response: any) => {
			this.spinner.hide();
			if(response.isSuccess){
				document.getElementById('openAddZipCodeSuccessBtn').click();	
			}else{
				this.errorMessage = "There was a problem submitting your request. Try again later.";
				document.getElementById('openAddZipCodeFailedBtn').click();
			}
		},
		(error) => {
			this.spinner.hide();
			this.errorMessage = "There was a problem submitting your request. Try again later.";
			document.getElementById('openAddZipCodeFailedBtn').click();
		});
	}

	get newKeyValueFG(): FormGroup {
		return new FormGroup({
			zipCode: new FormControl(null),
		});
	}

	get keyValueArrayFGControls(): FormGroup[] {
		return this.keyValueFA.controls as FormGroup[];
	}

	addNewKeyValueFG(): void {
		this.keyValueFA.push(this.newKeyValueFG);
	}

	removeNewKeyValueFG(index: number): void {
		this.keyValueFA.removeAt(index);
	}

	openAppStore(){
		window.open(this.iOSStoreUrl);
	}

	openPlayStore(){
		window.open(this.androidStoreUrl);
	}
}
