<p-toast></p-toast>
<section class="h-100vh page_wrapper">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <!-- <div class="col-lg-2 col-md-0"></div> -->
            <div class="col-lg-4 col-md-5 col-xs-12 pt-5 textCenter backgroundWhite welcome_left h-100vh">
                <div [hidden]="isIOS">
                    <h2 class="wlcmMsg">Welcome <br /> Aboard! </h2>
                    <p class="first_para">
                        Simply, choose your <br /> membership type and <br /> you're in. </p>
                    <p class="second_para">It's that easy!</p>
                    <img src="../../../assets/images/logo_left.png" class="domii_logo" alt="Logo">
                </div>
                <div [hidden]="!isIOS">
                    <h2 class="wlcmMsg">Welcome <br /> Aboard! </h2>
                    <p class="first_para">
                        To Login, tap the <br /> blue <a (click)="login()" class="text-blue" style="color: #0047AB; font-weight: bold;">OPEN</a> button above.</p>
                    <p class="second_para">Or, to create a new account, select <br/> your membership type...</p>
                    <img src="../../../assets/images/logo_left.png" class="domii_logo" alt="Logo">
                </div>
                <ul class="p-0">
                    <li>Customer Service: <a href="tel:8663664446">1-866-366-4446</a></li>
                    <li><a href="https://domii.io/">Domii.io</a></li>
                    <li><a href="mailto:support@ia9inc.com">support@ia9inc.com</a></li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-5 col-xs-12 backgroundWhite h-100vh welcome_right">
                <div class="col-lg-12 col-md-12 card noBorder top-margin">
                    <div class="card-body mt-3">
                        <img class="mb-5 d-block text-center ml-auto mr-auto"
                            src="../../../assets/images/logo_right.png" alt="landing page image">
                        <h3 class="text-center"> Let's Get You Started!</h3>
                        <ul class="text-left pl-md-4 pl-0 mt-4 mb-4">
                            <li> Select your membership type</li>
                            <li>Answer a few questions</li>
                            <li>Enjoy your personalized, real estate app, built especially for you!</li>
                        </ul>
                        <div>
                            <div class="card martop20px navCard" (click)="navigateRealtorSignup()">
                                <div class="card-body flexDisplay">
                                    <div>
                                        <img style="height: 60px;width: 60px;"
                                            src="../../../assets/images/realtor_landing.svg">
                                        <span class="userType">Real Estate Agents - Signup</span>
                                    </div>
                                    <div class="arrow">
                                        <img src="../../../assets/images/go_to_arrow.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="card martop20px navCard" (click)="navigateProSignup()">
                                <div class="card-body flexDisplay">
                                    <div>
                                        <img style="height: 60px;width: 60px;"
                                            src="../../../assets/images/prof_landing.svg">
                                        <span class="userType">Other Professionals - Signup</span>
                                    </div>
                                    <div class="arrow">
                                        <img src="../../../assets/images/go_to_arrow.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="card martop20px navCard" (click)="navigateConsumerSignup()">
                                <div class="card-body flexDisplay">
                                    <div>
                                        <img style="height: 60px;width: 60px;"
                                            src="../../../assets/images/consumer_landing.svg">
                                        <span class="userType">FREE Buyer/Seller - Signup</span>
                                    </div>
                                    <div class="arrow">
                                        <img src="../../../assets/images/go_to_arrow.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="martop20px font16px text-center">
                            Already have an account?
                            <span class="tredText" (click)="login()">
                                <strong>Sign in here</strong>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-0"></div> -->
        </div>
    </div>
</section>
<app-footer></app-footer>
