import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  hostCompany: string = '';
  androidStoreUrl: string = '';
  iOSStoreUrl: string = '';
  constructor() {
    if (location.origin.endsWith('domii.io')) {
      this.hostCompany = 'domii';
    }
    else if (location.origin.endsWith('cocoearly.com')) {
      this.hostCompany = 'cocoearly';
    }
  }

  

  getAndroidStoreUrl() {
    if (this.hostCompany == 'domii') {
      return 'https://play.google.com/store/apps/details?id=com.ia9.mobile';
    }
    else if (this.hostCompany == 'cocoearly') {
      return 'https://play.google.com/store/apps/details?id=com.ia9.cocoearly';
    }
    else {
      return 'https://play.google.com/store/apps/details?id=com.ia9.mobile';
    }
  }

  getiOSStoreUrl() {
    if (this.hostCompany == 'domii') {
      return 'https://apps.apple.com/us/app/domii/id1467412622';
    }
    else if (this.hostCompany == 'cocoearly') {
      return 'https://apps.apple.com/us/app/cea-search/id1550866357';
    }
    else {
      return 'https://apps.apple.com/us/app/domii/id1467412622';
    }
  }

  getFavicon() {
    if (this.hostCompany == 'domii') {
      return 'favicon_domii.ico';
    }
  }

  getTitle() {
    if (this.hostCompany == 'domii') {
      return 'Account Domii';
    }
    else if (this.hostCompany == 'cocoearly') {
      return 'Account Cocoearly';
    }
  }

  getXClientId() {
    if (this.hostCompany == 'domii') {
      return environment.XClientId_Domii;
    }
    else if (this.hostCompany == 'cocoearly') {
      return environment.XClientId_CoCoEarly;
    }
    else {
      return environment.XClientId_Domii;
    }
  }

  getCompanyLogo() {
    if (this.hostCompany == 'domii') {
      return 'domii_logo.svg';
    }
    else if (this.hostCompany == 'cocoearly') {
      return 'coco_early_logo.svg';
    }
    else {
      return 'domii_logo.svg';
    }
  }

  getCompanyBrand() {
    if (this.hostCompany == 'domii') {
      return 'domii_brand_white.svg';
    }
    else if (this.hostCompany == 'cocoearly') {
      return 'coco_early_brand.svg';
    }
    else {
      return 'domii_brand_white.svg';
    }
  }

  getIA9LightningUrl() {
    return environment.IA9LightningUrl;
  }

  getIA9LightningKey() {
    return environment.IA9LightningKey;
  }

  getIA9V2Url() {
    return environment.IA9V2Url;
  }

  getIA9V2Key() {
    return environment.IA9V2Key;
  }

  getIA9CoreUrl() {
    return environment.IA9CoreUrl;
  }

  getIA9CoreKey() {
    return environment.IA9CoreKey;
  }

  getFreeRedirectUrl(){
    return environment.FreeBuyerRedirectUrl;
  }

  getEULAUrl() {
    if (this.hostCompany == 'domii') {
      return environment.EULA_Domii;
    }
    else if (this.hostCompany == 'cocoearly') {
      return environment.EULA_CoCoEarly;
    }
    else {
      return environment.EULA_Domii;
    }
  }

  getPrivacyUrl() {
    if (this.hostCompany == 'domii') {
      return environment.Privacy_Domii;
    }
    else if (this.hostCompany == 'cocoearly') {
      return environment.Privacy_CoCoEarly;
    }
    else {
      return environment.Privacy_Domii;
    }
  }
}
