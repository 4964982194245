import { Component, isDevMode, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CompanyLogoFileModel, CompanyOnboardingModel } from 'src/app/models/companyOnboardingModel';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { LoginService } from 'src/app/services/login/login.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { WhiteLabellingService } from 'src/app/services/whitelablling/white-labelling.service';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss'],
  providers: [MessageService]
})
export class OnboardComponent implements OnInit {

  whiteLabelAdminOnboardingForm: FormGroup;
  submitted = false;
  xClientId: string;
  fileName: any;
  // welcomePopup: boolean = false;
  validateCode: string = ''
  membershipPatchedData: any;
  token: string = '';
  userProfile: any;
  accessPage: boolean = false;
  companyAdmin: boolean = false;
  displayErrorPopup: boolean = false;
  imageChanged: boolean = false;
  onboardPayload = new CompanyOnboardingModel();
  imageData: any;

  constructor(
    private formBuilder: FormBuilder,
    private environmentService: EnvironmentService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private wlService: WhiteLabellingService,
    private messageService: MessageService,
    private professionalService: ProfessionalService,
    private loginService: LoginService) {
    this.token = sessionStorage.getItem('msal.idtoken');
    this.validateCode = this.route.snapshot.params['code'];
  }

  ngOnInit(): void {
    if (!this.token) {
      setTimeout(() => {
        this.loginService.login();
      }, 0);
      return;
    }
    this.onBoardingForm();
    this.getUserDetails();
  }

  getUserDetails() {
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.spinner.show();
    this.professionalService.getUserInfo(url, this.token).subscribe(
      response => {
        this.spinner.hide();
        this.userProfile = response;
        if (this.userProfile?.UserType === 'CompanyAdmin') {
          this.xClientId = this.userProfile?.MembershipId;
          this.verifyCode();
        } else {
          this.companyAdmin = true;
          return false;
        }
      },
      error => {
        this.displayErrorPopup = true;
        this.spinner.hide();
      });
  }

  onBoardingForm() {
    this.whiteLabelAdminOnboardingForm = this.formBuilder.group({
      companyName: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      telno: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      website: new FormControl('', [Validators.required]),
      fax: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      logo: new FormControl(''),
      verificationCode: new FormControl(''),

      facebook: new FormControl(''),
      instagram: new FormControl(''),
      twitter: new FormControl(''),
      youtube: new FormControl(''),
      pinterest: new FormControl('')
    });
  }

  get form() { return this.whiteLabelAdminOnboardingForm.controls; }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.fileName = file.name;
      const index = this.fileName.lastIndexOf('.') + 1;
      const ext = this.fileName.substr(index, this.fileName.length).toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png') {
        reader.readAsDataURL(file);
        this.imageChanged = true;
        reader.onloadend = () => {
          this.whiteLabelAdminOnboardingForm.patchValue({
            logo: (reader.result as string)
          });
          this.imageData = (reader.result as string);
        };
      }
    }
  }

  // welcomePage() {
  //   this.welcomePopup = false
  //   this.router.navigate(['/admin']);
  // }

  // Verify Code
  verifyCode() {
    let url = this.environmentService.getIA9LightningUrl() + 'whitelabel/membershipverify/' + this.validateCode + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.wlService.verifyMembership(url, this.token, this.xClientId).subscribe(
      (response: any) => {
        if(this.xClientId !== response.data.uid){
          this.displayErrorPopup = true;
          this.spinner.hide();
          return;
        }
        if(response.data.isApproved){
          this.spinner.hide();
          sessionStorage.setItem('X_CLIENT_ID',this.userProfile.MembershipId);
          this.router.navigate(['/admin']);
        }
        this.membershipPatchedData = response.data;
        this.whiteLabelAdminOnboardingForm.get("companyName").patchValue(response.data.companyName);
        this.whiteLabelAdminOnboardingForm.get("address").patchValue(response.data.address);
        this.whiteLabelAdminOnboardingForm.get("telno").patchValue(response.data.mobilePhoneNumber);
        this.whiteLabelAdminOnboardingForm.get("website").patchValue(response.data.websiteUrl);
        this.whiteLabelAdminOnboardingForm.get("fax").patchValue(response.data.faxNumber);
        this.whiteLabelAdminOnboardingForm.get("email").patchValue(response.data.email);
        this.whiteLabelAdminOnboardingForm.get("verificationCode").patchValue(response.data.code);
        this.whiteLabelAdminOnboardingForm.get("facebook").patchValue(response.data.facebookUrl);
        this.whiteLabelAdminOnboardingForm.get("instagram").patchValue(response.data.instagramUrl);
        this.whiteLabelAdminOnboardingForm.get("twitter").patchValue(response.data.twitterUrl);
        this.whiteLabelAdminOnboardingForm.get("youtube").patchValue(response.data.youtubeUrl);
        this.whiteLabelAdminOnboardingForm.get("pinterest").patchValue(response.data.pintrestUrl);
        this.imageData = response.data.imageLogoUrl;
        var stringArray = response.data.imageLogoUrl.split('/');
        var logo = stringArray[stringArray.length - 1];
        var logoName = logo.split('?')
        this.fileName = logoName[0];
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Code Verified Successfully!..' });
        this.spinner.hide();
      },
      (error) => {
        this.displayErrorPopup = true;
        this.spinner.hide();
        if (error.error.statusCode === 2006) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Code!..' });
        }
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.whiteLabelAdminOnboardingForm.invalid) {
      return;
    }

    this.onboardPayload.IsApproved = true;
    this.onboardPayload.CompanyName = this.whiteLabelAdminOnboardingForm.get('companyName').value;
    this.onboardPayload.Address = this.whiteLabelAdminOnboardingForm.get('address').value;
    this.onboardPayload.MobilePhoneNumber = this.whiteLabelAdminOnboardingForm.get('telno').value;
    this.onboardPayload.Website = this.whiteLabelAdminOnboardingForm.get('website').value;
    this.onboardPayload.FaxNumber = this.whiteLabelAdminOnboardingForm.get('fax').value;
    this.onboardPayload.CompanyEmailAddress = this.whiteLabelAdminOnboardingForm.get('email').value;

    if(this.imageChanged){
    this.onboardPayload.CompanyLogoFile = new CompanyLogoFileModel();
    this.onboardPayload.CompanyLogoFile.description = 'company logo';
    this.onboardPayload.CompanyLogoFile.fileName = this.fileName;
    this.onboardPayload.CompanyLogoFile.docType = 'Logo';
    this.onboardPayload.CompanyLogoFile.base64 = this.whiteLabelAdminOnboardingForm.get('logo').value;
    }
    this.onboardPayload.FacebookUrl = this.whiteLabelAdminOnboardingForm.get('facebook').value;
    this.onboardPayload.InstagramUrl = this.whiteLabelAdminOnboardingForm.get('instagram').value;
    this.onboardPayload.TwitterUrl = this.whiteLabelAdminOnboardingForm.get('twitter').value;
    this.onboardPayload.YoutubeUrl = this.whiteLabelAdminOnboardingForm.get('youtube').value;
    this.onboardPayload.PintrestUrl = this.whiteLabelAdminOnboardingForm.get('pinterest').value;

    this.spinner.show();
    let url = this.environmentService.getIA9LightningUrl() + 'whitelabel/membershipupdate' + '?code=' + this.environmentService.getIA9LightningKey();
    this.wlService.updateMembership(url, this.onboardPayload, this.token, this.xClientId).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Membership Updated Successfully!..' });
        sessionStorage.setItem('X_CLIENT_ID',this.userProfile.MembershipId);
        this.router.navigate(['/admin']);
      },
      (error) => {
        this.displayErrorPopup = true;
        this.spinner.hide();
        if (error.error.statusCode === 2006) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!..' });
        }
      });
  }

  backToHome() {
    this.companyAdmin = false;
    sessionStorage.removeItem("userType");
    this.loginService.logout();
  }
}
