<!-- <h1>Page Not Found!</h1> -->
<div class="cardContainer">
    <div class="card">
        <div class="card-body">
            <img src="../../../assets/images/server_down.svg" alt="server error">
            <div class="textCenter title">Oops!!</div>
            <div class="textCenter martop10px">Something Went Wrong</div>
            <div class="textCenter martop10px">Please try again later</div>
        </div>
    </div>
</div>