import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarketMiiService {

  constructor(
    private http: HttpClient) { }


  postcardPayment(url, body, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
    return this.http.post(url, body, { headers: headers });
  }

  validateCode(url, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }
}