<app-header></app-header>
<p-confirmPopup></p-confirmPopup>
<div class="back_btn">
    <a routerLink="/dashboard">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="DashmainContainer">
    <div class="col-lg-12">
        <div class="detail">
            <section>
                <div>
                    <p class="title">Testimonials</p>
                    <p class="sub-title">You have {{testimonials.length}} testimonials</p>
                </div>
                <div>
                    <button class="btn export_btn" (click)="export()">
                        <img src="../../../assets/images/ic-file-download.svg" alt="">Export
                    </button>
                </div>
            </section>
        </div>
        <p-card header="" subheader="" styleClass="p-card-shadow" *ngFor="let testimonial of testimonials">
            <ng-template pTemplate="header">
                <div class="header-image">
                    <img alt="Card" src="../../../assets/images/Group.svg">
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="card-main-content">
                    <p class="testimony">{{testimonial.testimony}}</p>
                    <p class="testimony-date">{{testimonial.createdDate | date: 'yyyy-dd-MM'}}</p>
                </div>
            </ng-template>
            <ng-template pTemplate="footer" styleClass="footer-template">
                <hr>
                <section class="footer-section">
                    <div class="footer-section-1">
                        <p class="p-1">{{testimonial.name}}</p>
                        <p class="p-2">{{testimonial.cityTown}}</p>
                    </div>
                    <div class="footer-section-2">
                        <div class="bottom-image">
                            <img src="../../../assets/images/delete.svg" class="mr-3"
                                (click)="deleteTestimony(testimonial)">
                            <img style="height: 32px;" src="../../../assets/images/share.png"
                                (click)="shareSingle(testimonial)">
                        </div>
                    </div>
                </section>
            </ng-template>
        </p-card>
    </div>
</div>
<section>
    <p-dialog header="Share My Testimonial" [modal]="true" [(visible)]="mailBasic"
        [style]="{width: '80%', padding: '10px'}" [baseZIndex]="10000" position="top" [draggable]="false"
        [resizable]="false">
        <div class="">
            <div class="">
                <form [formGroup]="mailForm">
                    <div class="form-group col-xs-12">
                        <textarea type="text" class="form-control" formControlName="tos" id="tos" placeholder="To"
                            [ngClass]="{'is-invalid': submitted && form.tos.errors}"></textarea>
                        <div class="invalid-feedback" *ngIf="submitted && form.tos.errors?.required">
                            <span>Please provide the email</span>
                        </div>
                    </div>
                    <div class="form-group  col-xs-12">
                        <input type="text" class="form-control" formControlName="subject" id="subject"
                            placeholder="Subject" [ngClass]="{'is-invalid': submitted && form.subject.errors}">
                        <div class="invalid-feedback" *ngIf="submitted && form.subject.errors?.required">
                            <span>Please mention the subject</span>
                        </div>
                    </div>
                    <div class="form-group  col-xs-12">
                        <textarea type="text" class="form-control" formControlName="message" id="message"
                            placeholder="Message" [ngClass]="{'is-invalid': submitted && form.message.errors}" rows="10"
                            cols="100" [(ngModel)]="defaultText">
                        </textarea>
                        <div class="invalid-feedback" *ngIf="submitted && form.message.errors?.required">
                            <span>Please mention the message</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-text"
                (click)="hideEmailDialog()"></button>
            <button pButton pRipple label="Send" icon="pi pi-check" class="p-button-text" (click)="sendMail()"></button>
        </ng-template>
    </p-dialog>
</section>
<section>
    <p-dialog header="" [modal]="true" [(visible)]="displayErrorPopup" [style]="{width: '575px', padding: '10px'}"
        [baseZIndex]="10000" position="center" [draggable]="false" [resizable]="false">
        <div class="ErrorContainer">
            <div class="container">
                <div class="col-lg-12">
                    <section>
                        <p>Something went wrong. Please try again later or contact customer service,
                            <a href="tel:1-866-366-4446">1-866-366-4446</a>,
                            <a href="support@ia9inc.com">support@ia9inc.com</a>.
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="displayErrorPopup=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>