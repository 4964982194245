<!-- <app-header></app-header> -->
<!-- <div class="DashmainContainer">
    <div class="row">
        <div class="col-lg-10 col-md-9" *ngIf="userProfile">
            <div class="Dash_title">
                <img src="{{userProfile.Picture}}" alt="">
                <p>Good Morning, {{userProfile.FirstName}} {{userProfile.LastName}} <br> <span class="con">
                        Here is an overview of your performance
                    </span>
                </p>
            </div>
        </div>
        <div class="col-lg-2 col-md-3 range">
            <p-calendar placeholder="Year Range" [(ngModel)]="rangeDates" view="month" dateFormat="M, yy"
                [yearNavigator]="true" yearRange="2000:2030" selectionMode="range" [readonlyInput]="true"
                inputId="range" showIcon="true">
            </p-calendar>
        </div>
        <section>
            <p>We're making some improvements on this page. Please come back later.</p>
        </section>
    </div>
     <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="box">
                <p>Total Listings</p>
                <div class="num">
                    <h5>200</h5>
                    <img src="../../../assets/images/ic-sessions.svg" alt="">
                </div>
                <h4><span class="green">52.3% <img src="../../../assets/images/ic-arrow-narrow-up.svg"
                            alt=""></span>than last year</h4>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="box">
                <p>Active Listings</p>
                <div class="num">
                    <h5>200</h5>
                    <img src="../../../assets/images/ic-sessions.svg" alt="">
                </div>
                <h4><span class="green red">52.3% <img src="../../../assets/images/ic-arrow-narrow-up-1.svg"
                            alt=""></span>than last year</h4>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="box">
                <p>Under Agreements</p>
                <div class="num">
                    <h5>200</h5>
                    <img src="../../../assets/images/ic-sessions.svg" alt="">
                </div>
                <h4><span class="green">52.3% <img src="../../../assets/images/ic-arrow-narrow-up.svg"
                            alt=""></span>than last year</h4>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="box">
                <p>Closed Listings</p>
                <div class="num">
                    <h5>200</h5>
                    <img src="../../../assets/images/ic-sessions.svg" alt="">
                </div>
                <h4><span class="green">52.3% <img src="../../../assets/images/ic-arrow-narrow-up.svg"
                            alt=""></span>than last year</h4>
            </div>
        </div>
    </div>
    <div class="graph">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="card">
                    <div class="graph_set">
                        <h5>Total Listings Vs Closed Listings</h5>
                        <ul class="header_menu">
                            <li>
                                <div class="dropdown">
                                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle">Last 12
                                        Months</button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item ng-star-inserted">Last 8
                                            Months</a>
                                        <a class="dropdown-item ng-star-inserted">Last 6
                                            Months</a>
                                        <a class="dropdown-item ng-star-inserted">Last 4
                                            Months</a>
                                        <a class="dropdown-item ng-star-inserted">Last 2
                                            Months</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <p-chart type="line" [data]="basicData" [options]="basicOptions"></p-chart>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="card">
                    <div class="graph_set">
                        <h5>Active Vs Closed Listings</h5>
                        <ul _ngcontent-xuv-c55="" class="header_menu">
                            <li>
                                <div class="dropdown">
                                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle">Last 12
                                        Months</button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item ng-star-inserted">Last 8
                                            Months</a>
                                        <a class="dropdown-item ng-star-inserted">Last 6
                                            Months</a>
                                        <a class="dropdown-item ng-star-inserted">Last 4
                                            Months</a>
                                        <a class="dropdown-item ng-star-inserted">Last 2
                                            Months</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <p-chart type="bar" [data]="basicDataBar" [options]="basicOptions"></p-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="tbl_des">
        <div class="tbl">
            <h1>Latest Lisitings</h1>
            <button class="btn export_btn">Active Listings</button>
            <button class="btn export_btn">Closed Listings</button>
            <button class="btn export_btn">Expired Listings</button>
            <button class="btn export_btn"><img src="../../../assets/images/ic-file-download.svg" alt="">
                Export
            </button>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="table-responsive">
                    <div class="card">
                        <p-table #dt [columns]="cols" [value]="lisitings" [paginator]="true" [rows]="10"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [rowsPerPageOptions]="[10,20]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th scope="col">LISTING ID</th>
                                    <th scope="col">ADDRESS</th>
                                    <th scope="col">NAME</th>
                                    <th scope="col">COMPANY</th>
                                    <th scope="col">EMAIL</th>
                                    <th scope="col">PHONE</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td>{{ list.listing_id }}</td>
                                    <td>{{ list.address }}</td>
                                    <td>{{ list.name }}</td>
                                    <td>{{ list.company }}</td>
                                    <td>{{ list.email }}</td>
                                    <td>{{ list.phone }}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="paginatorleft">
                                <p-button type="button" styleClass="p-button-text"></p-button>
                            </ng-template>
                            <ng-template pTemplate="paginatorright">
                                <p-button type="button" styleClass="p-button-text"></p-button>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<app-header></app-header>
<p-toast></p-toast>
<div class="DashmainContainer" *ngIf="userProfile">
    <div class="row">
        <div class="col-xl-8 col-lg-10 col-md-12 p-0">
            <div class="right_b">
                <img src="{{userProfile?.Picture ? userProfile?.Picture : '../../../assets/images/default.png'}}"
                    alt="">
                <div class="rightbox_content">
                    <h2>Hello {{userProfile?.FirstName}} {{userProfile?.LastName}}</h2>
                    <p>Welcome to your domii<sup>&#174;</sup> Dashboard</p>
                    <p>Your Dashboard was recently</p>
                    <p>Updated on {{currentDate}} at {{currentTime}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>