import { Component, HostListener, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as dropin from 'braintree-web-drop-in';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { ProfessionalType } from '../../models/professionalTypeModel';
import { ProfessionalRegModel } from 'src/app/models/professionalRegModel';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { CommonConstants } from 'src/app/constants/commonConstants';
import { PlanDetails } from 'src/app/constants/PlanDetails';
import { Mbrship_Plans } from 'src/app/constants/mbrshipPlans';
import { LoginService } from 'src/app/services/login/login.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as states from '../../../assets/data/states.json';
import { HubspotContactModel } from 'src/app/models/hubspotContactModel';
import { HubspotService } from 'src/app/services/hubspot/hubspot.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsalService } from '@azure/msal-angular';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signUpForm: FormGroup;
  submitted = false;
  professionalTypes: ProfessionalType[] = [];
  dropInInstance: dropin.DropinModel;
  registrationCode: string;
  isRegCodeAvailable: boolean = false;
  xClientId: string;
  mbrshipBenefits: string[] = [];
  states = [];
  termsAndConditons: any;
  tcURL: SafeResourceUrl;
  tcHeader: string = '';
  errorMsg: string = '';
  membershipProfessionalPlans: [] = [] as any;
  androidStoreUrl: string;
  iOSStoreUrl: string;
  showBenefits: boolean = true;
  isMobile: boolean = false;
  mlsAffiliates: any[];
  displayEmailError: boolean = false;
  errorMessage: string = '';
  uniqueId: string;
  proType: string = '';
  token: string = '';
  memberPlanCode: string = '';
  errorRegCodeMessage: string = '';
  displayRegCodeError: boolean = false;
  tomorrowDate: string = '';
  selectedPlan: string = '';

  constructor(private formBuilder: FormBuilder,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private loginService: LoginService,
    private hubSpotService: HubspotService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private datePipe: DatePipe,) { }

  ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.uniqueId = sessionStorage.getItem('UNIQUE_ID');
    this.registrationCode = routeParams.get('regCode');
    this.token = sessionStorage.getItem('msal.idtoken');
    this.xClientId = this.environmentService.getXClientId();
    // (document.getElementById('companyLogo') as HTMLImageElement).src = CommonConstants.imageBasePath + this.environmentService.getCompanyLogo();
    this.mbrshipBenefits = PlanDetails.PROF_SIGNUP_FEATURES;
    this.androidStoreUrl = this.environmentService.getAndroidStoreUrl();
    this.iOSStoreUrl = this.environmentService.getiOSStoreUrl();
    this.buildSignUpForm();
    this.states = (states as any).default;
    // this.createDropIn();
    this.getMembershipTypes();
    // this.retrievePlans();
    this.getMLSAffilates();
    this.showHideBenefits(window.innerWidth);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    this.tomorrowDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
  }

  buildSignUpForm() {
    this.signUpForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl(null, [Validators.required, Validators.pattern("[0-9\+\-]{10,12}")]),
      emailId: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required]),
      proType: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      companyUrl: new FormControl('', [Validators.pattern(/^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([\/\w .-]*)\??([^\s]*)$/i)]),
      licenseInfo: new FormControl(''),
      licenseExpiration: new FormControl(''),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      registrationCode: new FormControl(''),
      agreeTandC: new FormControl(false, [Validators.requiredTrue]),
      membershipPlan: new FormControl(''),
    }, {
      validator: this.mustMatch('password', 'confirmPassword')
    });
    if (this.registrationCode != null) {
      this.isRegCodeAvailable = true;
      this.signUpForm.get('registrationCode').setValue(this.registrationCode);
    }
    else if(this.uniqueId){
        this.signUpForm.get('registrationCode').setValue(this.uniqueId);
    }
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (group: FormGroup) => {
      const control = group.controls[controlName];
      const matchingControl = group.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      }
      else {
        matchingControl.setErrors(null);
      }
    }
  }

  get form() { return this.signUpForm.controls; }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showHideBenefits(event.target.innerWidth);
  }

  showHideBenefits(width) {
    if (width <= 1023) {
      if (this.isMobile == false) {
        this.isMobile = true;
        this.showBenefits = false;
      }
    }
    else {
      this.isMobile = false;
      this.showBenefits = true;
    }
  }

  createDropIn() {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
    this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        dropin.create({
          authorization: response.data,
          container: document.getElementById('dropin-container')
        }).then((dropinInstance) => {
          this.dropInInstance = dropinInstance;
          this.spinner.hide();
        }).catch((error) => { console.log(error) });
      }
    }, (error) => {
      console.log(error);
    });
  }

  retrievePlans(profession?) {
    let url = this.environmentService.getIA9LightningUrl() + 'payment/professional/plans?code=' + this.environmentService.getIA9LightningKey();
    // this.membershipProfessionalPlans = [];
    // if(profession?.target[profession?.target.selectedIndex].text === "Banker/Mortgage Lender"){
    //   this.proType = "Banker/Mortgage Lender";
    //   url = this.environmentService.getIA9LightningUrl() + 'payment/MortgageLender/plans?code=' + this.environmentService.getIA9LightningKey();
      // this.createDropIn();
      this.professionalService.getMembershipPlans(url, this.xClientId).subscribe((response) => {
        if (response?.data) {
          for (var i = 0; i < response?.data.length; i++) {
            response.data[i].selected = false;
            this.membershipProfessionalPlans = response?.data
          }
        }
      }, (error) => {
        console.log(error);
      })
      // this.signUpForm.controls['membershipPlan'].setValidators([Validators.required]);
      // this.signUpForm.controls['membershipPlan'].updateValueAndValidity()
    // }else{
    //   this.proType = "";
    //   if(this.dropInInstance){
    //     this.dropInInstance.teardown(function(err) {
    //         if (err) { console.error('An error occurred during teardown:', err); }
    //     });
    //   }
    //   this.signUpForm.controls['membershipPlan'].clearValidators();
    //   this.signUpForm.controls['membershipPlan'].updateValueAndValidity()
    // }
  }

  radioChecked(data, i) {
    if(this.dropInInstance){
      this.dropInInstance.teardown(function(err) {
          if (err) { console.error('An error occurred during teardown:', err); }
      });
    }

    if(data.price !== 'Free'){
      this.spinner.show();
      this.createDropIn();
    }

    this.membershipProfessionalPlans.forEach((item: any) => {
      if (item.uid !== data.uid) {
        item.selected = false;
      } else {
        this.memberPlanCode = data.uid;
        item.selected = true;
      }
    })
    this.signUpForm.get("membershipPlan").patchValue(data.code);
    this.selectedPlan = data.price;
  }

  onSubmit() {
    this.validateInvitationCode();
    if(this.signUpForm.get('licenseInfo').value !== ""){
      this.signUpForm.controls['licenseExpiration'].setValidators([Validators.required]);
      this.signUpForm.controls['licenseExpiration'].updateValueAndValidity();
    }
    this.submitted = true;
    if (this.signUpForm.invalid) {
      return;
    }
    
    // if(this.selectedPlan !== 'Free'){
    //   this.dropInInstance.requestPaymentMethod().then((payload) => {
    //     this.registerUser(payload);
    //   }).catch((error) => { console.log(error) });
    // }else{
      this.registerUser();
    // }
  }

  registerUser(payload?) {
    const profReg = new ProfessionalRegModel();
    profReg.firstName = this.signUpForm.get('firstName').value;
    profReg.lastName = this.signUpForm.get('lastName').value;
    profReg.phone = this.signUpForm.get('mobileNumber').value;
    profReg.email = this.signUpForm.get('emailId').value;
    profReg.password = this.signUpForm.get('password').value;
    profReg.registrationCode = this.signUpForm.get('registrationCode').value;
    profReg.type = this.signUpForm.get('proType').value;
    profReg.companyName = this.signUpForm.get('companyName').value;
    profReg.companyUrl = this.signUpForm.get('companyUrl').value;
    profReg.licenseNumber = this.signUpForm.get('licenseInfo').value;
    profReg.licenseState = this.signUpForm.get('state').value;
    profReg.address1 = this.signUpForm.get('address').value;
    profReg.address2 = '';
    profReg.city = this.signUpForm.get('city').value;
    profReg.state = this.signUpForm.get('state').value;
    profReg.zip = this.signUpForm.get('zip').value;
    profReg.licenseExpiration = this.signUpForm.get('licenseExpiration').value;
    profReg.paymentMethodNonce = '';
    // if(this.selectedPlan !== 'Free'){
    //   profReg.paymentMethodNonce = payload.nonce;
    // }
    // profReg.MembershipPlan = '0';
    // if(this.proType === "Banker/Mortgage Lender"){
      // profReg.paymentMethodNonce = payload.nonce;
      // profReg.MembershipPlan = this.signUpForm.get('membershipPlan').value
    // }
    // profReg.MembershipPlan = Mbrship_Plans.PROF_SIGNUP;
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'registration/professional/enroll?code=' + this.environmentService.getIA9LightningKey();
    this.professionalService.registerProfessional(url, profReg, this.xClientId).subscribe(response => {
      this.redirectToSuccess();
      // this.registerUserWithHubspot(profReg);
      // this.spinner.hide();
      // document.getElementById('openPaySuccessBtn').click();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.errorMsg = error.error.description;
      document.getElementById('openPayFailedBtn').click();
    });
  }

  registerUserWithHubspot(profReg: ProfessionalRegModel) {
    const hubSpotcontact = new HubspotContactModel;
    hubSpotcontact.company = profReg.companyName;
    hubSpotcontact.firstname = profReg.firstName;
    hubSpotcontact.lastname = profReg.lastName;
    hubSpotcontact.bt_plan = "Domii Professional Signup";
    hubSpotcontact.mobilephone = profReg.phone;
    hubSpotcontact.email = profReg.email;
    hubSpotcontact.bt_plan_id = profReg.MembershipPlan;
    hubSpotcontact.address = profReg.address1;
    hubSpotcontact.city = profReg.city;
    hubSpotcontact.state = profReg.state;
    hubSpotcontact.zip_code = profReg.zip;
    hubSpotcontact.professional_type = profReg.type;
    hubSpotcontact.mls_affliation = profReg.mlsAffiliation;
    this.spinner.show();
    this.hubSpotService.createContact(hubSpotcontact, '/prosignup').subscribe(response => {
      console.log("User registered in HS");
      this.redirectToSuccess();
    },
      error => {
        console.log(error);
        this.spinner.hide();
        for (let index = 0; index < error.error.errors.length; index++) {
          if (error.error.errors[index].errorType === "INVALID_EMAIL") {
            this.errorMsg = 'Invalid email address';
            document.getElementById('openPayFailedBtn').click();
          }
        };
        // this.redirectToHome();
      });
  }

  redirectToHome() {
    setTimeout(() => {
      if ($('#paymentSuccess').hasClass('show')) {
        document.getElementById('openPaySuccessBtn').click();
      }
      this.router.navigate(['/home']);
    }, 2000);
  }

  redirectToSuccess() {
    setTimeout(() => {
      if ($('#paymentSuccess').hasClass('show')) {
        document.getElementById('openPaySuccessBtn').click();
      }
      document.getElementById('openSignupSuccessBtn').click();
      this.spinner.hide();
    }, 2000);
  }

  getMembershipTypes() {
    let url = this.environmentService.getIA9V2Url() + 'MembershipType?code=' + this.environmentService.getIA9V2Key();
    this.professionalService.getMembershipTypes(url).subscribe(response => {
      this.professionalTypes = response;
      this.professionalTypes = this.professionalTypes.filter(pro => pro.type != 1);
    },
      error => {
        console.log(error);
      })
  }

  openTerms(event, type) {
    event.preventDefault();
    document.getElementById('openTermsConditionsBtn').click();
    (document.getElementById('t&CLogo') as HTMLImageElement).src = CommonConstants.imageBasePath + this.environmentService.getCompanyLogo();
    let url = '';
    if (type == 'TC') {
      this.tcHeader = 'Terms and Conditions';
      url = this.environmentService.getEULAUrl();
    }
    else if (type == 'PP') {
      this.tcHeader = 'Privacy Policy';
      url = this.environmentService.getPrivacyUrl();
    }
    this.tcURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  login() {
    this.loginService.login();
  }

  isNumber(event) {
    const regex = new RegExp('[0-9]+');
    return regex.test(event.key);
  }

  openAppStore() {
    window.open(this.iOSStoreUrl);
  }

  openPlayStore() {
    window.open(this.androidStoreUrl);
  }

  getMLSAffilates() {
    const url = this.environmentService.getIA9V2Url() + "MlsAffiliates/?code=" + this.environmentService.getIA9V2Key();
    this.spinner.show();
    this.professionalService.getMLSAffiliates(url).subscribe(response => {
      this.spinner.hide();
      this.mlsAffiliates = response;
    });
  }

  validateEmail(event: any) {
    let url = this.environmentService.getIA9LightningUrl() + 'registration/validate/email/' + event.target.value + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.professionalService.getValidEmail(url, this.xClientId).subscribe((response) => {
      this.displayEmailError = false;
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      if (error.error.statusCode === 8002) {
        this.displayEmailError = true;
        this.errorMessage = 'An account with email address already exists.';
      }
    })
  }

  validateInvitationCode(){
    let regCode = this.signUpForm.get('registrationCode').value;
    let url = this.environmentService.getIA9LightningUrl() + 'registration/validate/invitation/' + regCode + '?code=' + this.environmentService.getIA9LightningKey();
    let plan = "";

    const parser = new DOMParser();
    this.membershipProfessionalPlans.map((pl) => {
      if(this.memberPlanCode == pl['uid']){
        let parsePlan = parser.parseFromString(pl['description'], "text/html");
        plan = parsePlan.getElementsByClassName("plan-title")[0].textContent;
      }
    });

    if(plan != ''){
      if(regCode == '' || regCode == null || regCode == 'undefined'){
        if(plan.trim() !== "Plan C"){
          this.signUpForm.controls['membershipPlan'].setValidators([Validators.required]);
          this.signUpForm.controls['membershipPlan'].updateValueAndValidity();
          this.errorRegCodeMessage = 'Please provide your agent’s unique id';
          this.displayRegCodeError = true;
        }else{
          this.displayRegCodeError = false;
        }
      }else{
        if(plan.trim() === "Plan C"){
          this.errorRegCodeMessage = 'Plan C, does not require an invitation code.';
          this.displayRegCodeError = true;
        }else{
          this.spinner.show();
          this.professionalService.getValidateInvitationCode(url, this.token, this.xClientId).subscribe(response => {
            this.displayRegCodeError = false;
            this.signUpForm.controls['membershipPlan'].clearValidators();
            this.signUpForm.controls['membershipPlan'].updateValueAndValidity();
            this.spinner.hide();
          }, (error) => {
            this.spinner.hide();
            if (error.error.statusCode === 6001) {
              this.displayRegCodeError = true;
              this.errorRegCodeMessage = "Please provide your Agent’s unique ID";
            }
            if (error.error.statusCode != 2000) {
              this.displayRegCodeError = true;
              this.errorRegCodeMessage = error.error.description;
            }
          });
        }
      }
    }
  }

  redirectToLogin() {
    this.loginService.login();
    this.displayEmailError = false;
  }
  
  isExpirationDateValid() {
    const expDate = new Date(this.signUpForm.get('licenseExpiration').value);
    if (expDate.getTime() < new Date(this.tomorrowDate).getTime()) {
        this.signUpForm.get('licenseExpiration').setErrors({ min: true });
    }
  }
}
