export class HubspotContactModel {
    company: string;
    companyUrl: string;
    firstname: string;
    lastname: string;
    mobilephone: string;
    email: string;
    bt_plan: string;
    bt_plan_id: string;
    mls_affliation: string;
    professional_type: number;
    address: string;
    city: string;
    state: string;
    zip_code: string;
    address2: string;
  preferredTms: any;
}