<button id="openCancelPlan" [hidden]="true" data-toggle="modal" data-target="#cancelPlan">CancelPlan</button>
<app-toasts
    *ngIf="toastVisible"
    [toastType]="toastType"
    [content]="toastMessage"
    [(visible)]="toastVisible"
    (closeToast)="closeToast()">
</app-toasts>
<div class="modal fade" id="cancelPlan" tabindex="-1" role="dialog" aria-labelledby="Cancel Plan" aria-hidden="true" #cancelPlan>
    <div class="modal-dialog modal modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header col-xs-12 col-md-12">
                <div class="closeModal">
                    <img src="../../../assets/images/cross.svg" style="cursor: pointer;" data-toggle="modal" data-target="#cancelPlan">
                </div>
            </div>
            <div class="modal-body">
                <div class="col-md-12 col-xs-12">
                    <div class="font18px">
                        <span>Are you sure you want to cancel?</span>
                    </div>
                    <div class="martop10px font16px contactInfo">
                        <div>Call our Support Team</div>
                        <div class="contactSec">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.853" height="16.855" viewBox="0 0 16.853 16.855"><g transform="translate(-18 -4.185)"><path d="M15.7,12.89a.947.947,0,0,0-1.316.041l-2.264,2.328A5.432,5.432,0,0,1,9.35,13.689,5.405,5.405,0,0,1,7.78,10.92l2.326-2.265a.946.946,0,0,0,.041-1.316L6.651,3.5a.946.946,0,0,0-1.316-.082L3.282,5.174a.946.946,0,0,0-.274.614,14.289,14.289,0,0,0,4.06,10.186,14.326,14.326,0,0,0,9.845,4.066c.191,0,.308-.006.34-.008a.938.938,0,0,0,.613-.275l1.76-2.054a.947.947,0,0,0-.081-1.316Z" transform="translate(15 1)" fill="#CE0000"/></g></svg>
                            </span>
                            <span class="contactNumber">
                                <a href="tel:1-866-366-4446" style="color: #CE0000;">1-866-366-4446</a>
                            </span>
                        </div>
                    </div>
                    <div class="martop20px font18px">
                        <span>Or Send Email, We will reach you back!!</span>
                    </div>
                    <div class="martop10px">
                        <form class="cancelPlanForm" (ngSubmit)="onSubmit()" #cancelPlanForm="ngForm">
                            <div>
                                <div class="form-group">
                                    <label for="contact">Contact Number</label>
                                    <input type="phone" class="form-control" id="contact" name="contact" required
                                        placeholder="Enter Your Contact Number" #contact="ngModel"
                                        [ngClass]="{'is-invalid': submitted && contact.errors}"
                                        [(ngModel)]="mobile"  maxlength="12">
                                    <div class="invalid-feedback" *ngIf="submitted && contact.errors">
                                        <span *ngIf="contact.errors.required">Please enter Contact Number</span>
                                        <span *ngIf="contact.errors.pattern">Please enter Valid Contact Number</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="form-group">
                                    <label for="email">Email ID</label>
                                    <input type="email" class="form-control" id="email" name="email" required
                                        placeholder="Enter Your Email ID" #email="ngModel"
                                        [ngClass]="{'is-invalid': (submitted || email.dirty) && email.errors}"
                                        [(ngModel)]="emailID" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
                                    <div class="invalid-feedback" *ngIf="(submitted || email.dirty) && email.errors">
                                        <span *ngIf="email.errors.required">Please enter Email ID</span>
                                        <span *ngIf="email.errors.pattern">Please enter Valid Email</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="form-group">
                                    <label for="reason">
                                        Reason to Cancel <span style="color: #838282;">(Optional)</span>
                                    </label>
                                    <textarea class="form-control" id="reason" name="reason" [(ngModel)]="reasonToCancel"
                                        #reason="ngModel">
                                    </textarea>
                                </div>
                            </div>
                            <div class="flexDspl martop20px">
                                <button type="submit" class="btn submitButton">Send</button>
                                <span class="formErrMsg" *ngIf="submitted && cancelPlanForm.form.invalid">*Enter All the Fields</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>