export const MENUITEMS = [
    {
        "id": 1,
        "selected": true,
        "menu": "Profile Settings",
        "route": "profile",
        "img": "../../../assets/images/gear.svg",
        "activeImg": "../../../assets/images/gear-active.svg"
    },
    // {
    //     "id": 2,
    //     "selected": false,
    //     "menu": "Cancel Plan",
    //     "route": "",
    //     "img": "../../../assets/images/cancel-plan.svg",
    //     "activeImg": "../../../assets/images/cancel-plan-active.svg"
    // },
    // {
    //     "id": 3,
    //     "selected": false,
    //     "menu": "Reset Password",
    //     "route": "",
    //     "img": "../../../assets/images/reset-pwd.svg",
    //     "activeImg": "../../../assets/images/reset-pwd-active.svg"
    // },
    {
        "id": 4,
        "selected": false,
        "menu": "Logout",
        "route": "logout",
        "img": "../../../assets/images/logout.svg",
        "activeImg": "../../../assets/images/logout-active.svg"
    }
]