import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {

  associatedusers: any = [];
  associateduserDetails: any = [];
  token: string = '';
  displayBasic: boolean = false;

  constructor(
    private environmentService: EnvironmentService,
    private professionalService: ProfessionalService,
    private spinner: NgxSpinnerService,
  ) {
    this.token = sessionStorage.getItem('msal.idtoken');
  }

  ngOnInit(): void {
    this.leads();
  }

  leads() {
    this.spinner.show();
    const url = this.environmentService.getIA9CoreUrl() + 'associatedusers?code=' + this.environmentService.getIA9CoreKey();
    this.professionalService.getLeads(url, this.token).subscribe(
      response => {
        this.associatedusers = response;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      });
  }

  showDetailDialog(data) {
    this.associateduserDetails = data;
    this.displayBasic = true;
  }
}
