import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonConstants } from 'src/app/constants/commonConstants';
import { EnvironmentService } from 'src/app/services/environment/environment.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPwdForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private environmentService: EnvironmentService) { }

  ngOnInit(): void {
    this.resetPwdForm = this.formBuilder.group({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required]),
    }, {
      validator: this.mustMatch('newPassword', 'confirmPassword')
    });
    (document.getElementById('companyLogo') as HTMLImageElement).src = CommonConstants.imageBasePath + this.environmentService.getCompanyLogo();
  }

  mustMatch(controlName: string, matchingControlName: string){
    return (group: FormGroup) => {
      const control = group.controls[controlName];
      const matchingControl = group.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch){
        return;
      }

      if (control.value !== matchingControl.value){
        matchingControl.setErrors({ mustMatch: true });
      }
      else{
        matchingControl.setErrors(null);
      }
    }
  }

  get form() { return this.resetPwdForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.resetPwdForm.invalid){
      return;
    }
  }

}
