import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import * as moment from 'moment';

@Component({
  selector: 'app-mobile-open-house',
  templateUrl: './mobile-open-house.component.html',
  styleUrls: ['./mobile-open-house.component.scss']
})
export class MobileOpenHouseComponent implements OnInit {
  public openHouseForm: FormGroup;
  submitted = false;
  // token: string = '';
  listingInfo: any = {};
  xClientId: string = '';
  aid: string = '';
  show: boolean = true;
  displayBasic: boolean = false;
  displayErrorPopup: boolean = false;
  model: any = {
    address: '',
    showingSchedule: ''
  };
  businessAddress: string = '';
  showingSchedule: string = ''
  currentDate: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private realtorService: RealtorService,
    private environmentService: EnvironmentService,
    private spinner: NgxSpinnerService) {
    this.currentDate = moment().format('ll');
    this.route.queryParams
      .subscribe(params => {
        this.aid = params.aid;
        this.xClientId = params.X_CLIENT_ID;
      });
    this.model.showingSchedule = this.currentDate + ' 9-5 PM';
  }

  ngOnInit(): void {
    if (this.aid) {
      this.showDialog();
      this.spinner.show();
      const url = this.environmentService.getIA9LightningUrl() + 'agent/buyer/signin/' + '?aid=' + `${this.aid}` + '&X_CLIENT_ID=' + `${this.xClientId}` + '&code=' + this.environmentService.getIA9LightningKey();
      if (this.aid) {
        this.realtorService.getRealtorProfileInfoMobile(url).subscribe(res => {
          if (res && res.data) {
            this.listingInfo = res.data;
            this.spinner.hide();
          }
        }, error => {
          this.spinner.hide();
        })
      }
      else {
        this.spinner.hide();
        this.toastr.warning('Listing / token Required!');
      }
      this.createOpenHouseForm();
    }
    else {
      // setInterval(() => {
      //   this.router.navigateByUrl('/');
      //   return false;
      // }, 3000)
      this.spinner.hide();
    }
  }

  public createOpenHouseForm(): void {
    this.openHouseForm = this.formBuilder.group({
      fullName: new FormControl('', [Validators.required]),
      primaryPhoneNumber: new FormControl('', [Validators.required]),
      secondaryPhoneNumber: new FormControl(''),
      emailAddress: new FormControl('', [Validators.required, Validators.email]),
      isWorkingWithCompanyAgent: new FormControl(null),
      isWorkingWithOtherAgent: new FormControl(null),
    });
  }

  get f() {
    return this.openHouseForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.openHouseForm.invalid) {
      return false;
    }
    this.openHouseForm.addControl('firstName', new FormControl(this.openHouseForm.value.fullName));
    this.openHouseForm.addControl('lastName', new FormControl(''));
    delete this.openHouseForm.value.fullName
    this.openHouseForm.addControl('address', new FormControl(this.businessAddress));
    this.openHouseForm.addControl('showingSchedule', new FormControl(this.showingSchedule));
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'agent/buyer/signin/' + '?aid=' + `${this.aid}` + '&X_CLIENT_ID=' + `${this.xClientId}` + '&code=' + this.environmentService.getIA9LightningKey();
    if (this.aid) {
      this.realtorService.realtorSignupMobile(url, this.openHouseForm.value).subscribe(res => {
        if (res['isSuccess'] === true) {
          this.show = false;
          setTimeout(() => {
            // this.openHouseForm.reset();
            this.createOpenHouseForm();
            this.show = true;
          }, 2000);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      }, error => {
        this.displayErrorPopup = true;
        this.spinner.hide();
      });
    }
    else {
      this.spinner.hide();
    }
  }

  showDialog() {
    this.displayBasic = true;
  }

  onAdd(form) {
    this.displayBasic = false;
    this.businessAddress = form.value.address;
    this.showingSchedule = form.value.showingSchedule;
  }
}
