import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  showPlanAlert: boolean = false;

  constructor(private loginService: LoginService,
    private router: Router,
    private location: Location) { }

  ngOnInit(): void {
    this.showHidePlanAlert(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showHidePlanAlert(event.target.innerWidth);
  }

  showHidePlanAlert(width){
    if (width <= 768){
      this.showPlanAlert = true;
    }else{
      this.showPlanAlert = false;
    }
  }

  logout(){
    // remove user information here
    sessionStorage.removeItem("userType");
    this.loginService.logout();
  }

  cancel(){
    this.location.back();
  }

}
