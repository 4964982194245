<app-header></app-header>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<div class="DashmainContainer">
	<form [formGroup]="upgradeForm">
		<div class="col-md-12 col-xs-12 font16px mbrshipSection">
			<!-- <div>
				<h3 class="select-planhead">Change Your Plan Below:</h3><br>
			</div> -->

			<div>
				<ul class="nav nav-pills" id="nav-tab">
					<li class="nav-item" *ngFor="let category of uniquePlanCategories; let i = index">
						<a [class.active]="category === selectedCategory" (click)="selectCategory(category)"
							class="nav-item nav-link" role="tab">{{category}}</a>
					</li>
				</ul>
				<div class="tab-content plan-area" id="nav-tabContent">
					<div *ngFor="let plan of realtorPlans; let i = index" class="tab-pane fade show"
						[ngClass]="{ active: plan.planCategoryDescription === selectedCategory }">
						<div class="rdio rdio-primary radio-inline">
							<input [id]="'radio-' + i" type="radio" name="membershipPlan" [value]="plan.code"
								[ngClass]="{'is-invalid': submitted && form.membershipPlan.errors}"
								(change)="radioChecked(plan, i)" [checked]="plan.selected">
							<label [for]="'radio-' + i" [innerHTML]="plan.description" style="width: 100%;">

							</label>
							<div *ngIf="plan.featureSet?.AllowZipCodes === 'Yes'">
								<input [id]="'zipCode-' + i" type="text" class="form-control zipCode" formControlName="zipCode"
									[placeholder]="'Choose Up To ' + plan.limits.MaxZipCodeAllowed + ' Zip Codes'">
								<div style="margin-left: 34px;" *ngIf="plan.selected && submitted && form.zipCode.errors?.invalidZipCodes" class="error invalid-feedback">
									Invalid zip codes: {{ submitted && form.zipCode.errors?.invalidZipCodes.join(', ')
									}}
								</div>
								<div style="margin-left: 34px;" *ngIf="plan.selected && submitted && form.zipCode.errors?.maxZipCodesExceeded" class="error invalid-feedback">
									Maximum {{ submitted && plan.limits.MaxZipCodeAllowed }} zip
									codes are allowed. You provided {{
									submitted && form.zipCode.errors?.maxZipCodesExceeded.actual }}.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="invalid-feedback" *ngIf="submitted && form.membershipPlan.errors?.required">
					<span>Please select a plan</span>
				</div>
			</div>
			<div>
				<div class="row">
					<div class="form-group col-sm-4">
						<div style="width: 100%;">
							<label for="promocode" style="font-size: large; font-weight: bold; ">PROMO CODE</label>
							<div *ngIf="errorPromoCodeMessage !== ''"
								style="float:right; font-size: large; font-weight: bolder; margin-right: 70px; color: #ce0000 !important; ">
								<span>{{errorPromoCodeMessage}}</span>
							</div>
							<div *ngIf="validPromoCodeMessage !== ''"
								style="float:right; font-size: large; font-weight: bolder; margin-right: 70px; color: #28a745 !important; ">
								<span>{{validPromoCodeMessage}}</span>
							</div>
						</div>
						<div class="input-group mb-3">
							<input type="text" formControlName="promocode" class="form-control" placeholder="Promo Code"
								aria-label="Promo Code" aria-describedby="basic-addon2">
							<div class="input-group-append">
								<button class="btn btn-primary btn-outline-secondary" type="button"
									(click)="onApplyPromoCode()">Apply</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div id="dropin-container"></div>
			</div>
			<div class="flexDspl agree-termarea">
				<div class="custom-control custom-checkbox">
					<input class="custom-control-input" type="checkbox" formControlName="agreeTandC"
						id="agreeTandCCheck">
					<label class="custom-control-label" for="agreeTandCCheck">
						I Agree to all the
						<span class="tCtext" (click)="openTerms($event, 'TC')">Terms and
							Conditions</span>
						and
						<span class="tCtext" (click)="openTerms($event, 'PP')">Privacy Policy</span>
					</label>
				</div>
			</div>
			<span class="invalid-feedback" *ngIf="submitted && form.agreeTandC.errors">*Please accept the
				Terms</span>
			<div class="flexDspl row" style="margin-bottom: 150px;">
				<div class="col-md-6 col-xs-12 ipadfullwidth">
					<button type="submit" class="btn submitButtonnew" (click)="onSubmit()">SUBMIT</button>
				</div>
			</div>
		</div>
	</form>
</div>
<button id="failedBtn" [hidden]="true" data-toggle="modal" data-target="#paymentFailed">Payment Failed</button>
<div class="modal fade" id="paymentFailed" tabindex="-1" role="dialog" aria-labelledby="Payment Failed"
	aria-hidden="true" data-backdrop="static" data-keyboard="false" #paymentFailed>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content confirmationModal">
			<div class="textCenter">
				<img src="../../../assets/images/stop_hand.svg" alt="Payment Failed" class="cnfrmImg">
			</div>
			<div class="martop20px cnfrmMsg text-center">
				<strong [innerHTML]="errorMessage"></strong>
			</div>
			<div class="martop20px cnfrmMsg text-center" *ngIf="errorHasButton">
				<button class="btn btn-primary btn-outline-secondary" type="button" data-dismiss="modal">Retry</button>
			</div>
		</div>
	</div>
</div>
<button id="successBtn" [hidden]="true" data-toggle="modal" data-target="#paymentSuccess">Payment Success</button>
<div class="modal fade" id="paymentSuccess" tabindex="-1" role="dialog" aria-labelledby="Payment Success"
	aria-hidden="true" data-backdrop="static" data-keyboard="false" #paymentSuccess>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content"
			style="background-color: #f75a72; align-items: center; padding: 1%; font-weight: 600; border-top-left-radius: 30px; border-top-right-radius: 30px;">
			<div style="width: 100%;">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					style="float:right; color: #fff; opacity: inherit; margin-top: 2%; margin-right: 2%;">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="textCenter">
				<h2 class="text-white">NEXT STEP</h2>
			</div>
			<div class="textCenter" style="background-color: #fff; padding: 2%; min-width: 100%;">
				<h5 style="color: #f75a72; margin-top: 20px;">If you have not downloaded the<br />
					domii<sup>&#174;</sup> app, please download it now<br />
					by tapping the App Store and Google<br />
					Play Store links below.</h5><br />
				<img src="../../../assets/images/download-apple-store.png" alt="app store" class="appStore"
					(click)="openAppStore()" style="width: 250px" /><br /><br />
				<img src="../../../assets/images/download-google-play.png" alt="play store" class="playStore"
					(click)="openPlayStore()" style="width: 250px" />
			</div>
		</div>
	</div>
</div>
<button id="openTermsConditionsBtn" [hidden]="true" data-toggle="modal" data-target="#termsConditions">T&C</button>
<div class="modal fade" id="termsConditions" tabindex="-1" role="dialog" aria-labelledby="Terms and Conditions"
	aria-hidden="true" #termsConditions>
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header col-xs-12 col-md-12 modalTitle">
				<div class="col-md-4 col-xs-4 padnull">
					<img src="" alt="Logo" id="t&CLogo" class="tcLogo">
				</div>
				<span class="col-md-4 col-xs-4 textCenter">{{tcHeader}}</span>
				<div class="col-md-4 col-xs-4" style="text-align: end;">
					<img src="../../../assets/images/cross.svg" data-toggle="modal" data-target="#termsConditions">
				</div>
			</div>
			<div class="modal-body">
				<iframe [src]="tcURL" class="tcIFrame"></iframe>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>