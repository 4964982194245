export class RealtorRegModel {
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    zip: string;
    state: string;
    mlsAffiliation: string;
    licenseNumber: string;
    licenseState: string;
    licenseExpiration: string;
    email: string;
    phone: string;
    companyName: string;
    companyUrl: string;
    password: string;
    type: number;
    paymentMethodNonce: string;
    MembershipPlan: string;
    HeaderColorHex: string;
    promoCode: string;
  preferredTms: any;
}