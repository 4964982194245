import { Component, OnInit } from '@angular/core';
import { ProfessionalService } from 'src/app/services/professional/professional.service';

@Component({
  selector: 'app-plan-ending-alert',
  templateUrl: './plan-ending-alert.component.html',
  styleUrls: ['./plan-ending-alert.component.scss']
})
export class PlanEndingAlertComponent implements OnInit {

  realtorProfile: boolean = false;
  subscription: any;
  daysLeft: number = 0;

  constructor(private professionalService: ProfessionalService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem("userType") == "1"){
      this.realtorProfile = true;
      this.daysLeft = parseInt(sessionStorage.getItem("planEnds"));
    }
    this.subscription = this.professionalService.realtorProfile.subscribe(value => {
      if(value?.AgentType == 1){
        this.realtorProfile = true;
        const mbrShipPlans = value?.b2cProfile.MembershipPlans;
        let planStartDate = mbrShipPlans?.find(plan => plan.IsActive == true)?.PlanStartDate;
        if (planStartDate != null && planStartDate != undefined){
          planStartDate = new Date(planStartDate).getTime();
          const today = new Date().getTime();
          this.daysLeft = 30 - Math.floor((today - planStartDate)/(1000*60*60*24));
          this.daysLeft = this.daysLeft < 0 ? 0 : this.daysLeft;
          sessionStorage.setItem("planEnds", this.daysLeft.toString());
        }
      }
    });
  }

}
