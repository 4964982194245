import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class RealtorService {

  

  constructor(
    private http: HttpClient, private tokenService: TokenService) { }

  getSigninSummary(url, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  getOpenHouseAttendees(url, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  sendEmails(url, body, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.post(url, body, { headers: headers });
  }

  realtorSignup(url, body, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.post(url, body, { headers: headers });
  }

  getRealtorProfileInfo(url, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  // Methods for Mobile - Integrate Openhouses form in Agent header [Without Token]

  getRealtorProfileInfoMobile(url) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  realtorSignupMobile(url, body) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
    return this.http.post(url, body, { headers: headers });
  }

  cancelSubscriptions(url, body, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.post(url, body, { headers: headers });
  }

  getPaymentHistory(url, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  subscribePlan(url, body, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.post(url, body, { headers: headers });
  }

  updatePlan(url, body, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.post(url, body, { headers: headers });
  }

  getListingShowings(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  getListingFeedbacks(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  getListingAgents(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  getBookedShowings(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  getShowings(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  getShowingsDailySummary(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  getValidateUpgradeCode(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  getTestimonials(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }

  deleteTestimonials(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.delete(url, { headers });
  }

  updateUserPreference(url, body, clientId, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.post(url, body, { headers: headers });
  }

  getCompanyDetails(url, xClientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', xClientId)
      .set('Authorization', 'Bearer ' + this.tokenService.token);
    
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  realtorPayment(url, body, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
    return this.http.post(url, body, { headers: headers });
  }

  validateCode(url, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId);
    return this.http.get(url, { headers });
  }
  
  addZipCode(url, body, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
    return this.http.post(url, body, { headers: headers });
  }
}
