import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import * as moment from 'moment';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit {
  basicData: any;
  basicDataBar: any;
  basicOptions: any;
  multiAxisData: any;
  chartOptions: any;
  multiAxisOptions: any;
  stackedData: any;
  stackedOptions: any;
  horizontalOptions: any;
  subscription: Subscription;
  first = 0;
  rows = 10;
  lisitings: any;
  rangeDates: Date[];
  cols: any[];
  exportColumns: any[];
  userProfile: any;
  currentDate: string = '';
  currentTime: string = '';
  token: string = '';

  constructor(
    private primengConfig: PrimeNGConfig,
    private spinner: NgxSpinnerService,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router,
    private loginService: LoginService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {
    this.currentDate = moment().format('L');
    this.currentTime = moment().format('LT');
    this.token = sessionStorage.getItem('msal.idtoken');
    const paramToken = this.route.snapshot.queryParamMap.get("token");
    if (paramToken !== null) {
      this.token = paramToken;
      // this.tokenService.token = paramToken;
      sessionStorage.setItem('msal.idtoken', this.token);
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.primengConfig.ripple = true;
    this.getUserDetails();
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.lisitings ? this.first === (this.lisitings.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.lisitings ? this.first === 0 : true;
  }

  getUserDetails() {
    this.spinner.show();
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.professionalService.getUserInfo(url, this.token).subscribe(
      response => {
        this.userProfile = response;
        this.spinner.hide();

        // Redirection for admin
        if (this.userProfile.UserType === "CompanyAdmin") {
          sessionStorage.setItem('X_CLIENT_ID',this.userProfile.MembershipId);
          this.router.navigate(['admin']);
          return;
        }

        if (this.userProfile.UserLevel === 0) {
          this.router.navigate(['consumer/client']);
          setTimeout(() => {
            sessionStorage.removeItem("userType");
            this.loginService.logout();
          }, 2000);
          return false;
        }
      },
      error => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'User Not Found' });
        sessionStorage.removeItem("userType");
        this.loginService.logout();
        return false;
      });
  }
}
