import { NgxSpinnerModule } from 'ngx-spinner';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgModule } from '@angular/core';

const NGX_MODULES = [
    NgxSpinnerModule,
    ColorPickerModule,
    GooglePlaceModule
]

@NgModule({
    imports: [
        NGX_MODULES
    ],
    exports: [
        NGX_MODULES
    ]
})
export class NGXModule { }