<app-header></app-header>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<section>
    <div class="back_btn">
        <a routerLink="/dashboard">
            <img src="../../../assets/images/back_arrow.svg" alt="">
        </a>
    </div>
    <div class="DashmainContainer">
        <div class="mem_ship">
            <h1 class="title">Membership</h1>
            <div class="mem_ship_con">
                <h1>Membership Details</h1>
                <div class="mem_ship_con_details">
                    <div class="nam1">
                        <ul>
                            <li>Membership Status:</li>
                            <li>Current Plan:</li>
                        </ul>
                    </div>
                    <div class="nam2" *ngIf="userProfile?.MembershipPlans">
                        <ul>
                            <span style="display:inline;color: #f75a72;" *ngIf="userProfile?.MembershipPlans[0].IsActive === true"><li (click)="renewMembershipPlan()">
                                <!-- <a style="text-decoration: underline;color: #f75a72 !important;cursor: pointer;">Renew/Activate</a> --> Active</li></span>
                            <span style="display:inline;color: #f75a72;" *ngIf="userProfile?.MembershipPlans[0].IsActive !== true"><li (click)="renewMembershipPlan()">
                                <a style="text-decoration: underline;color: #f75a72 !important;cursor: pointer;">Upgrade/Change Membership</a> Account has expired. Renew Now to continue on Domii
                            </li></span>
                            <li>{{ userProfile?.MembershipPlans[0].Description | replace }}</li>
                        </ul>
                    </div>
                    <div class="alert alert-secondary col-lg-12">
                        <h6>To UPGRADE/CHANGE YOUR MEMBERSHIP PLAN:</h6><br>
                        <p>You <b>MUST</b> click the <q>CANCEL MEMBERSHIP</q> button below in order to change your plan. Then tap UPGRADE/CHANGE MEMBERSHIP, Select Plan, Enter your credit card details, Upgrade Success.</p>
                    </div>
                </div>
            </div>
            <div class="tbl_des">
                <div class="tbl">
                    <h1>Transaction History</h1>
                    <button class="btn export_btn" (click)="export()">
                        <img src="../../../assets/images/ic-file-download.svg" alt="">Export
                    </button>
                </div>
                <div class="card">
                    <p-table #dt [columns]="cols" [value]="paymentHistoryDetails" dataKey="id" [rows]="5"
                        responsiveLayout="scroll" [showCurrentPageReport]="true" [paginator]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="date">Date<p-sortIcon field="date"></p-sortIcon>
                                </th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Description</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-payment>
                            <tr>
                                <td>{{payment.date | date: 'MM/dd/yyyy'}}</td>
                                <td *ngIf="payment.amount !== 'Free'">{{payment.amount}}</td>
                                <td *ngIf="payment.amount === 'Free'">{{payment.amount}}</td>
                                <td>{{payment.status}}</td>
                                <td>{{payment.description}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="flexDspl row" *ngIf="cancelButtonStatus === true">
                <div class="col-md-6 col-xs-12 ipadfullwidth">
                    <button type="submit" class="btn submitButtonnew" (click)="cancelSubscription($event)">
                        Cancel Membership
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <p-dialog header="" [modal]="true" [(visible)]="displayErrorPopup" [style]="{width: '575px', padding: '10px'}"
        [baseZIndex]="10000" position="center" [draggable]="false" [resizable]="false">
        <div class="ErrorContainer">
            <div class="container">
                <div class="col-lg-12">
                    <section>
                        <p>Something went wrong. Please try again later or contact customer service,
                            <a href="tel:1-866-366-4446">1-866-366-4446</a>,
                            <a href="support@ia9inc.com">support@ia9inc.com</a>.
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="backToHome()" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>
<app-footer></app-footer>
