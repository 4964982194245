import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  token: string = '';
  constructor(private msalService: MsalService,
    private router: Router, private activatedRouter : ActivatedRoute) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.token = sessionStorage.getItem('msal.idtoken');
    const returnUrl = sessionStorage.getItem('returnUrl');
    const paramToken = route.queryParamMap.get("token");
    if (paramToken !== null) {
      return true;
    }
    if (!this.token && this.msalService.getAccount() === null && !returnUrl) {
      sessionStorage.setItem('returnUrl', state.url);
      this.router.navigate(['/home']);
      return false;
    }
    if (!this.token && this.msalService.getAccount() === null) {
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}
