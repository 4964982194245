import { Component, HostListener, OnInit } from '@angular/core';
import { Mbrship_Plans } from 'src/app/constants/mbrshipPlans';
import { PlanDetails } from 'src/app/constants/PlanDetails';
import * as dropin from 'braintree-web-drop-in';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';

@Component({
  selector: 'app-pricing-plans',
  templateUrl: './pricing-plans.component.html',
  styleUrls: ['./pricing-plans.component.scss']
})
export class PricingPlansComponent implements OnInit {

  loading: boolean = false;
  showBasicFeatures: boolean = true;
  showProFeatures: boolean = true;
  showAdvFeatures: boolean = true;
  isMobile: boolean = false;
  domii_basic_code = Mbrship_Plans.DOMII_BASIC_FREE;
  domii_pro_code = Mbrship_Plans.DOMII_PRO_WO_TEAM_BUILD;
  domii_adv_code = Mbrship_Plans.DOMII_ADVANTAGE;
  domii_basic_features: string[];
  domii_pro_features: string[];
  domii_adv_features: string[];
  gps_leads_desc: string = PlanDetails.GPS_LEADS_DESC;
  buyer_note: string = PlanDetails.BUYER_NOTE;
  selectedPlan: string = "33rg";

  dropInInstance: dropin.DropinModel;
  xClientId: string;

  constructor(private professionalService: ProfessionalService,
    private environmentService: EnvironmentService) { }

  ngOnInit(): void {
    this.domii_basic_features = PlanDetails.DOMII_BASIC_FEATURES;
    this.domii_pro_features = PlanDetails.DOMII_PRO_FEATURES;
    this.domii_adv_features = PlanDetails.DOMII_ADVANTAGE_FEATURES;
    this.xClientId = this.environmentService.getXClientId();
    // this.createDropIn(); --> uncomment this when API is wired to update plan
    this.showHideFeatures(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showHideFeatures(event.target.innerWidth);
  }

  showHideFeatures(width){
    if (width <= 768){
      this.isMobile = true;
      this.showBasicFeatures = false;
      this.showProFeatures = false;
      this.showAdvFeatures = false;
    }else{
      this.isMobile = false;
      this.showBasicFeatures = true;
      this.showProFeatures = true;
      this.showAdvFeatures = true;
    }
  }

  createDropIn(){
    const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
    this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
      if (response?.data){
        dropin.create({
          authorization: response.data,
          container: document.getElementById('dropin-container')
        }).then((dropinInstance) => {
          this.dropInInstance = dropinInstance;
        }).catch((error) => {console.log(error)});
      }
    }, (error) => {
      console.log(error);
    });
  }

  onSubmit(){
    // make payment for selected plan
  }

}
