<app-header></app-header>
<div class="DashmainContainer">
	<div class="row">
		<div class="col-lg-8 col-md-9" *ngIf="userProfile">
			<div class="Dash_title">
				<img src="{{
			userProfile?.Picture
			  ? userProfile?.Picture
			  : '../../../assets/images/default.png'
		  }}" alt="" />
				<p>
					Hello {{ userProfile.FirstName }} {{ userProfile.LastName }}
					<br />
					<span class="con"> Here is an overview of your performance </span>
				</p>
			</div>
		</div>
		<div class="col-lg-2 col-md-3">
      <div class="rows">
        <label for="cboRange" class="w-100">Range:</label>
        <select class="form-control" id="cboRange" name="cboRange"
        (change)='changeRange($event)'>
          <option value="">Select Range</option>
          <option value="1">1 month</option>
          <option value="3">3 months</option>
          <option value="6">6 months</option>
          <option value="12">1 year</option>
        </select>
      </div>
		</div>
		<div class="col-lg-2 col-md-3 range">
			<!-- <p-calendar placeholder="Year Range" [(ngModel)]="rangeDates" view="month" dateFormat="yy-mm-dd"
        [yearNavigator]="true" yearRange="2000:2030" selectionMode="range" [readonlyInput]="true" inputId="range"
        showIcon="true" (ngModelChange)="getDateChanged(rangeDates)">
	    </p-calendar> -->

      <label for="startDate" class="w-100">Start Date:</label>
      <p-calendar placeholder="Start Date" [(ngModel)]="startDate" [maxDate]="endDate" view="month" dateFormat="yy-mm-dd" [readonlyInput]="true" inputId="startDate"
      showIcon="true" (ngModelChange)="getDateChanged([startDate,endDate])" class="w-100"></p-calendar>
      <label for="endDate" class="w-100">End Date:</label>
      <p-calendar placeholder="End Date" [(ngModel)]="endDate" [minDate]="startDate" view="month" dateFormat="yy-mm-dd" [readonlyInput]="true" inputId="endDate"
      showIcon="true" (ngModelChange)="getDateChanged([startDate,endDate])" class="w-100"></p-calendar>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-3 col-md-6 col-sm-12" *ngFor="let list of ListingSummary">
			<div class="box">
				<p style="
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: 2px;
		  ">
					{{ list.description }}
				</p>
				<div class="num">
					<h5>{{ list.currCount }}</h5>
					<img src="../../../assets/images/ic-sessions.svg" alt="" />
				</div>
				<h4 *ngIf="list.currIsUpThanPrev">
					<span class="green">{{ list.currPercentage.toFixed(2) }}%
						<img src="../../../assets/images/ic-arrow-narrow-up.svg" alt="" />
					</span> greater than last year
				</h4>
				<h4 *ngIf="!list.currIsUpThanPrev">
					<span class="green">{{ list.currPercentage }}%
						<img src="../../../assets/images/ic-arrow-narrow-up-1.svg" alt="" />
					</span> less than last year
				</h4>
			</div>
		</div>
	</div>
	<!-- <div class="graph">
	<div class="row">
	  <div class="col-lg-6 col-md-6">
		<div class="card">
		  <div class="graph_set">
			<h5>Total Listings Vs Closed Listings</h5>
			<ul class="header_menu">
			  <li>
				<div class="dropdown">
				  <button
					type="button"
					data-toggle="dropdown"
					class="btn dropdown-toggle"
				  >
					Last 12 Months
				  </button>
				  <div class="dropdown-menu">
					<a class="dropdown-item ng-star-inserted">Last 8 Months</a>
					<a class="dropdown-item ng-star-inserted">Last 6 Months</a>
					<a class="dropdown-item ng-star-inserted">Last 4 Months</a>
					<a class="dropdown-item ng-star-inserted">Last 2 Months</a>
				  </div>
				</div>
			  </li>
			</ul>
		  </div>
		  <p-chart
			type="line"
			[data]="basicData"
			[options]="basicOptions"
		  ></p-chart>
		</div>
	  </div>
	  <div class="col-lg-6 col-md-6">
		<div class="card">
		  <div class="graph_set">
			<h5>Active Vs Closed Listings</h5>
			<ul _ngcontent-xuv-c55="" class="header_menu">
			  <li>
				<div class="dropdown">
				  <button
					type="button"
					data-toggle="dropdown"
					class="btn dropdown-toggle"
				  >
					Last 12 Months
				  </button>
				  <div class="dropdown-menu">
					<a class="dropdown-item ng-star-inserted">Last 8 Months</a>
					<a class="dropdown-item ng-star-inserted">Last 6 Months</a>
					<a class="dropdown-item ng-star-inserted">Last 4 Months</a>
					<a class="dropdown-item ng-star-inserted">Last 2 Months</a>
				  </div>
				</div>
			  </li>
			</ul>
		  </div>
		  <p-chart
			type="bar"
			[data]="basicDataBar"
			[options]="basicOptions"
		  ></p-chart>
		</div>
	  </div>
	</div>
  </div> -->
	<div class="tbl_des">
		<div class="tbl">
			<div>
				<h1>Listings</h1>
				<h3>{{dropDownMenuIt}} Listings</h3>
			</div>
			<div class="d-flex">
				<div class="dropdown">
					<button type="button" data-toggle="dropdown" class="btn dropdown-toggle">
						{{ dropDownMenuIt }}
					</button>
					<div class="dropdown-menu">
						<a class="dropdown-item ng-star-inserted" (click)="getAllListings('all')">All</a>
						<a class="dropdown-item ng-star-inserted" (click)="getAllListings('active')">Active</a>
						<a class="dropdown-item ng-star-inserted" (click)="getAllListings('activeundercontract')">Active
							under
							contract</a>
						<a class="dropdown-item ng-star-inserted" (click)="getAllListings('closed')">Closed</a>
						<a class="dropdown-item ng-star-inserted" (click)="getAllListings('expired')">Expired</a>
						<a class="dropdown-item ng-star-inserted" (click)="getAllListings('withdrawn')">Withdrawn</a>
					</div>
				</div>
				<button class="btn export_btn" (click)="downloadListingAsPDF()">
					<img src="../../../assets/images/ic-file-download.svg" alt="" />
					Export
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-12">
				<div class="table-responsive">
					<div class="card">
						<p-table #dt [value]="lisitings" [rows]="5" responsiveLayout="scroll"
							[showCurrentPageReport]="true" [paginator]="true">
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="listingId">LISTING ID<p-sortIcon
											field="listingId"></p-sortIcon>
									</th>
									<th>ADDRESS</th>
									<th>SELLER</th>
									<th>LIST DATE</th>
									<th>LIST PRICE</th>
									<th>DOM</th>
									<!-- <th>CLOSING DATE</th> -->
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-list>
								<tr>
									<td style="text-decoration: underline;color: #f75a72; cursor: pointer;"
										(click)="listingDetails(list.listingId)">
										{{ list.listingId }}</td>
									<td>{{ list.address.full }}</td>
									<td>{{ list.seller?.sellerName }}</td>
									<td>{{ getDate(list.listDate) }}</td>
									<td>{{ list.listPrice | currency: "USD" }}</td>
									<td>{{ list?.daysOnMarket ? 'list?.daysOnMarket' : 'N/A' }}</td>
									<!-- <td>{{ getDate(list.closingDate) }}</td> -->
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>
