import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-postcard-payment-notifications',
  templateUrl: './postcard-payment-notifications.component.html',
  styleUrls: ['./postcard-payment-notifications.component.scss']
})
export class PostcardPaymentNotificationsComponent implements OnInit {
  data: any = {};
  routeState: any;
  constructor(private router: Router) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.routeState = this.router.getCurrentNavigation().extras.state;
      if (this.routeState) {
        this.data.message = this.routeState.message ? this.routeState.message : '';
      }
    }
  }

  ngOnInit(): void { }

}
