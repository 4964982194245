import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-consumer-login',
  templateUrl: './no-consumer-login.component.html',
  styleUrls: ['./no-consumer-login.component.scss']
})
export class NoConsumerLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
