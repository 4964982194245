<app-header></app-header>
<p-confirmDialog [breakpoints]="{'960px': '100vw', '640px': '100vw'}" [style]="{width: '50vw'}" [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text" position="top-right">
</p-confirmDialog>
<div class="back_btn">
    <a routerLink="/dashboard">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="DashmainContainer">
    <div class="tbl_des">
        <div class="tbl">
            <h1>Available Templates</h1>
            <div class="d-flex">
                <button class="btn export_btn" (click)="downloadListingAsPDF()">
                    <img src="../../../assets/images/ic-file-download.svg" alt="" />
                    Export
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="table-responsive">
                    <div class="card">
                        <p-table #dt [value]="lisitings" [rows]="5" responsiveLayout="scroll"
                            [showCurrentPageReport]="true" [paginator]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Template Name</th>
                                    <th>Type</th>
                                    <th>Channel</th>
                                    <th pSortableColumn="listDate">Date Created<p-sortIcon field="listDate">
                                        </p-sortIcon>
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td>{{ list.listingId }}</td>
                                    <td>{{ list.address.full }}</td>
                                    <td>{{ list.seller?.sellerName }}</td>
                                    <td>{{ getDate(list.listDate) }}</td>
                                    <td>
                                        <button (click)="previewTemplate(list)" pButton label="Preview"
                                            class="p-button-text">
                                        </button>
                                    </td>
                                    <td>
                                        <button (click)="editTemplate()" pButton label="Edit" class="p-button-text">
                                        </button>
                                    </td>
                                    <td>
                                        <button (click)="deleteTemplate()" pButton label="Delete" class="p-button-text">
                                        </button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <button (click)="addTemplate()" pButton label="Add Template" class="p-button-text">
                                </button>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Edit the current template -->
<section>
    <p-dialog header="Edit Template" [modal]="true" [(visible)]="displayBasic" [style]="{width: '60%', padding: '10px'}"
        [baseZIndex]="10000" position="top" [draggable]="false" [resizable]="true" [breakpoints]="{'960px': '100vw'}">
        <p-card header="" subheader="" styleClass="p-card-shadow">
            <ng-template pTemplate="content">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-6" style="border-right: 1px solid #f75a72;">
                        <div class="edit-card-main-content">
                            <p class="">Hi there {{ _firstName }},</p>
                            <p class="">
                                I wonder if you are still interested in {{_propertyAddress}} you visited last
                                {{ _appointDateTime }} ?
                            </p>
                            <p>Thanks, <br>{{ _realtorNameAndAddress }}</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-6" style="border-left: 1px solid #f75a72;">
                        <div class="edit-card-main-content">
                            <p class="">You can modify your template and user the following place holders:</p>
                            <p class="">{{ _firstName }}</p>
                            <p class="">{{ _realtorNameAndAddress }}</p>
                            <p class="">{{ _appointDateTime }}</p>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <hr>
                <section class="footer-section">
                    <div class="footer-section-2">
                        <div class="bottom-image">
                            <button (click)="getFeedbacks(list)" style="margin-right: 16px;" pButton label="Submit"
                                class="p-button-text">
                            </button>
                            <button (click)="getFeedbacks(list)" pButton label="Preview" class="p-button-text">
                            </button>
                        </div>
                    </div>
                </section>
            </ng-template>
        </p-card>
        <ng-template pTemplate="footer">
            <p-button (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>

<!-- Preview Template -->
<section class="preview-template-card">
    <p-dialog header="Preview Template" [modal]="true" [(visible)]="displayAgents"
        [style]="{width: '575px', padding: '10px'}" [baseZIndex]="10000" position="top" [draggable]="false"
        [resizable]="true" [breakpoints]="{'960px': '100vw'}">
        <div style="margin-bottom: 0;">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div>
                        <div class="preview-testimonial">
                            <p> Preview Testimonial (separate page/window)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="displayAgents=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>

<!-- Add Template -->
<section class="add-template-card">
    <p-dialog header="Add Template" [modal]="true" [(visible)]="displayAddTemplate"
        [style]="{width: '50%', padding: '10px'}" [baseZIndex]="10000" position="top" [draggable]="false"
        [resizable]="true" [breakpoints]="{'960px': '100vw'}">
        <p-card header="" subheader="" styleClass="p-card-shadow">
            <ng-template pTemplate="content">
                <section class="select-template-card">
                    <div class="p-field-radiobutton">
                        <p-radioButton name="templateName" value="a" [(ngModel)]="templateName" inputId="templateName1">
                        </p-radioButton>
                        <label for="templateName1">Consumer Drip Email 1</label>
                    </div>
                    <div class="p-field-radiobutton">
                        <p-radioButton name="templateName" value="b" [(ngModel)]="templateName" inputId="templateName2">
                        </p-radioButton>
                        <label for="templateName2">Consumer Drip Email 2</label>
                    </div>
                    <div class="p-field-radiobutton">
                        <p-radioButton name="templateName" value="c" [(ngModel)]="templateName" inputId="templateName3">
                        </p-radioButton>
                        <label for="templateName3">Consumer Drip Email 3</label>
                    </div>
                    <div class="p-field-radiobutton">
                        <p-radioButton name="templateName" value="d" [(ngModel)]="templateName" inputId="templateName4">
                        </p-radioButton>
                        <label for="templateName4">Feedback Template</label>
                    </div>
                </section>
                <br>
                <section class="selected-template-card">
                    <p class="selected-template">Selected Template :
                        <span style="color: #f75a72;">
                            Consumer Drip Email 1
                        </span>
                    </p>
                    <p>Content:</p>
                    <p>Hi there {{_firstName}},</p>
                    <p> I wonder if you can give me one more chance to showcase another property located
                        at {{_propertyAddress}}
                    </p>
                    <p>Thanks,</p>
                    <p>{{_realtorNameAndAddress}}</p>
                </section>
            </ng-template>
            <ng-template pTemplate="footer">
                <hr>
                <section class="footer-section">
                    <div class="footer-section-1">
                        <div class="bottom-image">
                            <button (click)="getFeedbacks(list)" style="margin-right: 16px;" pButton label="Submit"
                                class="p-button-text">
                            </button>
                            <button (click)="getFeedbacks(list)" style="margin-right: 16px;" pButton label="Preview"
                                class="p-button-text">
                            </button>
                            <button (click)="getFeedbacks(list)" pButton label="Cancel" class="p-button-text">
                            </button>
                        </div>
                    </div>
                </section>
            </ng-template>
        </p-card>
        <ng-template pTemplate="footer">
            <p-button (click)="displayAddTemplate=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>
<app-footer></app-footer>