<div class="container_box mt-5">
    <div class="center_box">
        <img class="m-3" src="../../../assets/images/house-1495589-1267760.png" width="125">
        <div *ngIf="!isExtimated && !extimationError">
            <h3 class="m-2">What's your home worth?</h3>
            <div>
                <input ngx-google-places-autocomplete [options]="options" #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)" />
            </div>
            <button class="btn btn-primary m-3" type="button" (click)="getValuation()">Submit</button>
        </div>

        <div class="evaluation" *ngIf="isExtimated && !extimationError">
            <h3 class="m-2">Home Evaluation</h3>
            <p class="strong">Based on comparables sales within the last twelve months,<br>
                domii's value range is</p>
            <div class="amountBox">{{extimatedvalue?.low | currency: "USD" }} - {{ extimatedvalue?.high | currency:
                "USD"
                }}</div>
            <button type="button" class="btn btn-primary m-3" (click)="isExtimated=!isExtimated">Click other home
                valuations</button>
        </div>

        <div class="evaluation" *ngIf="extimationError">
            <h3>Hello!</h3>
            <p class="strong">Unfortunately, the data for this property is currently unavaliable because of one of the
                following reasons.</p>
            <ul class="guides">
                <li>Price guidelines</li>
                <li>Property type guidelines</li>
                <li>City data restriction</li>
                <li>Weak internet Connection</li>
            </ul>

            <button type="button" class="btn btn-primary m-3"
                (click)="closeClicked()">close</button>
        </div>
    </div>
</div>
<app-footer></app-footer>