<div class="footer">
  <div class="footerRow1">
    <div class="row col-md-12 col-xs-12 p-0">
      <div class="col-md-4 col-xs-12 ipadfullwidth p-0">
        <div class="footerSec footertopsection">
          <div class="footerItem">
            <img [src]="companyBrand" alt="Brand" id="footerBrand" class="brandImg" />
          </div>
          <div class="socialLinks footerItem">
            <ul class="list-inline">
              <li class="list-inline-item">
                <a href="https://www.facebook.com/domiihomes" target="_blank">
                  <img
                    src="../../../assets/images/facebook.svg"
                    alt="facebook"
                  />
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.instagram.com/domiihomes/" target="_blank">
                  <img
                    src="../../../assets/images/instagram.svg"
                    alt="instagram"
                  />
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://twitter.com/domiihomes" target="_blank">
                  <img src="../../../assets/images/twitter.svg" alt="twitter" />
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://www.youtube.com/channel/UCQMLFzGp3RTmIPs0FGVhh-g" target="_blank"
                >
                  <img src="../../../assets/images/youtube.svg" alt="youtube" />
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://www.pinterest.com/domiihomes/?invite_code=8bc8b29b536845d3b1cd73111c35119f&sender=731975883094771765"
                  target="_blank"><img
                    src="../../../assets/images/pinterest.svg"
                    alt="pinterest"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row col-md-8 col-xs-12 ipadfullwidth p-0">
        <div
          class="col-md-6 col-xs-12 ipadfullwidth d-block d-sm-none ipadvisible"
        >
          <div class="footerSec footerItem footerappicon">
            <img
              src="../../../assets/images/download-apple-store.png"
              alt="app store"
              class="appStore"
              (click)="openAppStore()"
            />
            <img
              src="../../../assets/images/download-google-play.png"
              alt="play store"
              class="playStore"
              (click)="openPlayStore()"
            />
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ipadfullwidth">
          <div class="footerSec ipadinline">
            <div class="footerItem">
              <span><img src="../../../assets/images/phone.svg" /></span>
              <span class="contactNumber">
                <a href="tel:1-866-366-4446" style="color: #ffffff"
                  >1-866-366-4446</a
                >
              </span>
            </div>
            <!-- <div class="footerItem">
                            <a href="mailto:support@ia9inc.com" style="color: #FFFFFF;">support@ia9inc.com</a>
                        </div> -->
            <!-- <div class="footerItem">IA9 Inc. Wilmington DE 19805</div> -->
            <div class="footerItem">
              <a href="https://account.domii.io/home">For Buyers</a>
            </div>
            <div class="footerItem">
              <a href="https://domii.io/about-us/">About</a>
            </div>
            <div class="footerItem">
              <a href="https://domii.io/blog/">Blog</a>
            </div>
            <div class="footerItem">
              <a href="https://domii.io/contact-us/">Contact</a>
            </div>
          </div>
        </div>
        <div
          class="
            col-md-4 col-xs-12
            ipadfullwidth
            d-none d-sm-block
            ipadhidden
            pr-0
          "
        >
          <div class="footerSec footerItem footerappicon">
            <img
              src="../../../assets/images/download-apple-store.png"
              alt="app store"
              class="appStore"
              (click)="openAppStore()"
            />
            <img
              src="../../../assets/images/download-google-play.png"
              alt="play store"
              class="playStore"
              (click)="openPlayStore()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footerRow2">
    <div class="col-md-12 col-xs-12">
      <div
        class="footerItem footerbotrt ipadcenter d-block d-sm-none ipadvisible"
      >
        <span>
          <img
            src="../../../assets/images/poweredBy-ia9.svg"
            class="poweredByIa9"
            alt="powered by IA9"
          />
        </span>
      </div>
      <div class="footerItem footerbotlt">
        <span class="ipadfullwidth"
          >Copyright &#169; {{ year }} All rights reserved</span
        >
        <span class="footerItem ipadfullwidth">
          <span class="separator1">|</span>
          <span
            >For<a
              style="color: #ffffff"
              href="https://secure.domii.io/real-estate-agent"
            >
              Real Estate Agents
            </a></span
          >
          <span class="separator2">|</span>
          <span
            ><a style="color: #ffffff" href="https://domii.io/privacy-policy/"
              >Privacy Policy
            </a></span
          >
        </span>
      </div>

      <div
        class="footerItem footerbotrt ipadcenter d-none d-sm-block ipadhidden"
      >
        <span>
          <img
            src="../../../assets/images/poweredBy-ia9.svg"
            class="poweredByIa9"
            alt="powered by IA9"
          />
        </span>
      </div>
    </div>
  </div>
</div>
