import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';

@Component({
  selector: 'app-open-house',
  templateUrl: './open-house.component.html',
  styleUrls: ['./open-house.component.scss']
})
export class OpenHouseComponent implements OnInit {

  public openHouseForm: FormGroup;
  submitted = false;
  subscription: Subscription = new Subscription();
  token: string = '';
  listingInfo: any = {};
  xClientId: string;
  show: boolean = true;
  displayBasic: boolean = false;
  model: any = {
    address: '',
    showingSchedule: ''
  };
  businessAddress: string = '';
  showingSchedule: string = ''
  currentDate: string = '';
  displayErrorPopup: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private realtorService: RealtorService,
    private environmentService: EnvironmentService,
    private spinner: NgxSpinnerService) {
    this.currentDate = moment().format('ll');
    this.token = sessionStorage.getItem('msal.idtoken');
    this.model.showingSchedule = this.currentDate + ' 9-5 PM';
  }

  ngOnInit(): void {
    if (this.token) {
      this.showDialog();
      this.xClientId = this.environmentService.getXClientId();
      this.spinner.show();
      const url = this.environmentService.getIA9LightningUrl() + 'agent/buyer/signin/' + '?code=' + this.environmentService.getIA9LightningKey();
      if (this.token) {
        this.realtorService.getRealtorProfileInfo(url, this.xClientId, this.token).subscribe(res => {
          if (res && res.data) {
            this.listingInfo = res.data;
            this.spinner.hide();
          }
        })
      } else {
        this.spinner.hide();
        this.toastr.warning('Listing / token Required!');
      }
      this.createOpenHouseForm();
    } else {
      setInterval(() => {
        this.router.navigateByUrl('/');
        return false;
      }, 3000)
      this.spinner.hide();
    }
  }

  public createOpenHouseForm(): void {
    this.openHouseForm = this.formBuilder.group({
      fullName: new FormControl('', [Validators.required]),
      primaryPhoneNumber: new FormControl('', [Validators.required]),
      secondaryPhoneNumber: new FormControl(''),
      emailAddress: new FormControl('', [Validators.required, Validators.email]),
      isWorkingWithCompanyAgent: new FormControl(null),
      isWorkingWithOtherAgent: new FormControl(null),
    });
  }

  get f() {
    return this.openHouseForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.openHouseForm.invalid) {
      return false;
    }
    this.openHouseForm.addControl('firstName', new FormControl(this.openHouseForm.value.fullName));
    this.openHouseForm.addControl('lastName', new FormControl(''));
    delete this.openHouseForm.value.fullName
    this.openHouseForm.addControl('address', new FormControl(this.businessAddress));
    this.openHouseForm.addControl('showingSchedule', new FormControl(this.showingSchedule));
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'agent/buyer/signin/' + '?code=' + this.environmentService.getIA9LightningKey();
    if (this.token) {
      this.realtorService.realtorSignup(url, this.openHouseForm.value, this.xClientId, this.token).subscribe(res => {
        if (res['isSuccess'] === true) {
          this.show = false;
          setTimeout(() => {
            this.createOpenHouseForm();
            this.show = true;
          }, 2000);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      }, error => {
        this.displayErrorPopup = true;
        this.spinner.hide();
      });
    } else {
      this.toastr.warning('Listing / token Required!');
      this.spinner.hide();
    }
  }

  showDialog() {
    this.displayBasic = true;
  }

  onAdd(form) {
    this.displayBasic = false;
    this.businessAddress = form.value.address;
    this.showingSchedule = form.value.showingSchedule;
  }
}
