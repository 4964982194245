import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CounterModel } from 'src/app/models/counterModel';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { LoginService } from 'src/app/services/login/login.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers: [MessageService]
})
export class LandingPageComponent implements OnInit {
  uniqueId: string;
  isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  
  constructor(
    private professionalService: ProfessionalService,
    private loginService: LoginService,
    private environmentService: EnvironmentService,
    private messageService: MessageService,
    private router: Router, private route: ActivatedRoute,
    private spinner: NgxSpinnerService,) {
    this.uniqueId = this.route.snapshot.params['uniqueId'];
    if (this.uniqueId) {
      sessionStorage.setItem('UNIQUE_ID', this.uniqueId);
      this.qrCodeHit();
    }else{
      sessionStorage.removeItem('UNIQUE_ID');
    }
  }

  ngOnInit(): void {
    if(this.router.url === '/signup-success'){
      this.spinner.show();
      if(window.innerWidth <= 1023){
        console.log("mobile");
        this.spinner.hide();
      }else{
        // LOGIN VALIDATION WILL BE ADDED HERE
        console.log("desktop");
        this.router.navigate(['/dashboard']);
        this.spinner.hide();
      }
    }
  }

  login() {
    this.loginService.login();
  }

  navigateConsumerSignup() {
    this.router.navigate(['/consumersignup']);
  }

  navigateProSignup() {
    this.router.navigate(['/prosignup']);
  }

  navigateRealtorSignup() {
    this.router.navigate(['/realtorsignup']);
  }

  qrCodeHit() {
    debugger;
    let url = this.environmentService.getIA9LightningUrl() + 'tools/counter' + '?code=' + this.environmentService.getIA9LightningKey();
    var counter = new CounterModel();
    counter.code = this.uniqueId;
    counter.source = this.router.url;
    counter.type = "QrCodeUrl";

    this.professionalService.qrHit(url, counter,"FEE8CC43-AFD8-4DA8-A4E3-6639745C4428").subscribe(
      (response: any) => {
        //Success - do nothing here.
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Code!..' });
      });
  }
}
