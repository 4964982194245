import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as dropin from 'braintree-web-drop-in';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-realtor-professional-invite',
  templateUrl: './realtor-professional-invite.component.html',
  styleUrls: ['./realtor-professional-invite.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RealtorProfessionalInviteComponent implements OnInit {

  membershipProfessionalPlans: [] = [] as any;
  xClientId: string;
  dropInInstance: dropin.DropinModel;
  token: string = '';
  _email: string = '';
  _agentId: string = '';
  _payload = {
    "email": '',
    "paymentMethodToken": '',
    "MembershipPlanCode": '',
    "AgentId": '',
    "code": ''
  }
  displayErrorPopup: boolean = false;
  invitationcode: string = ''
  userProfile: any;
  upgradeCodeStatus: boolean = false;
  errorMessage: string = '';

  constructor(
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private realtorService: RealtorService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loginService: LoginService
  ) {
    this.invitationcode = this.route.snapshot.params['code'];
    this._payload.code = this.invitationcode;
    this.token = sessionStorage.getItem('msal.idtoken');
    this.xClientId = this.environmentService.getXClientId();
  }

  ngOnInit(): void {
    this.getUserDetails();
  }

  getUserDetails() {
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.professionalService.getUserInfo(url, this.token).subscribe(
      response => {
        this.userProfile = response;
        if (this.userProfile.MembershipPlans !== null) {
          this._payload.AgentId = this.userProfile.MembershipPlans[0].AssociatedId;
          this._payload.email = this.userProfile.Email;
          if (this.userProfile.UserLevel > 1) {
            this.ValidateInvitationCode();
          } else {
            this.router.navigate(['dashboard']);
            return false;
          }
        } else {
          this.displayErrorPopup = true;
        }
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'User Not Found' });
        sessionStorage.removeItem("userType");
        this.loginService.logout();
        return false;
      });
  }

  ValidateInvitationCode() {
    let url = this.environmentService.getIA9LightningUrl() + 'registration/validate/professional/' + this.invitationcode + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.professionalService.getValidateInvitationCode(url, this.token, this.xClientId).subscribe(
      (response: any) => {
        if (response.statusCode === 2000) {
          this.createDropIn();
          this.retrievePlans();
          this.upgradeCodeStatus = true;
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
        if (error.error.statusCode === 2006) {
          this.upgradeCodeStatus = false;
          this.errorMessage = 'Code is invalid or already used.';
          setTimeout(() => {
            this.router.navigate(['/membership/professional']);
          }, 2000);
          return false;
        }
      }
    );
  }

  createDropIn() {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
    this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        dropin.create({
          authorization: response.data,
          container: document.getElementById('dropin-container')
        }).then((dropinInstance) => {
          this.dropInInstance = dropinInstance;
        }).catch((error) => { console.log(error) });
      }
    }, (error) => {
      console.log(error);
    });
  }

  retrievePlans() {
    const url = this.environmentService.getIA9LightningUrl() + 'payment/professional/plans?code=' + this.environmentService.getIA9LightningKey();
    this.professionalService.getMembershipPlans(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        for (var i = 0; i < response?.data.length; i++) {
          response.data[i].selected = false;
          this.membershipProfessionalPlans = response?.data
        }
      }
    }, (error) => {
    })
  }

  radioChecked(data, i) {
    this.membershipProfessionalPlans.forEach((item: any) => {
      if (item.uid !== data.uid) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    })
    this._payload.MembershipPlanCode = data.code;
  }

  onUpdate(event: Event) {
    if (this._payload.MembershipPlanCode === '') {
      this.toastr.warning('Please select a plan!..');
      return false;
    }
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dropInInstance.requestPaymentMethod().then((payload) => {
          this._payload.paymentMethodToken = payload.nonce;
          const url = this.environmentService.getIA9LightningUrl() + 'payment/update?code=' + this.environmentService.getIA9LightningKey();
          this.spinner.show();
          this.realtorService.updatePlan(url, this._payload, this.xClientId, this.token).subscribe((response) => {
            this.spinner.hide();
            if (response['isSuccess'] === true) {
              this.messageService.add({ severity: 'info', summary: 'Join', detail: 'Thank you for joining.' });
              this.router.navigate(['/membership/professional']);
            }
          },
            (error) => {
              this.displayErrorPopup = true;
              this.spinner.hide();
            });
        }).catch((error) => { console.log(error) });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected.' });
      }
    });
  }

  backToHome() {
    this.displayErrorPopup = false;
    this.router.navigate(['/dashboard']);
  }
}
