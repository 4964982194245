import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonConstants } from 'src/app/constants/commonConstants';
import { EnvironmentService } from 'src/app/services/environment/environment.service';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {

  submitted = false;
  agreedTC = false;
  @ViewChild('mbrshipForm') mbrshipForm: NgForm;

  constructor(private environmentService: EnvironmentService) { }

  ngOnInit(): void {
    (document.getElementById('companyLogo') as HTMLImageElement).src = CommonConstants.imageBasePath + this.environmentService.getCompanyLogo();
  }

  goToPayment(){
    this.submitted = true;
    if (this.mbrshipForm.invalid){
      return;
    }
  }

}
