<!-- Header -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<nav class="navbar navbar-expand-md">
    <div class="container">
        <a class="navbar-brand" href="https://domii.io/"><img src="../../assets/images/logo.png" alt=""></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                    <a class="nav-link" href="#">
                        <i aria-hidden="true" class="fa fa-phone" style="font-size: 18px; color: #000;"></i><a
                            href="tel:1-866-366-4446"> 1-866-366-4446 </a><span style="color: #fff;">----</span> <span
                            style="color: #f75a72;"> | </span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://domii.io/about-us/">
                        About
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://domii.io/blog/">
                        Blog
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://domii.io/contact-us/">
                        Contact
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://account.domii.io/home">
                        Login
                    </a>
                </li>
            </ul>
            <button class="down_btn">
                <a style="color: white;" href="https://domii.io/download/">Download App</a>
            </button>
        </div>
    </div>
</nav>

<!-- Main Content -->
<div class="bg-img">
    <div class="container">
        <h1>Professional Information</h1>
    </div>
</div>
<div class="container">
    <div class="Pro_con">
        <h2>Why Join Domii?</h2>
        <p>Whether clients are looking to remodel a kitchen, paint a bedroom, or landscape their new home, professional
            services are often needed to complete the buying or selling process. By adding a professional team to their
            app profile, real estate agents can refer their clients to their favorite service providers - without paper
            business cards or the risk of losing client interest. Teams allow clients to view and request services from
            the most respected businesses within seconds – and all you have to do in response is accept the lead and
            make an appointment.
        </p>
        <h2 class="bene_hed">Benefits Include:</h2>
        <ul class="bene_inc">
            <li>Inexpensive</li>
            <li>Unbiased</li>
            <li>No hidden fees, no referral fees</li>
            <li>All Inclusive</li>
            <li>Opportunity for growth</li>
            <li>Efficient way to build clientele</li>
        </ul>
        <h2>How It Works</h2>
        <p>Your real estate agent’s clients will select your agent’s team and the lead will be sent directly to you.
            Once sent out, you will receive a notification on your phone that allows you to accept or decline the
            client, and if accepted, you will receive the client’s contact information allowing you to schedule an
            appointment at your earliest convenience. If you decline the lead or don’t accept right away, it will be
            sent to the next closest team member. Leads are sent to professionals/team members based on proximity, so
            there are no biases - the closest team member to the client will get the lead first. The benefits of being
            on a team are exponential, as all of your agent’s clients have the potential to be your clients.
        </p>
        <p>You also have the opportunity to join Domii’s general professional roster. The general professional roster is
            available for those who want to have increased visibility in conjunction with leads from your real estate
            agent. Domii consumers without a linked real estate agent can use our “Find a Pro” feature to find you this
            way – similarly to leads from your agent, there are no biases.
        </p>
        <h2>More Information About Domii:</h2>
        <p>Thank you for considering Domii. Domii is a real estate-based app that allows your realtor to connect with
            their current clients and gain leads. Our goal is to simplify the real estate process by providing
            functional tools that allow your realtor to make their daily tasks more efficient, communicate effectively
            with their clients, and provide new ways to bring modernization to the real estate world. We pride ourselves
            on giving clients the opportunity to be methodical during their home search efforts. Domii provides
            everything a consumer would need during their transaction and by adding professional services to Domii, we
            prepare consumers for the intricacies of the process. Service providers and professionals are also
            benefitted with the additions of Domii Teams and Find a Pro. Our goal is to be an inexpensive option for
            lead generation – no referral fees, no pricey add-ons. Our unbiased system of matching consumers with
            professionals is fast and effective, and by having reliable professionals represented by Domii Teams, we are
            confident that consumers will always feel comfortable. Service providers including Mortgage Lenders and Real
            Estate Agents will be verified by our team to ensure our clients will only be matched with properly licensed
            professionals.
        </p>
        <p>With Domii’s all-in-one platform, everyone wins.</p>
    </div>
</div>

<!-- Footer -->
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <img src="../../assets/images/logo-footer.svg" alt="">
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="footer_icon">
                    <ul>
                        <li>
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                            <a href="https://www.facebook.com/domiihomes"></a>
                        </li>
                        <li>
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                            <a href="https://www.instagram.com/domiihomes/"></a>
                        </li>
                        <li>
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                            <a href="https://twitter.com/domiihomes"></a>
                        </li>
                        <li>
                            <i class="fa fa-youtube-play" aria-hidden="true"></i>
                            <a href="https://www.youtube.com/channel/UCQMLFzGp3RTmIPs0FGVhh-g"></a>
                        </li>
                        <li>
                            <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                            <a
                                href="https://www.pinterest.com/domiihomes/?invite_code=8bc8b29b536845d3b1cd73111c35119f&sender=731975883094771765">
                            </a>
                        </li>
                    </ul>
                    <p>
                        <i class="fa fa-phone" aria-hidden="true"></i><a style="color: white;"
                            href="tel:1-866-366-4446">1-866-366-4446</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-5 col-sm-12">
                <ul class="menu">
                    <li><a href="https://domii.io/about-us/">About</a></li>
                    <li><a href="https://domii.io/blog/">Blog</a></li>
                    <li><a href="https://domii.io/contact-us/">Contact</a></li>
                    <li><a href="https://account.domii.io/home">Login</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="btm_fotter">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <p>Copyright © 2021 All rights reserved | For
                    <a href="https://secure.domii.io/real-estate-agent"> Real Estate Agents </a> |
                    <a href="https://domii.io/privacy-policy/">Privacy Policy </a>
                </p>
            </div>
            <div class="col-md-6">
                <img src="../../assets/images/poweredBy-ia9.svg" alt="">
            </div>
        </div>
    </div>
</div>