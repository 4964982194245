<app-header></app-header>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<div class="DashmainContainer" *ngIf="upgradeCodeStatus === true">
    <div class="col-md-12 col-xs-12 font16px mbrshipSection">
        <h1 class="title">
            Realtor - Professional Invite For Domii Professional Members
        </h1><br><br>
        <h3 class="select-planhead">Select your plan</h3><br>
        <div class="row col-md-12 col-xs-12 feeDetails">
            <div class="plan-area" *ngFor="let plan of membershipProfessionalPlans ; let i = index" [ngStyle]="{
                        'border': plan.selected === true ? '1px solid #F75A72' : ''
                      }">
                <div class="rdio rdio-primary radio-inline">
                    <input [id]="'radio-' + i" type="radio" name="membershipPlan" [value]="plan.code"
                        [ngClass]="{'is-invalid': submitted && form.membershipPlan.errors}"
                        (change)="radioChecked(plan, i)" [checked]="plan.selected">
                    <label [for]="'radio-' + i" [innerHTML]="plan.description">
                    </label>
                    <div class="rightside-area" *ngIf="plan.price !== 'Free'">
                        <p>{{ plan.price }}
                            <sub>/{{ plan.frequency }}</sub>
                        </p>
                    </div>
                    <div class="rightside-area" *ngIf="plan.price === 'Free'">
                        <p>{{ plan.price }}
                        </p>
                    </div>
                </div>
            </div>
            <p class="corporate-pricing">
                <span class="">*Corporate Pricing available</span>,
                Please contact our Customer Service at
                <a href="tel:8663664446">1-866-366-4446</a>
            </p>
        </div>
    </div>
    <div>
        <div id="dropin-container"></div>
    </div>
    <div class="flexDspl row" style="margin-bottom: 150px;">
        <div class="col-md-6 col-xs-12 ipadfullwidth">
            <button type="submit" class="btn submitButtonnew" (click)="onUpdate($event)">
                Join Now
            </button>
        </div>
    </div>
</div>
<section>
    <p-dialog header="" [modal]="true" [(visible)]="displayErrorPopup" [style]="{width: '575px', padding: '10px'}"
        [baseZIndex]="10000" position="center" [draggable]="false" [resizable]="false">
        <div class="ErrorContainer">
            <div class="container">
                <div class="col-lg-12">
                    <section>
                        <p>Something went wrong. Please try again later or contact customer service,
                            <a href="tel:1-866-366-4446">1-866-366-4446</a>,
                            <a href="support@ia9inc.com">support@ia9inc.com</a>.
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="backToHome()" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>
<div class="DashmainContainer" *ngIf="upgradeCodeStatus === false">
    <div class="col-md-12 col-xs-12 font16px mbrshipSection">
        <h1 class="title">
            {{errorMessage}}
        </h1>
    </div>
</div>
<app-footer></app-footer>