import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, catchError } from 'rxjs/operators';
import { isDevMode } from '@angular/core';
import {
  Observable,
  Observer,
  BehaviorSubject,
  throwError,
  observable,
} from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ConsumerService {
  realtorProfile: EventEmitter<any> = new EventEmitter<any>();
  private openCancelPlan = new BehaviorSubject<any>({
    open: false,
    profile: null,
  });
  mainX_CLIENT_IDCode = '';
  mainLightningUrl = '';
  mainLightningCode = '';
  readonly lightningBaseUrl = 'https://ia9lightning';
  readonly urlSuffix = '.azurewebsites.net/api/';
  readonly ENVIRONMENT_VALUE = '-dev';
  readonly lightningCODEDEV = 'yIcsZ9MioJPgcHQoCDUAaS6EyuG/4odgd7cWniq0tM4OfFxYR0favQ==';
  readonly lightningCODE = 'ucLrkFVooPmg4Z76c5hbsw/1fZrXV2EcClIr6MLrX0HvkHFgeHZV3A==';
  readonly PRODUCTION_VALUE = '';
  readonly X_CLIENT_IDCODE = 'c606af7f-9da0-4360-b878-27dfa8a43238';
  readonly X_CLIENT_IDCODEDEV = 'fee8cc43-afd8-4da8-a4e3-6639745c4428';
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private loadingService: NgxSpinnerService
  ) {
    if (isDevMode()) {
      this.mainLightningUrl = this.lightningBaseUrl + this.ENVIRONMENT_VALUE + this.urlSuffix;
      this.mainLightningCode = this.lightningCODEDEV;
      this.mainX_CLIENT_IDCode = this.X_CLIENT_IDCODEDEV;
    } else {
      this.mainLightningUrl = this.lightningBaseUrl + this.PRODUCTION_VALUE + this.urlSuffix;
      this.mainLightningCode = this.lightningCODE;
      this.mainX_CLIENT_IDCode = this.X_CLIENT_IDCODE;
    }
  }
  registerConsumer(url, body, clientId) {
    const headers = new HttpHeaders().set('X_CLIENT_ID', clientId);
    return this.http.post(url, body, { headers: headers });
  }
  getAgentDetails(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this.loadingService.show();
    return this.http
      .get(
        `${this.mainLightningUrl}lightning/validate?mid=${payload.mid}&aid=${payload.aid}&code=${this.mainLightningCode}`,
        { headers }
      )
      .pipe(
        map((data) => {
          this.loadingService.hide();
          return data;
        }),
        catchError((errRes) => {
          let errorMessage: string;
          if (errRes.error && errRes.error.description) {
            errorMessage = `${errRes.error.description}`;
          } else {
            errorMessage =
              'There was an issue, please try again or check back later.';
          }
          this.toastr.error(errorMessage);
          this.loadingService.hide();
          return throwError(errorMessage);
        })
      );
  }
  enrollAgentId(aid: any, code: any, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', this.mainX_CLIENT_IDCode);
    if (!aid || !code) {
      this.toastr.error('agentId or code required!');
    }
    this.loadingService.show();
    return this.http
      .post(
        `${this.mainLightningUrl}registration/enroll/${aid}?code=${code}`,
        body,
        { headers }
      )
      .pipe(
        map((data) => {
          this.loadingService.hide();
          this.toastr.success('Enroll success!');
          return data;
        }),
        catchError((err) => {
          if (err.error) {
            console.log(err);
            this.toastr.error(err.error.description);
          }
          this.loadingService.hide();
          if (err.status == 401 || err.status == 403) {
            this.toastr.error(err.statusText);
          }
          return throwError(err);
        })
      );
  }
}
