import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { OrderListModule } from 'primeng/orderlist';
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { DropdownModule } from 'primeng/dropdown';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { ChartModule } from 'primeng/chart';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import {MultiSelectModule} from 'primeng/multiselect';
import { FileUploadModule } from 'primeng/fileupload';
import { NgModule } from '@angular/core';

const PRIMENG_MODULES = [
    DialogModule,
    MenuModule,
    OrderListModule,
    ConfirmPopupModule,
    DropdownModule,
    TabMenuModule,
    ToastModule,
    MultiSelectModule,
    AccordionModule,
    ChartModule,
    PaginatorModule,
    TableModule,
    CalendarModule,
    CardModule,
    ConfirmDialogModule,
    RadioButtonModule,
    FileUploadModule
]

@NgModule({
    imports: [
        PRIMENG_MODULES
    ],
    exports: [
        PRIMENG_MODULES
    ]
})
export class PrimeNGModule { }