import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  userCache: any;
  token: string;

  constructor() { }
}
