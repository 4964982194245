<div class="container-fluid">
    <div class="row">
        <div class="col-md-7 col-xs-12 padtop3 textCenter marbot110px">
            <h2 class="wlcmMsg">Welcome to</h2>
            <img src="" id="companyLogo" alt="Logo">
            <img class="signInImg" src="../../../assets/images/login-wlc.svg" alt="Reset pwd page image">
        </div>
        <div class="col-md-5 col-xs-12 backgroundWhite marbot110px">
            <div class="card noBorder">
                <div class="card-body">
                    <h4 class="card-title" style="font-weight: 600;">Reset Password</h4>
                    <form [formGroup]="resetPwdForm" class="martop20px">
                        <div class="row">
                            <div class="form-group col-md-8 col-xs-12">
                                <label for="newPassword">New Password</label>
                                <input type="password" class="form-control" formControlName="newPassword" id="newPassword" placeholder="Enter Password" [ngClass]="{'is-invalid': submitted && form.newPassword.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.newPassword.errors">
                                    <span *ngIf="form.newPassword.errors.required">Please enter Password</span>
                                    <span *ngIf="form.newPassword.errors.minlength">Password must be atleast 8 characters</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-8 col-xs-12">
                                <label for="confirmPassword">Confirm Password</label>
                                <input type="password" class="form-control" formControlName="confirmPassword" id="confirmPassword" placeholder="Confirm Password" [ngClass]="{'is-invalid': submitted && form.confirmPassword.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.confirmPassword.errors">
                                    <span *ngIf="form.confirmPassword.errors.required">Please enter Confirm Password</span>
                                    <span *ngIf="form.confirmPassword.errors.mustMatch">Password Not Matching</span>
                                </div>
                            </div>
                        </div>
                        <div class="row martop20px">
                            <div class="col-md-8 col-xs-12">
                                <button type="submit" class="btn submitButton" (click)="onSubmit()">Submit</button>
                            </div>
                        </div>
                    </form>
                    <div class="martop20px">
                        Return to <a routerLink="/signin"><strong>Sign In</strong></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>