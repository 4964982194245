import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { MENUITEMS } from 'src/app/data/menuItems';
import { MenuModel } from 'src/app/models/menuModel';
import { LoginService } from 'src/app/services/login/login.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { TokenService } from 'src/app/services/token/token.service';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  subscription: Subscription = new Subscription();
  token: string = '';
  isMobile: boolean = false;
  menuList: MenuModel[] = [];
  showPlanAlert = false;
  items: MenuItem[];
  membershipUsers: MenuItem[];
  userProfile: any;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private tokenService: TokenService,
    private environmentService: EnvironmentService,
    private professionalService: ProfessionalService,
    private loginService: LoginService,
    private primengConfig: PrimeNGConfig,
    private spinner: NgxSpinnerService) {
    this.showHideBenefits(window.innerWidth);
    this.token = sessionStorage.getItem('msal.idtoken');
    this.getUserDetails();
  }

  ngOnInit(): void {
    let url = window.location.pathname.split("/")[1];
    this.showHidePlanAlert(window.innerWidth);
    for (const menu of MENUITEMS) {
      const m = new MenuModel();
      m.id = menu.id;
      m.selected = menu.selected;
      m.menu = menu.menu;
      m.route = menu.route;
      m.img = menu.route == url ? menu.activeImg : menu.img;
      m.activeImg = menu.activeImg;
      this.menuList.push(m);
    }
    if (sessionStorage.getItem("userType") == "1") {
      // this.addPricingPlansMenu();
    }
    let index = this.menuList.findIndex(m => m.route == url);
    for (let i = 0; i < this.menuList.length; i++) {
      if (this.menuList[i].route === url) {
        this.menuList[index].selected = true;
        this.menuList[index].img = this.menuList[index].activeImg;
      }
    }
    this.subscription = this.professionalService.realtorProfile.subscribe(value => {
      if (value?.AgentType == 1) {
        // this.addPricingPlansMenu();
      }
    });
    this.primengConfig.ripple = true;

    // Open House
    this.items = [{
      items: [{
        label: 'Open House Sign In',
        icon: 'pi pi-external-link',
        url: '/open-house',
        command: () => { }
      },
      {
        label: 'Sign In Summary',
        icon: 'pi pi-external-link',
        url: '/open-house-signin-summary',
        command: () => { }
      }]
    }];
  }

  getUserDetails() {
    this.spinner.show();
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.professionalService.getUserInfo(url, this.token).subscribe(
      response => {
        this.userProfile = response;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      });
  }

  showHideBenefits(width) {
    if (width <= 1023) {
      if (this.isMobile == false) {
        this.isMobile = true;
      }
    }
    else {
      this.isMobile = false;
    }
  }

  // gotoListing() {
  //   if (this.token) {
  //     this.router.navigateByUrl(`/open-house?token=${this.token}`);
  //   } else {
  //     this.toastr.warning('Waiting for token..')
  //   }
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showHidePlanAlert(event.target.innerWidth);
  }

  showHidePlanAlert(width) {
    if (width <= 768) {
      this.showPlanAlert = false;
    } else {
      this.showPlanAlert = true;
    }
  }

  addPricingPlansMenu() {
    if (this.menuList.findIndex(m => m.id == 5) == -1) {
      this.menuList.splice(1, 0, {
        id: 5,
        selected: false,
        menu: "Pricing Plans",
        route: "pricingPlans",
        img: "../../../assets/images/plans.svg",
        activeImg: "../../../assets/images/plans-active.svg"
      });
    }
    if (MENUITEMS.findIndex(m => m.id == 5) == -1) {
      MENUITEMS.splice(1, 0, {
        id: 5,
        selected: false,
        menu: "Pricing Plans",
        route: "pricingPlans",
        img: "../../../assets/images/plans.svg",
        activeImg: "../../../assets/images/plans-active.svg"
      });
    }
  }

  loadMenu(selectedMenu: MenuModel) {
    for (const menu of this.menuList) {
      const masterObj = MENUITEMS.find(m => m.id === menu.id);
      if (menu.id != selectedMenu.id) {
        menu.selected = false;
        menu.img = masterObj.img;
      } else {
        menu.selected = true;
        menu.img = masterObj.activeImg;
      }
    }
    if (selectedMenu.id == 2) {
      this.professionalService.emitCancelPlan(this.loginService.profileInfo);
    } else if (selectedMenu.id == 3) {
      this.loginService.resetPassword();
    } else {
      this.router.navigate([selectedMenu.route]);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
