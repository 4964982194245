<app-header></app-header>
<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<section>
  <div class="back_btn">
    <a routerLink="/dashboard">
      <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
  </div>
  <div class="DashmainContainer">
    <div class="mem_ship">
      <div class="tbl_des">
        <h1 class="title">Membership</h1>
        <h1>Membership Details</h1>
        <div class="card">
          <p-table [value]="userProfile?.MembershipPlans" dataKey="id" [rows]="5" responsiveLayout="scroll"
            [showCurrentPageReport]="true" [paginator]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>Membership status</th>
                <th>Current plan</th>
                <th>ASSOCIATED REALTOR</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-plan>
              <tr>
                <td *ngIf="plan.IsActive === true">Active</td>
                <td (click)="renewMembershipPlan()" *ngIf="plan.IsActive !== true">
                  <a style="text-decoration: underline;color: #f75a72 !important;cursor: pointer;">Renew/Activate</a>
                </td>
                <td>{{plan.Description}}</td>
                <td>{{plan.AssociatedPersonName}}</td>
                <td *ngIf="plan.IsActive === true">
                  <button (click)="cancelSubscription($event, plan)" pButton label="Cancel Membership"
                    class="p-button-text">
                  </button>
                </td>
                <td *ngIf="plan.IsActive === false">
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="tbl_des">
        <div class="tbl">
          <h1>Transaction History</h1>
          <button class="btn export_btn" (click)="export()">
            <img src="../../../assets/images/ic-file-download.svg" alt="">Export
          </button>
        </div>
        <div class="card">
          <p-table #dt [columns]="cols" [value]="paymentHistoryDetails" dataKey="id" [rows]="5"
            responsiveLayout="scroll" [showCurrentPageReport]="true" [paginator]="true">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="date">Date<p-sortIcon field="date"></p-sortIcon>
                </th>
                <th>Amount</th>
                <th>Status</th>
                <th>Description</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-payment>
              <tr>
                <td>{{payment.date | date: 'MM/dd/yyyy'}}</td>
                <td *ngIf="payment.amount !== 'Free'">{{payment.amount}}</td>
                <td *ngIf="payment.amount === 'Free'">{{payment.amount}}</td>
                <td>{{payment.status}}</td>
                <td>{{payment.description}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <!-- <div class="flexDspl row">
                <div class="col-md-6 col-xs-12 ipadfullwidth">
                    <button type="submit" class="btn submitButtonnew" (click)="cancelSubscription($event)">
                        Cancel Membership
                    </button>
                </div>
            </div> -->
    </div>
  </div>
</section>
<section>
  <p-dialog header="" [modal]="true" [(visible)]="displayErrorPopup" [style]="{width: '575px', padding: '10px'}"
    [baseZIndex]="10000" position="center" [draggable]="false" [resizable]="false">
    <div class="ErrorContainer">
      <div class="container">
        <div class="col-lg-12">
          <section>
            <p>Something went wrong. Please try again later or contact customer service,
              <a href="tel:1-866-366-4446">1-866-366-4446</a>,
              <a href="support@ia9inc.com">support@ia9inc.com</a>.
            </p>
          </section>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button (click)="backToHome()" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>
</section>
<app-footer></app-footer>
