import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss'],
})
export class TestimonialComponent implements OnInit {
  xClientId: string;
  feedback: any;
  tstmonCode: '';
  queryParms: any;
  testimonialForm: FormGroup;
  feedbackSubmittedthanks: boolean = false;
  feedbackAlredaySubmitted: boolean = false;
  feedbackSubmittedError: boolean = false;
  errorMessage: any;

  constructor(
    private fb: FormBuilder,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.xClientId = this.environmentService.getXClientId();
    this.tstmonCode = this.activatedRoute.snapshot.params['code'];

    this.testimonialForm = this.fb.group({
      name: ['', Validators.required],
      code: [''],
      townCity: ['', Validators.required],
      testimonialText: ['', Validators.required],
    });

    this.getVerifyTestimonial();
  }

  getVerifyTestimonial() {
    const url =
      this.environmentService.getIA9LightningUrl() +
      'agent/testimonials/verify/' +
      this.tstmonCode +
      '?code=yIcsZ9MioJPgcHQoCDUAaS6EyuG%2F4odgd7cWniq0tM4OfFxYR0favQ%3D%3D';

    this.spinner.show();
    this.professionalService.getTestimonialList(url, this.xClientId).subscribe(
      (x) => {
        this.feedback = x.data;
        this.testimonialForm.controls['code'].setValue(this.tstmonCode);
        this.spinner.hide();
      },
      (error) => {
        if (error.error.statusCode == 11000) {
          this.feedbackAlredaySubmitted = true;
        }
        this.feedbackSubmittedError = true;
        this.errorMessage = error.error.description;

        this.toastr.error(error.error.description);
        this.spinner.hide();
      }
    );
  }

  onSaveClick() {
    console.log('i am in onSubmit...!');
    const url =
      this.environmentService.getIA9LightningUrl() +
      'agent/testimonials/' +
      this.tstmonCode +
      '?code=yIcsZ9MioJPgcHQoCDUAaS6EyuG%2F4odgd7cWniq0tM4OfFxYR0favQ%3D%3D';
    const profReg = this.testimonialForm.getRawValue();
    console.log(profReg);
    this.spinner.show();
    this.professionalService
      .registerProfessional(url, profReg, this.xClientId)
      .subscribe(
        (response: any) => {
          if (response.isSuccess) {
            this.feedbackSubmittedthanks = response.isSuccess;
          }
          this.spinner.hide();
        },
        (error) => {
          console.log(error);
          this.feedbackSubmittedError = true;
          this.errorMessage = error.error.description;
          this.toastr.error(error.error.description);
          this.spinner.hide();
        }
      );
  }
}
