import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as dropin from 'braintree-web-drop-in';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MsalService } from '@azure/msal-angular';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-realtor-membership-upgrade',
  templateUrl: './realtor-membership-upgrade.component.html',
  styleUrls: ['./realtor-membership-upgrade.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RealtorMembershipUpgradeComponent implements OnInit {

  membershipRealtorsPlans: [] = [] as any;
  xClientId: string;
  dropInInstance: dropin.DropinModel;
  token: string = '';
  _email: string = '';
  _agentId: string = '';
  _payload = {
    "email": '',
    "paymentMethodToken": '',
    "MembershipPlanCode": '',
    "AgentId": '',
    "code": ''
  }
  displayErrorPopup: boolean = false;
  validateCode: string = ''
  userProfile: any;
  upgradeCodeStatus: string = '';
  errorMessage: string = '';

  constructor(
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private realtorService: RealtorService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private msalService: MsalService,
    private loginService: LoginService
  ) {
    this.validateCode = this.route.snapshot.params['code'];
    this._payload.code = this.validateCode;
    this.token = sessionStorage.getItem('msal.idtoken');
    this.xClientId = this.environmentService.getXClientId();
  }

  ngOnInit(): void {
    if (!this.token) {
      setTimeout(() => {
        this.loginService.login();
      }, 0);
      return;
    }
    this.getUserDetails();
  }

  getUserDetails() {
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.professionalService.getUserInfo(url, this.token).subscribe(
      response => {
        this.userProfile = response;
        if (this.userProfile.MembershipPlans !== null) {
          this._payload.AgentId = this.userProfile.MembershipPlans[0].AssociatedId;
          this._payload.email = this.userProfile.Email;
          if (this.userProfile.UserLevel === 1 && this.userProfile.MembershipPlans[0].IsActive === false) {
            this.validateUpgradeCode();
          } else {
            this.router.navigate(['/membership/realtors']);
          }
        } else {
          this.displayErrorPopup = true;
        }
      },
      error => { });
  }

  validateUpgradeCode() {
    let url = this.environmentService.getIA9LightningUrl() + 'registration/validate/code/' + this.validateCode + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.realtorService.getValidateUpgradeCode(url, this.token, this.xClientId).subscribe(
      (response: any) => {
        this.upgradeCodeStatus = response.data.status;
        if (this.upgradeCodeStatus === 'Pending') {
          this.createDropIn();
          this.retrievePlans();
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        if (error.error.data !== null) {
          this.upgradeCodeStatus = error.error.data.status;
          if (this.upgradeCodeStatus === 'Completed') {
            // this.errorMessage = error.error.description.split('.')[0];
            this.router.navigate(['/membership/realtors']);
            return false;
          }
        } else {
          // this.errorMessage = `Code  ${this.validateCode} is invalid`;
          this.router.navigate(['/membership/realtors']);
          return false;
        }
      }
    );
  }

  createDropIn() {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
    this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        dropin.create({
          authorization: response.data,
          container: document.getElementById('dropin-container')
        }).then((dropinInstance) => {
          this.dropInInstance = dropinInstance;
        }).catch((error) => { console.log(error) });
      }
    }, (error) => {
      console.log(error);
    });
  }

  retrievePlans() {
    const url = this.environmentService.getIA9LightningUrl() + 'payment/realtorupgrade/plans?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.professionalService.getMembershipPlansRealtors(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        this._payload.MembershipPlanCode = response?.data[0].code;
        this.spinner.hide();
      }
    }, (error) => {
      this.spinner.hide();
    });
  }

  // radioChecked(data, i) {
  //   this.membershipRealtorsPlans.forEach((item: any) => {
  //     if (item.uid !== data.uid) {
  //       item.selected = false;
  //     } else {
  //       item.selected = true;
  //     }
  //   })
  //   this._payload.MembershipPlanCode = data.code;
  // }

  onUpdate(event: Event) {
    // if (this._payload.MembershipPlanCode === '') {
    //   this.toastr.warning('Please select a plan!..');
    //   return false;
    // }
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dropInInstance.requestPaymentMethod().then((payload) => {
          this._payload.paymentMethodToken = payload.nonce;
          const url = this.environmentService.getIA9LightningUrl() + 'payment/update?code=' + this.environmentService.getIA9LightningKey();
          this.spinner.show();
          this.realtorService.updatePlan(url, this._payload, this.xClientId, this.token).subscribe((response) => {
            this.spinner.hide();
            if (response['isSuccess'] === true) {
              this.messageService.add({ severity: 'info', summary: 'Upgrade', detail: 'Thank you for upgrading your membership. If you have concerns, questions, please contact 8663664446, support@ia9inc.com.' });
              this.router.navigate(['/membership/realtors']);
            }
          },
            (error) => {
              this.displayErrorPopup = true;
              this.spinner.hide();
            });
        }).catch((error) => { console.log(error) });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected.' });
      }
    });
  }

  backToHome() {
    this.displayErrorPopup = false;
    this.router.navigate(['/dashboard']);
  }
}
