import { Component, EventEmitter, HostListener, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';


@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit, OnDestroy {

  @Input('visible') visible: boolean;
  @Input('content') content: string;
  @Input('toastType') toastType: string;
  @Output() closeToast = new EventEmitter();

  private subscription: Subscription;
  private timer: Observable<number>;

  constructor() { }

  ngOnInit(): void {
    this.autoHideToast();
  }

  autoHideToast(){
    this.timer = timer(3000);
    this.subscription = this.timer.subscribe(() => {
      this.visible = false;
      this.closeToast.emit();
    })
  }

  @HostListener('unloaded')
  ngOnDestroy(){
    this.visible = false;
  }

}
