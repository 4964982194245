import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ProfessionalService } from '../../services/professional/professional.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-searchvaluemii',
  templateUrl: './searchvaluemii.component.html',
  styleUrls: ['./searchvaluemii.component.scss']
})
export class SearchvaluemiiComponent implements OnInit {

  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;
  search: string;
  xClientId: string;
  formatedAddress: string = '';
  handleGoogleSearchInput: boolean;
  locality: string;
  street_address: string;
  region: string;
  postal_code: string;
  isExtimated: boolean = false;
  extimatedvalue: any;
  extimationError: boolean = false;

  options: any = {
    types: [],
    componentRestrictions: { country: "US" }
  };

  propertySearchPayload: any = {
    propertySearchField: '',
    lat: 0,
    lng: 0,
    tokenizedAddress: ''
  };

  constructor(
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe,
  ) { }

  ngOnInit(): void {
    this.xClientId = this.environmentService.getXClientId();
  }

  public getGoogleSearchType(address: Address) {
    let googleSearchType = 'default';
    let searchLocation = '';

    if (address.types && address.types.length > 0) {
      if (address.types.indexOf('street_address') >= 0 || address.types.indexOf('premise') >= 0) {
        googleSearchType = 'street_address';

        // append to this type of search the lat, long and radius to search
        if (address.geometry && address.geometry.location) {
          googleSearchType += '&near=' + address.geometry.location.lat() + ',' + address.geometry.location.lng() + '&radius=5';
        }
      }
    }

    return '&searchType=' + googleSearchType;
  }
  replaceStreet(address: string) {
    const streetTypes = {
      "Road": "Rd",
      "road": "Rd",
      "Street": "St",
      "street": "St",
      "Lane": "Ln",
      "lane": "Ln",
      "Terrace": "Ter",
      "terrace": "Ter",
      "Circle": "Cir",
      "circle": "Cir",
      "Drive": "Dr",
      "drive": "Dr",
      "Avenue": "Ave",
      "avenue": "Ave",
      "Place": "Pl",
      "place": "Pl",
      "Court": "Ct",
      "court": "Ct"
    };

    let streetRegex = new RegExp(Object.keys(streetTypes).join('|'), "gi");

    address = address.replace(streetRegex, function (matched) {
      return streetTypes[matched.toLowerCase()];
    });

    return address.substring(0, address.length - 5); // remove the USA part
  }

  public getTokenizedAddress(address: Address) {
    if (address.adr_address) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(address.adr_address, 'text/html');
      this.locality = doc.getElementsByClassName('locality')[0].innerHTML.toString();
      this.street_address = doc.getElementsByClassName('street-address')[0].innerHTML.toString();
      this.region = doc.getElementsByClassName('region')[0].innerHTML.toString();
      this.postal_code = doc.getElementsByClassName('postal-code')[0].innerHTML.toString();
    }
  }

  handleAddressChange(address: any) {
    this.propertySearchPayload.propertySearchField = address.formatted_address;
    this.propertySearchPayload.propertySearchField = this.replaceStreet(this.propertySearchPayload.propertySearchField);
    this.propertySearchPayload.lat = address.geometry.location.lat();
    this.propertySearchPayload.lng = address.geometry.location.lng();
    this.getTokenizedAddress(address);
  }

  closeClicked(){
    this.extimationError = false;
    this.isExtimated = false;
  }

  getValuation() {
    const url =
      this.environmentService.getIA9LightningUrl() +
      'rets/avm' +
      '?cities=' + this.region +
      '&street=' + this.street_address +
      '&points=' + this.propertySearchPayload.lat + ',' + this.propertySearchPayload.lng + '&limit=100' +
      '&code=yIcsZ9MioJPgcHQoCDUAaS6EyuG%2F4odgd7cWniq0tM4OfFxYR0favQ%3D%3D' +
      '&listingId=';

    this.spinner.show();
    this.professionalService.getValueMiiListing(url, this.xClientId).subscribe(
      (x) => {
        this.isExtimated = true;
        this.extimationError = false;
        this.extimatedvalue = x.data;
        console.log(this.extimatedvalue);
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error(error.error.description);
        this.isExtimated = false;
        this.extimationError = true;
        this.spinner.hide();
      
      }
    );
  }

}
