<div class="container-fluid Business-professionalcontainer">
  <div class="row">
    <div class="col-md-5 col-xl-6 col-xs-12 padtop3 textCenter marbot110px ipadfullwidth">
      <div class="logo-section">
        <img class="signUpImg martop5 logo-img" src="../../../assets/images/logo.svg" alt="signup page image">
      </div>
      <h2 class="wlcmMsg">Welcome to Domii!</h2>
      <div class="gps-leadsdiv">
        <h3>Get Live Leads as hundreds of consumers search for homes and request services directly from their Domii app.
        </h3>
        <p class="tap-respond">They tap, you respond, it’s that easy!</p>
        <div class="list-views">
          <ul [class.moredisplay]="showBenefits">
            <li>
              <div><img src="../../../assets/images/bullet.svg"></div>
              <p>Domii is a tech company that markets to thousands of consumers that are looking for homes, refinancing,
                or are in need of home improvement services.</p>
            </li>
            <li>
              <div><img src="../../../assets/images/bullet.svg"></div>
              <p>If a consumer needs your service, Domii will reach out to the closest service provider based on your
                GPS location. If you are the closest, you will get notified instantly-- if you are in the right place at
                the right time, you get the lead! Once locked in, the lead is yours.</p>
            </li>
            <li>
              <div><img src="../../../assets/images/bullet.svg"></div>
              <p>Do you work closely with real estate agents? Domii allows you to have access to their clients with the
                use of real estate teams. Link your account to your agent of choice and their clients become your
                leads-- no GPS required.</p>
            </li>
            <li>
              <div><img src="../../../assets/images/bullet.svg"></div>
              <p>Fill out the registration form, choose your plan and start leveraging your business relationships like
                never before. Welcome to the Domii team!</p>
            </li>
            <p class="bottom-text">Download Domii at the Apple or Play store today and realize the benefits consumers
              are raving about. Domii is an app created not just for real estate agents and businesses, but for buyers
              and sellers as well. Welcome to Domii-- innovation at its best!</p>
            <!-- <p class="tap-respond"><a href="https://account-staging.domii.io/professional-info">Learn more</a></p> -->
            <div class="bottom-image">
              <img src="../../../assets/images/download-apple-store.png" class="mr-3" (click)="openAppStore()">
              <img src="../../../assets/images/download-google-play.png" (click)="openPlayStore()">
            </div>
          </ul>
          <div *ngIf="isMobile" class="veiwmorebtn">
            <span class="viewMoreTxt" (click)="showBenefits = !showBenefits">
              {{showBenefits ? 'View Less': 'View More'}}
              <img
                [src]="showBenefits ? '../../../assets/images/chevron-up.svg' : '../../../assets/images/chevron-down.svg'">
            </span>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-7 col-xl-6 col-xs-12 padtop3 backgroundWhite marbot110px ipadfullwidth">
      <div class="card noBorder">
        <div class="card-body">
          <!-- <div class="card-header-div">
            <h4 class="card-title" style="font-weight: 600;padding-top: 4%;">Business Professionals Sign Up</h4>
            <p class="already-account" style="padding-top: 4%;">Already have an account,
              <a (click)="login()">Sign In</a>
            </p>
          </div> -->
          <div style="margin-bottom: 1.5rem; text-align: center;">
            <div class="col-xs-12">
              <h4 class="card-title">Business Professionals Sign Up
              </h4>
            </div>
            <!-- <div class="col-md-6 col-xs-12">
              <p class="already-account">Already have an account,
                <a (click)="login()">Sign In</a>
              </p>
            </div> -->
          </div>
          <!-- <div>
            <h5 class="signup-heading">get connected with hundreds of Consumers and Agents on Domii</h5>
          </div> -->
          <form [formGroup]="signUpForm">
            <div class="row">
              <div class="form-group col-md-6 col-xs-12">
                <label for="firstName">First Name</label>
                <input type="text" class="form-control" formControlName="firstName" id="firstName"
                  placeholder="Enter First Name" [ngClass]="{'is-invalid': submitted && form.firstName.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.firstName.errors?.required">
                  <span>Please enter First Name</span>
                </div>
              </div>
              <div class="form-group col-md-6 col-xs-12">
                <label for="lastName">Last Name</label>
                <input type="text" class="form-control" formControlName="lastName" id="lastName"
                  placeholder="Enter Last Name" [ngClass]="{'is-invalid': submitted && form.lastName.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.lastName.errors?.required">
                  <span>Please enter Last Name</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 col-xs-12">
                <label for="mobileNumber">Mobile Number</label>
                <input type="phone" class="form-control" formControlName="mobileNumber" id="mobileNumber"
                  placeholder="Enter Mobile Number"
                  [ngClass]="{'is-invalid': (submitted || form.mobileNumber.dirty) && form.mobileNumber.errors}"
                  maxlength="12">
                <div class="invalid-feedback"
                  *ngIf="(submitted || form.mobileNumber.dirty) && form.mobileNumber.errors">
                  <span *ngIf="form.mobileNumber.errors.required">Please enter Mobile Number</span>
                  <span *ngIf="form.mobileNumber.errors.pattern">Please enter Valid Mobile Number</span>
                </div>
              </div>
              <div class="form-group col-md-6 col-xs-12">
                <label for="emailId">Email Address</label>
                <input type="email" class="form-control" formControlName="emailId" id="emailId"
                  placeholder="Enter Email Address"
                  [ngClass]="{'is-invalid': (submitted || form.emailId.dirty) && form.emailId.errors}"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" (change)="validateEmail($event)">
                <div class="invalid-feedback" *ngIf="(submitted || form.emailId.dirty) && form.emailId.errors">
                  <span *ngIf="form.emailId.errors.required">Please enter Email</span>
                  <span *ngIf="form.emailId.errors.pattern">Please enter Valid Email</span>
                </div>
                <div class="invalid-feedback" *ngIf="displayEmailError">
                  <p>{{errorMessage }}<span style="text-decoration: underline;
                  color: black;cursor: pointer;" (click)="redirectToLogin()"> Sign In</span></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 col-xs-12">
                <label for="password">Password</label>
                <input type="password" class="form-control" formControlName="password" id="password"
                  placeholder="Enter Password" [ngClass]="{'is-invalid': submitted && form.password.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.password.errors">
                  <span *ngIf="form.password.errors.required">Please enter Password</span>
                  <span *ngIf="form.password.errors.minlength">Password must be atleast 8 characters</span>
                </div>
              </div>
              <div class="form-group col-md-6 col-xs-12">
                <label for="confirmPassword">Confirm Password</label>
                <input type="password" class="form-control" formControlName="confirmPassword" id="confirmPassword"
                  placeholder="Confirm Password" [ngClass]="{'is-invalid': submitted && form.confirmPassword.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.confirmPassword.errors">
                  <span *ngIf="form.confirmPassword.errors.required">Please enter Confirm Password</span>
                  <span *ngIf="form.confirmPassword.errors.mustMatch">Password Not Matching</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 col-xs-12">
                <label for="professionalType">Professional Type</label>
                <select class="form-control" formControlName="proType" id="proType"
                  [ngClass]="{'is-invalid': submitted && form.proType.errors}"
									(change)='retrievePlans($event)' >
                  <option value="" class="option">Select Professional Type</option>
                  <option *ngFor="let pt of professionalTypes" class="option" value="{{pt.type}}">{{pt.name}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="submitted && form.proType.errors?.required">
                  <span>Please select Professional Type</span>
                </div>
              </div>
              <div class="form-group col-md-6 col-xs-12">
                <label for="companyName">Company Name</label>
                <input type="text" class="form-control" formControlName="companyName" id="companyName"
                  placeholder="Enter Company Name" [ngClass]="{'is-invalid': submitted && form.companyName.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.companyName.errors?.required">
                  <span>Please enter Company Name</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 col-xs-12">
                <label for="address">Company Address</label>
                <input type="text" class="form-control" formControlName="address" id="address"
                  placeholder="Enter Address" [ngClass]="{'is-invalid': submitted && form.address.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.address.errors?.required">
                  <span>Please enter Address</span>
                </div>
              </div>
              <div class="form-group col-md-6 col-xs-12">
                <label for="companyUrl">Company/Personal Website URL</label>
                <input type="text" class="form-control" formControlName="companyUrl" id="companyUrl"
                    placeholder="Enter Company/Personal Website URL"
                    [ngClass]="{'is-invalid': (submitted || form.companyUrl.dirty) && form.companyUrl.errors }">
                <div class="invalid-feedback" *ngIf="(submitted || form.companyUrl.dirty) && form.companyUrl.errors">
                    <span>Please enter a valid Company/Personal Website URL</span>
                </div>
              </div>
              <div class="form-group col-md-6 col-xs-12">
                <label for="licenseInfo">License Number (If Applicable)</label>
                <input type="text" class="form-control" formControlName="licenseInfo" id="licenseInfo"
                  placeholder="Enter License Info">
              </div>
              <div class="form-group col-md-6 col-xs-12">
                <label for="licenseExpiration">Expiration Date</label>
                <input type="date" class="form-control" formControlName="licenseExpiration"
                    id="licenseExpiration" placeholder="YYYY-MM-DD" [min]="tomorrowDate"
                    (change)="isExpirationDateValid()">
                <div class="invalid-feedback"
                    *ngIf="submitted && form.licenseExpiration.errors?.required">
                    <span>Please enter Expiration Date</span>
                </div>
                <div class="invalid-feedback"
                    *ngIf="submitted && form.licenseExpiration.errors?.min">
                    <span>Expiration Date has passed</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 col-xs-12">
                <label for="city">City</label>
                <input type="text" class="form-control" formControlName="city" id="city" placeholder="Enter City"
                  [ngClass]="{'is-invalid': submitted && form.city.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.city.errors?.required">
                  <span>Please enter City</span>
                </div>
              </div>
              <div class="form-group col-md-6 col-xs-12">
                <label for="state">State</label>
                <select class="form-control" formControlName="state" id="state"
                  [ngClass]="{'is-invalid': submitted && form.state.errors}">
                  <option value="" class="option">Select State</option>
                  <option *ngFor="let state of states" class="option" value="{{state.value}}">{{state.name}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="submitted && form.state.errors?.required">
                  <span>Please select State</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 col-xs-12">
                <label for="zip">Zip Code</label>
                <input type="text" class="form-control" formControlName="zip" id="zip" placeholder="Enter Zip Code"
                  [ngClass]="{'is-invalid': submitted && form.zip.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.zip.errors?.required">
                  <span>Please enter Zip Code</span>
                </div>
              </div>
              <div *ngIf="!isRegCodeAvailable" class="form-group col-md-6 col-xs-12">
                <label for="registrationCode">
                  Invitation Code from your Agent’s Text
                  <span class="tooltip-container">
                    <img src="../../../assets/images/question-circle.svg" alt="help" />
                    <span class="tooltiptext">e.g. 123221</span>
                  </span>
                </label>
                <input type="text" class="form-control" formControlName="registrationCode" id="registrationCode"
                  placeholder="Enter 6 digit Invitation Code (Optional)"
                  [ngClass]="{'is-invalid': submitted && form.registrationCode.errors}">
                <div class="invalid-feedback" *ngIf="submitted && form.registrationCode.errors?.required">
                  <span>Please enter 6 digit invitaion code</span>
                </div>
				<div class="invalid-feedback" *ngIf="displayRegCodeError">
					<p>{{errorRegCodeMessage }}</p>
				</div>
              </div>
            </div>
            <div class="col-md-12 col-xs-12 font16px mbrshipSection" *ngIf="membershipProfessionalPlans.length !== 0">
              <h3 class="select-planhead">BUSINESS PROFESSIONALS<br/>SELECT YOUR PLAN</h3>
              <div class="row col-md-12 col-xs-12 feeDetails">
                <div class="plan-area" *ngFor="let plan of membershipProfessionalPlans ; let i = index" [ngStyle]="{
                  'border': plan.selected === true ? '1px solid #F75A72' : ''
                }">
                  <div class="rdio rdio-primary radio-inline">
                    <input [id]="'radio-' + i" type="radio" name="membershipPlan" [value]="plan.code"
                      [ngClass]="{'is-invalid': submitted && form.membershipPlan.errors}"
                      (change)="radioChecked(plan, i)" [checked]="plan.selected">
                    <label [for]="'radio-' + i" [innerHTML]="plan.description">
                    </label>
                    <div class="rightside-area" *ngIf="plan.price !== 'Free'">
                      <p>{{ plan.price }}
                        <sub>/{{ plan.frequency }}</sub>
                      </p>
                    </div>
                    <div class="rightside-area" *ngIf="plan.price === 'Free'">
                      <p>{{ plan.price }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="invalid-feedback" *ngIf="submitted && form.membershipPlan.errors?.required">
                  <span>Please select a plan</span>
                </div>
                <div>
                  <!-- <p class="cancel">*Cancel Anytime</p> -->
                  <p class="corporate-pricing">
                    <span class="">*Corporate Pricing available</span>,
                    Please contact our Customer Service at
                    <a href="tel:8663664446">1-866-366-4446</a>
                  </p>
                </div>
              </div>
              <!-- <div class="row col-md-12 col-xs-12 d-none d-sm-block" *ngFor="let benefit of mbrshipBenefits">
                <div style="display: flex;">
                  <img src="../../../assets/images/checkmark.svg" alt="pointer" class="checkMark">
                  <p>{{benefit}}</p>
                </div>
              </div> -->
            </div>
            <div>
              <div id="dropin-container"></div>
            </div>
            <div class="form-group flexDspl agree-termarea">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" formControlName="agreeTandC" id="agreeTandCCheck">
                <label class="custom-control-label" for="agreeTandCCheck">
                  I Agree to all the
                  <span class="tCtext" (click)="openTerms($event, 'TC')">Terms and Conditions</span>
                  and
                  <span class="tCtext" (click)="openTerms($event, 'PP')">Privacy Policy</span>
                </label>
              </div>
            </div>
            <span class="invalid-feedback" *ngIf="submitted && form.agreeTandC.errors">Please accept the Terms</span>
            <div class="flexDspl row">
              <div class="col-md-6 col-xs-12 ipadfullwidth">
                <button type="submit" class="btn submitButtonnew" (click)="onSubmit()">Create Account</button>
                <p class="already-account">Already have an account?<a (click)="login()"> Sign In</a></p>
              </div>
              <div class="col-md-6 col-xs-12 ipadfullwidth">
                <span class="formErrMsg" *ngIf="submitted && signUpForm.invalid">*Enter All the Fields</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<button id="openPaySuccessBtn" [hidden]="true" data-toggle="modal" data-target="#paymentSuccess">PaySuccess</button>
<div class="modal fade" id="paymentSuccess" tabindex="-1" role="dialog" aria-labelledby="Payment Success"
  aria-hidden="true" #paymentSuccess>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content confirmationModal">
      <div class="textCenter">
        <img src="../../../assets/images/pay_success.svg" alt="payment success" class="cnfrmImg">
      </div>
      <div class="martop20px cnfrmMsg">
        <strong>Thank You</strong>
      </div>
      <div class="martop20px">Payment Successful</div>
    </div>
  </div>
</div>
<button id="openPayFailedBtn" [hidden]="true" data-toggle="modal" data-target="#paymentFailed">PayFailed</button>
<div class="modal fade" id="paymentFailed" tabindex="-1" role="dialog" aria-labelledby="Payment Failed"
  aria-hidden="true" #paymentFailed>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content confirmationModal">
      <div class="textCenter">
        <img src="../../../assets/images/pay_failed.svg" alt="payment failed" class="cnfrmImg">
      </div>
      <div class="martop20px cnfrmMsg" style="text-align: center;">
        <strong>{{errorMsg}}</strong>
      </div>
    </div>
  </div>
</div>
<button id="openTermsConditionsBtn" [hidden]="true" data-toggle="modal" data-target="#termsConditions">T&C</button>
<div class="modal fade" id="termsConditions" tabindex="-1" role="dialog" aria-labelledby="Terms and Conditions"
  aria-hidden="true" #termsConditions>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header col-xs-12 col-md-12 modalTitle">
        <div class="col-md-4 col-xs-4 padnull">
          <img src="" alt="Logo" id="t&CLogo" class="tcLogo">
        </div>
        <span class="col-md-4 col-xs-4 textCenter">{{tcHeader}}</span>
        <div class="col-md-4 col-xs-4" style="text-align: end;">
          <img src="../../../assets/images/cross.svg" data-toggle="modal" data-target="#termsConditions">
        </div>
      </div>
      <div class="modal-body">
        <iframe [src]="tcURL" class="tcIFrame"></iframe>
      </div>
    </div>
  </div>
</div>
<button id="openSignupSuccessBtn" [hidden]="true" data-toggle="modal" data-target="#signupSuccess">SignupSuccess</button>
<div class="modal fade" id="signupSuccess" tabindex="-1" role="dialog" aria-labelledby="Signup Success"
  aria-hidden="true" data-backdrop="static" data-keyboard="false" #signupSuccess>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content confirmationModal">
      <div class="textCenter">
        <img src="../../../assets/images/pay_success.svg" alt="signup success" class="cnfrmImg">
      </div>
      <div class="martop20px cnfrmMsg">
        <strong>Success</strong>
      </div>
      <div class="martop20px text-center">Great job, thank you for signing up.<br/>Click <a href="/signup-success">here</a> to login.</div>
    </div>
  </div>
</div>
<app-footer></app-footer>
