import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { Papa } from 'ngx-papaparse';
import * as moment from 'moment';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-realtors-membership-details',
  templateUrl: './realtors-membership-details.component.html',
  styleUrls: ['./realtors-membership-details.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RealtorsMembershipDetailsComponent implements OnInit {

  xClientId: string;
  token: string = '';
  userProfile: any;
  paymentHistoryDetails: any;
  cols: any[];
  exportColumns: any[];
  displayErrorPopup: boolean = false;
  cancelButtonStatus: boolean = true;
  activeMembership:any="unset";

  constructor(
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private realtorService: RealtorService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private papa: Papa,
    private route: ActivatedRoute
    ) {
    this.token = sessionStorage.getItem('msal.idtoken');
    this.xClientId = this.environmentService.getXClientId();
    const paramToken = this.route.snapshot.queryParamMap.get("token");
    if (paramToken !== null) {
      this.token = paramToken;
      // this.tokenService.token = paramToken;
      sessionStorage.setItem('msal.idtoken', this.token);
    }
  }

  ngOnInit(): void {
    this.getUserDetails();
    this.cols = [
      { field: "date", header: "Date" },
      { field: "amount", header: "Amount" },
      { field: "status", header: "Status" }
    ];
    this.exportColumns = this.cols.map(col => ({
      title: col.header,
      dataKey: col.field
    }));
  }

  getUserDetails() {
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.spinner.show();
    this.professionalService.getUserInfo(url, this.token).subscribe(
      response => {
        this.userProfile = response;
        if (this.userProfile.MembershipPlans !== null) {
          this.paymentHistory();
        } else {
          this.displayErrorPopup = true;
        }
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      });
  }

  cancelSubscription(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let data = {
          "email": this.userProfile.Email,
          "agentid": this.userProfile.MembershipPlans[0].AssociatedId
        }
        const url = this.environmentService.getIA9LightningUrl() + 'payment/cancel?code=' + this.environmentService.getIA9LightningKey();
        this.spinner.show();
        this.realtorService.cancelSubscriptions(url, data, this.xClientId, this.token).subscribe((response) => {
          this.spinner.hide();
          if (response['isSuccess'] === true) {
            this.messageService.add({ severity: 'info', summary: 'Canceled', detail: 'MemberShip Canceled' });
            // this.router.navigate(['/membership/realtors']);
            location.reload();
          }
        },
          (error) => {
            this.displayErrorPopup = true;
            this.spinner.hide();
          });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }

  paymentHistory() {
    const url = this.environmentService.getIA9LightningUrl() + 'payment/history?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.realtorService.getPaymentHistory(url, this.xClientId, this.token).subscribe((response) => {
      this.paymentHistoryDetails = response.data;
      for (let index = 0; index < this.paymentHistoryDetails.length; index++) {
        if (this.paymentHistoryDetails[index].status !== "Expired") {
          this.cancelButtonStatus = true;
          break;
        } else {
          this.cancelButtonStatus = false;
        }
      }
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
      })
  }

  renewMembershipPlan() {
    this.userProfile.MembershipPlans.forEach(element => {
      if(element.IsActive){
        this.activeMembership="set";
        return;
      }
    });
    this.router.navigate(['/renew-plan-realtors'], { queryParams: { email: this.userProfile.Email, agentid: this.userProfile.MembershipPlans[0].AssociatedId, activeMembership: this.activeMembership } });
  }

  export() {
    const csvColumns = [
      'Date',
      'Amount',
      'Status',
      'Description'
    ];
    const csvRows: string[][] = [];
    let row: string[] = [];
    this.paymentHistoryDetails.forEach((x) => {
      row = [];
      row.push(moment(x.date).format('YYYY-MM-DD'));
      row.push(x.amount);
      row.push(x.status);
      row.push(x.description);
      csvRows.push(row);
    });
    const reportCsv = this.papa.unparse({ fields: csvColumns, data: csvRows });
    const blob = new Blob([reportCsv], { type: 'text/csv; charset=utf-8' });
    FileSaver.saveAs(blob, 'Transaction' + '.csv');
  }

  backToHome() {
    this.displayErrorPopup = false;
    this.router.navigate(['/dashboard']);
  }

}
