<div class="container-fluid Business-professionalcontainer">
    <div class="row">
        <div class="col-md-5  col-xl-6 col-xs-12 padtop3 textCenter marbot110px ipadfullwidth">
            <div class="logo-section">
                <img class="signUpImg  logo-img" src="../../../assets/images/logo.svg" alt="signup page image">
            </div>
            <h2 class="wlcmMsg">Welcome to Domii!</h2>
            <div class="gps-leadsdiv">
                <div class="list-views">
                    <h2>As a Home <strong><span style="color: #f75a72;">Buyer</span></strong>, <strong><span
                                style="color: #f75a72;">Seller</span></strong>, or General <strong><span
                                style="color: #f75a72;">User</span></strong>, you can…
                    </h2>
                    <ul [class.moredisplay]="showBenefits">
                        <li>Search for homes, find open houses near you, save your favorite properties, and request
                            showings from nearby realtors</li>
                        <li>Share properties with other users or your realtor of choice</li>
                        <li>Request over 30 types of professionals in your area to help you with anything from
                            housekeeping to building a home – without the lengthy request forms.&nbsp; For more
                            information on professionals visit our <span style="color: #f75a72;"><strong><a
                                        href="https://domii.io/find-a-pro/" style="color: #f75a72;">Find a
                                        Pro</a></strong></span> page
                        </li>
                        <li>Find the value of your home, request a free credit report, and request pre-approval</li>
                        <li>Exchange and sign documents from your realtor of choice, and submit an offer for a home in
                            under 5 minutes</li>
                    </ul>
                    <p><strong>Check out our other features through the app:</strong></p>
                    <ul>
                        <li style="list-style-type: none;">
                            <ul>
                                <li style="list-style-type: none;">
                                    <ul>
                                        <li style="list-style-type: none;">
                                            <ul>
                                                <li>Mortgage calculator</li>
                                                <li>Lot lines</li>
                                                <li>Home inspection notebook</li>
                                                <li>Touring notebook</li>
                                                <li>Closing documents</li>
                                                <li>FAQs</li>
                                                <li>Favorite, filter, and save properties or searches</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <p class="bottom-text">Download Domii at the Apple or Play store today and realize the benefits
                    consumers
                    are
                    raving about. You got
                    it, we created an app not just for real estate agents and businesses, but for buyers and sellers as
                    well,
                    welcome to Domii, innovation at its best!</p>
                <p class="tap-respond">Get connected today!</p>
                <div class="bottom-image">
                    <img src="../../../assets/images/download-apple-store.png" class="mr-3" (click)="openAppStore()">
                    <img src="../../../assets/images/download-google-play.png" (click)="openPlayStore()">
                </div>
            </div>
        </div>
        <div class="col-md-7 col-xl-6 col-xs-12 backgroundWhite marbot110px ipadfullwidth padtop3">
            <div class="card noBorder">
                <div class="card-body">
                    <!-- <div class="card-header-div">
                        <h4 class="card-title">Consumer Sign Up</h4>
                        <p class="already-account">Already have an account, <a (click)="login()">Sign In</a></p>
                    </div> -->
                    <div style="text-align:center">
                        <div class="col-xs-12">
                            <h4 class="card-title">Home Buyer/Seller Sign Up</h4>
                        </div>
                        <!-- <div class="form-group col-md-6 col-xs-12">
                            <p class="already-account">Already have an account, <a (click)="login()">Sign In</a></p>
                        </div> -->
                    </div>
                    <form [formGroup]="consumerSignUpForm">
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="firstName">First Name</label>
                                <input type="text" class="form-control" formControlName="firstName" id="firstName"
                                    placeholder="Enter First Name"
                                    [ngClass]="{'is-invalid': submitted && form.firstName.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.firstName.errors?.required">
                                    <span>Please enter First Name</span>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="lastName">Last Name</label>
                                <input type="text" class="form-control" formControlName="lastName" id="lastName"
                                    placeholder="Enter Last Name"
                                    [ngClass]="{'is-invalid': submitted && form.lastName.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.lastName.errors?.required">
                                    <span>Please enter Last Name</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="phone">Mobile Number</label>
                                <input type="phone" class="form-control" formControlName="phone" id="phone"
                                    placeholder="Enter Mobile Number"
                                    [ngClass]="{'is-invalid': (submitted || form.phone.dirty) && form.phone.errors}"
                                    maxlength="12">
                                <div class="invalid-feedback"
                                    *ngIf="(submitted || form.phone.dirty) && form.phone.errors">
                                    <span *ngIf="form.phone.errors.required">Please enter Mobile Number</span>
                                    <span *ngIf="form.phone.errors.pattern">Please enter Valid Mobile
                                        Number</span>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="email">Email Address</label>
                                <input type="email" class="form-control" formControlName="email" id="email"
                                    placeholder="Enter Email Address"
                                    [ngClass]="{'is-invalid': (submitted || form.email.dirty) && form.email.errors}"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                                    (change)="validateEmail($event)">
                                <div class="invalid-feedback"
                                    *ngIf="(submitted || form.email.dirty) && form.email.errors">
                                    <span *ngIf="form.email.errors.required">Please enter Email</span>
                                    <span *ngIf="form.email.errors.pattern">Please enter Valid Email</span>
                                </div>
                                <div class="invalid-feedback" *ngIf="displayEmailError">
                                    <p>{{errorMessage }}<span style="text-decoration: underline;
                                    color: black;cursor: pointer;" (click)="redirectToLogin()"> Sign In</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="password">Password</label>
                                <input type="password" class="form-control" formControlName="password" id="password"
                                    placeholder="********"
                                    [ngClass]="{'is-invalid': submitted && form.password.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.password.errors">
                                    <span *ngIf="form.password.errors.required">Please enter Password</span>
                                    <span *ngIf="form.password.errors.minlength">Password must be atleast 8
                                        characters</span>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="confirmPassword">Confirm Password</label>
                                <input type="password" class="form-control" formControlName="confirmPassword"
                                    id="confirmPassword" placeholder="********"
                                    [ngClass]="{'is-invalid': submitted && form.confirmPassword.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.confirmPassword.errors">
                                    <span *ngIf="form.confirmPassword.errors.required">Please enter Confirm
                                        Password</span>
                                    <span *ngIf="form.confirmPassword.errors.mustMatch">Password Not Matching</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="registrationCode">Invitation Code (Optional)</label>
                                <P style="color: #636363; margin: 0 0px 8px; line-height: 16px;">
                                    To connect with your agent, enter the invitation code that was sent to you in your
                                    text
                                </P>
                                <input type="text" class="form-control" formControlName="registrationCode"
                                    id="registrationCode" placeholder="Enter 6 digit Invitation Code (Optional)"
                                    [ngClass]="{'is-invalid': submitted && form.registrationCode.errors}">
                            </div>
                        </div>
                        <div class="flexDspl  agree-termarea">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" formControlName="agreeTandC"
                                    id="agreeTandCCheck">
                                <label class="custom-control-label" for="agreeTandCCheck">
                                    I Agree to all the
                                    <span class="tCtext" (click)="openTerms($event, 'TC')">Terms and
                                        Conditions</span>
                                    and
                                    <span class="tCtext" (click)="openTerms($event, 'PP')">Privacy Policy</span>
                                </label>
                            </div>
                        </div>
                        <span class="invalid-feedback" *ngIf="submitted && form.agreeTandC.errors">*Please accept the
                            Terms</span>
                        <div class="flexDspl row">
                            <div class="col-md-6 col-xs-12 ipadfullwidth">
                                <button type="submit" class="btn submitButtonnew" (click)="onSubmit()">Create
                                    Account
                                </button>
                                <p class="already-account">Already have an account? <a (click)="login()">Sign In</a></p>
                            </div>
                            <div class="col-md-6 col-xs-12 ipadfullwidth">
                                <span class="formErrMsg" *ngIf="submitted && consumerSignUpForm.invalid">*Enter All
                                    the
                                    Fields</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<button id="openRegSuccessBtn" [hidden]="true" data-toggle="modal" data-target="#regSuccess">RegSuccess</button>
<div class="modal fade" id="regSuccess" tabindex="-1" role="dialog" aria-labelledby="Registration Success"
    aria-hidden="true" #regSuccess>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content confirmationModal">
            <div class="textCenter">
                <img src="../../../assets/images/pay_success.svg" alt="registration success" class="cnfrmImg">
            </div>
            <div class="martop20px cnfrmMsg">
                <strong>Thank You</strong>
            </div>
            <div class="martop20px">Sign Up Successful!</div>
        </div>
    </div>
</div>
<button id="openRegFailedBtn" [hidden]="true" data-toggle="modal" data-target="#regFailed">RegFailed</button>
<div class="modal fade" id="regFailed" tabindex="-1" role="dialog" aria-labelledby="Registration Failed"
    aria-hidden="true" #regFailed>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content confirmationModal">
            <div class="textCenter">
                <img src="../../../assets/images/pay_failed.svg" alt="registration failed" class="cnfrmImg">
            </div>
            <div class="martop20px cnfrmMsg">
                <strong>Error Signing Up!</strong>
            </div>
        </div>
    </div>
</div>
<button id="openTermsConditionsBtn" [hidden]="true" data-toggle="modal" data-target="#termsConditions">T&C</button>
<div class="modal fade" id="termsConditions" tabindex="-1" role="dialog" aria-labelledby="Terms and Conditions"
    aria-hidden="true" #termsConditions>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header col-xs-12 col-md-12 modalTitle">
                <div class="col-md-4 col-xs-4 padnull">
                    <img src="" alt="Logo" id="t&CLogo" class="tcLogo">
                </div>
                <span class="col-md-4 col-xs-4 textCenter">{{tcHeader}}</span>
                <div class="col-md-4 col-xs-4" style="text-align: end;">
                    <img src="../../../assets/images/cross.svg" data-toggle="modal" data-target="#termsConditions">
                </div>
            </div>
            <div class="modal-body">
                <iframe [src]="tcURL" class="tcIFrame"></iframe>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>