<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<div class="DashmainContainer" *ngIf="marketmiiDetails">
    <div class="col-md-12 col-xs-12 font16px mbrshipSection">
        <img src="../../../assets/images/h.png" alt="">
        <h1>MARKETMii</h1><br>
        <h2>
            You’re on your way!
        </h2>
        <h3>
            Please fill out the required information below to continue.
        </h3>
    </div>
    <br>
    <div class="col-md-12 col-xs-12 ordered-address">
        <h1 class="address-title">
            You ordered the following postcards:
        </h1>
        <br>
        <section>
            <div class="row col-md-12 col-xs-12">
                <div class="col-md-6 col-xs-12" *ngFor="let postcard of marketmiiDetails.postcards">
                    <div class="col-md-12 col-xs-12" class="post-card">
                        <img [src]="postcard">
                    </div>
                </div>
            </div>
        </section>
    </div>
    <br>
    <div class="col-md-12 col-xs-12 ordered-address">
        <h1 class="address-title">
            Thank for your order <span style="font-weight: 600;">{{ realtorFN }}</span>, Your order will be delivered to
            these addresses:
        </h1>
        <br>
        <section>
            <div *ngFor="let address of marketmiiDetails.addresses">
                <img src="../../../assets/images/ic-sessions.svg" width="30px">
                <p>{{ address }}</p>
            </div>
        </section>
    </div>
    <br>
    <div class="col-md-12 col-xs-12 total-amount-card">
        <h1 class="ordered-title">
            Your total amount for this order:
        </h1>
        <h1 class="ordered-amount">
            ${{ marketmiiDetails.totalAmount }}
        </h1>
    </div>
    <br><br>
    <div class="payment-details">
        <h1 class="payment-title">
            Enter your payment details below:
        </h1>
        <div>
            <div id="dropin-container"></div>
        </div>
    </div>
    <div class="flexDspl row">
        <div class="col-md-6 col-xs-12 ipadfullwidth" style="margin-bottom: 200px;">
            <button type="submit" class="btn submitButtonnew" (click)="onSubmit($event)">
                Submit
            </button>
        </div>
    </div>
</div>

<!-- Error PopUp -->
<section>
    <p-dialog header="" [modal]="true" [(visible)]="displayErrorPopup" [style]="{width: '575px', padding: '10px'}"
        [baseZIndex]="10000" position="center" [draggable]="false" [resizable]="false">
        <div class="ErrorContainer">
            <div class="container">
                <div class="col-lg-12">
                    <section>
                        <p>Something went wrong. Please try again later or contact customer service,
                            <a href="tel:1-866-366-4446">1-866-366-4446</a>,
                            <a href="support@ia9inc.com">support@ia9inc.com</a>.
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="displayErrorPopup=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>