<app-header></app-header>
<app-spinner *ngIf="loading"></app-spinner>
<div class="back_btn">
    <a routerLink="/dashboard">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="mainContainer">
    <app-plan-ending-alert *ngIf="isMobile"></app-plan-ending-alert>
    <!-- Uncomment the following code when the API is integrated to update plan-->

    <!-- <div class="card noBorder footerMargin">
        <div class="card-body">
            <div class="card-title col-md-12 col-xs-12">Pricing Plans</div>
            <div class="card">
                <div class="card-body" [ngClass]="selectedPlan == domii_basic_code ? '' : 'greyBg'">
                    <div class="row card-title">
                        <div>
                            <div>Domii Basic</div>
                            <div>FREE</div>
                        </div>
                        <div>
                            <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" name="selectedPlan" id="domiiBasicPlan" [value]="domii_basic_code"
                                    [(ngModel)]="selectedPlan" #domiiBasicPlan="ngModel">
                                <label class="custom-control-label" for="domiiBasicPlan"></label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isMobile">
                        <span class="viewMoreTxt" (click)="showBasicFeatures = !showBasicFeatures">View More</span>
                    </div>
                    <div *ngIf="showBasicFeatures">
                        <div class="row col-md-12 col-xs-12 martop10px" [ngClass]="isMobile ? 'padnull' : ''">
                            <div *ngFor="let feature of domii_basic_features" class="col-md-4 col-xs-12">
                                <div style="display: flex;">
                                    <img src="../../../assets/images/checkmark.svg" alt="pointer" class="checkMark">
                                    <p>{{feature}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plans-grid">
                <div class="card">
                    <div class="card-body" [ngClass]="selectedPlan == domii_pro_code ? '' : 'greyBg'">
                        <div class="row card-title">
                            <div>
                                <div>Domii Pro</div>
                                <div>$ 19.99 /Month</div>
                            </div>
                            <div>
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" name="selectedPlan" id="domiiProPlan" [value]="domii_pro_code"
                                        [(ngModel)]="selectedPlan" #domiiProPlan="ngModel">
                                    <label class="custom-control-label" for="domiiProPlan"></label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isMobile">
                            <span class="viewMoreTxt" (click)="showProFeatures = !showProFeatures">View More</span>
                        </div>
                        <div *ngIf="showProFeatures">
                            <div class="col-md-12 col-xs-12 includesTxt" [ngClass]="isMobile ? 'martop10px padnull' : ''">
                                * Includes Basic +
                            </div>
                            <div class="row col-md-12 col-xs-12 martop10px" [ngClass]="isMobile ? 'padnull' : ''">
                                <div *ngFor="let feature of domii_pro_features" class="col-md-6 col-xs-12">
                                    <div style="display: flex;">
                                        <img src="../../../assets/images/checkmark.svg" alt="pointer" class="checkMark">
                                        <p>{{feature}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body" [ngClass]="selectedPlan == domii_adv_code ? '' : 'greyBg'">
                        <div class="row card-title">
                            <div>
                                <div>Domii Advantage</div>
                                <div>$ 39.00 /Month</div>
                            </div>
                            <div>
                                <div class="custom-control custom-radio">
                                    <input class="custom-control-input" type="radio" name="selectedPlan" id="domiiAdvPlan" [value]="domii_adv_code"
                                        [(ngModel)]="selectedPlan" #domiiAdvPlan="ngModel">
                                    <label class="custom-control-label" for="domiiAdvPlan"></label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isMobile">
                            <span class="viewMoreTxt" (click)="showAdvFeatures = !showAdvFeatures">View More</span>
                        </div>
                        <div *ngIf="showAdvFeatures">
                            <div class="col-md-12 col-xs-12 includesTxt" [ngClass]="isMobile ? 'martop10px padnull' : ''">
                                * Includes Basic and Pro +
                            </div>
                            <div class="row col-md-12 col-xs-12 martop10px" [ngClass]="isMobile ? 'padnull' : ''">
                                <div *ngFor="let feature of domii_adv_features" class="col-md-4 col-xs-12">
                                    <div style="display: flex;">
                                        <img src="../../../assets/images/checkmark.svg" alt="pointer" class="checkMark">
                                        <p>{{feature}}</p>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12 leadsDesc">{{gps_leads_desc}}</div>
                                <div class="col-md-12 col-xs-12 buyerNote">{{buyer_note}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div id="dropin-container"></div>
            </div>
            <div style="margin: 20px 0;">
                <div class="col-md-4 col-xs-12 padnull">
                    <button type="submit" class="btn submitButton" (click)="onSubmit()">Pay Now</button>
                </div>
            </div>
        </div>
    </div> -->

    <!-- remove the following code when the API is integrated to update plan -->
    <div class="cardContainer">
        <div class="card noBorder footerMargin">
            <div class="card-body textCenter">
                <img src="../../../assets/images/server_down.svg" alt="server error">
                <div class="title">Coming Soon!!</div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>