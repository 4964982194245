export class UserProfileModel{
    id: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    emailId: string;
    companyName: string;
    companyUrl: string;
    companyPhoto: string;
    mlsAffiliation: string;
    preferredTms: string;
    locationOption: number;
    licenseNumber: string;
    licenses: any[];
    paymentToken: string;
    planId: string;
    type: number;
    address1: string;
    address2:string;
    city: string;
    state: string;
    zip: string;
    photo: string;
}