import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HubspotService } from 'src/app/services/hubspot/hubspot.service';
import { ProfessionalService } from '../../services/professional/professional.service';

@Component({
  selector: 'app-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss']
})
export class CancelPlanComponent implements OnInit {

  firstName: string;
  lastName: string;
  mobile: string;
  emailID: string;
  reasonToCancel: string;
  submitted: boolean;

  toastVisible = false;
  toastMessage = "";
  toastType = "";

  @ViewChild('cancelPlanForm') cancelPlanForm: NgForm;

  constructor(private professionalService: ProfessionalService,
    private hubspotService: HubspotService) { }

  ngOnInit(): void {
    this.professionalService.getCancelPlan().subscribe(value => {
      if(value?.open){
        this.firstName = value?.profile?.FirstName;
        this.lastName = value?.profile?.LastName;
        this.mobile = value?.profile?.Phone;
        this.emailID = value?.profile?.Email;
        this.reasonToCancel = '';
        this.submitted = false;
        document.getElementById('openCancelPlan').click();
      }
    });
  }

  onSubmit(){
    this.submitted = true;
    if (this.cancelPlanForm.invalid){
      return;
    }
    this.hubspotService.submitCancellationRequest(this.firstName, this.lastName, this.emailID, this.mobile, this.reasonToCancel).subscribe(response => {
      document.getElementById('openCancelPlan').click();
      this.toastVisible = true;
      this.toastMessage = "Success!! Request Sent";
      this.toastType = "success";
    }, error => {
      this.toastVisible = true;
      this.toastMessage = " Something went wrong, please try again!!";
      this.toastType = "error";
    })
  }

  closeToast(){
    this.toastVisible = false;
  }
}
