// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule } from '@azure/msal-angular';

import { DatePipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';

// Components
import { AppComponent } from './app.component';
import { ConsumerSignupComponent } from './components/consumer-signup/consumer-signup.component';
import { ProfessionalInfoComponent } from './components/professional-info/professional-info.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OpenHouseComponent } from './components/open-house/open-house.component';
import { IntanceAccessComponent } from './components/intance-access/intance-access.component';
import { ListingsComponent } from './components/listings/listings.component';
import { OpenHouseSigninSummaryComponent } from './components/open-house-signin-summary/open-house-signin-summary.component';
import { NewLandingComponent } from './components/new-landing/new-landing.component';
import { NoConsumerLoginComponent } from './components/no-consumer-login/no-consumer-login.component';
import { MobileOpenHouseComponent } from './components/mobile-open-house/mobile-open-house.component';
import { ListingsShowingRealtorsComponent } from './components/listings-showing-realtors/listings-showing-realtors.component';
import { LeadsComponent } from './components/leads/leads.component';
import { RealtorMembershipUpgradeComponent } from './components/realtor-membership-upgrade/realtor-membership-upgrade.component';
import { RealtorsMembershipDetailsComponent } from './components/realtors-membership-details/realtors-membership-details.component';
import { ProfessionalMembershipDetailsComponent } from './components/professional-membership-details/professional-membership-details.component';
import { RenewMembershipPlanComponent } from './components/renew-membership-plan/renew-membership-plan.component';
import { RenewMembershipPlanProfessionalComponent } from './components/renew-membership-plan-professional/renew-membership-plan-professional.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { SearchvaluemiiComponent } from './components/searchvaluemii/searchvaluemii.component';
import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MembershipComponent } from './components/membership/membership.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ToastsComponent } from './components/toasts/toasts.component';
// import { RealtorSignupComponent } from './components/realtor-signup/realtor-signup.component';
import { NewRealtorSignupComponent } from './components/new-signup/new-signup.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PricingPlansComponent } from './components/pricing-plans/pricing-plans.component';
import { FooterComponent } from './components/footer/footer.component';
import { PlanEndingAlertComponent } from './components/plan-ending-alert/plan-ending-alert.component';
import { CancelPlanComponent } from './components/cancel-plan/cancel-plan.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { RealtorProfessionalInviteComponent } from './components/realtor-professional-invite/realtor-professional-invite.component';
import { RealtorTestimonialComponent } from './components/realtor-testimonial/realtor-testimonial.component';
import { RealtorTemplatesComponent } from './components/realtor-templates/realtor-templates.component';
import { OnboardComponent } from './components/onboard/onboard.component';
import { PostcardPaymentComponent } from './components/marketMii/postcard-payment/postcard-payment.component';
import { UpgradePaymentComponent } from './components/upgrade-payment/upgrade-payment.component';
import { AddZipCodesComponent } from './components/add-zip-codes/add-zip-codes.component';
import { CertificateHomeValuationComponent } from './components/certificate-home-valuation/certificate-home-valuation.component';

// Ngx Modules
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { NGXModule } from './ngx.module';

// Pipe
import { ReplacePipe } from './helpers/replace-pipe';

// PrimeNG Module
import { PrimeNGModule } from './primeNG.module';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LoginComponent,
    PageNotFoundComponent,
    HeaderComponent,
    ProfileComponent,
    SidebarComponent,
    LogoutComponent,
    MembershipComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ToastsComponent,
    // RealtorSignupComponent,
    NewRealtorSignupComponent,
    SpinnerComponent,
    PricingPlansComponent,
    FooterComponent,
    PlanEndingAlertComponent,
    CancelPlanComponent,
    LandingPageComponent,
    ConsumerSignupComponent,
    ProfessionalInfoComponent,
    DashboardComponent,
    OpenHouseComponent,
    IntanceAccessComponent,
    ListingsComponent,
    OpenHouseSigninSummaryComponent,
    NewLandingComponent,
    NoConsumerLoginComponent,
    MobileOpenHouseComponent,
    RealtorsMembershipDetailsComponent,
    ProfessionalMembershipDetailsComponent,
    RenewMembershipPlanComponent,
    RenewMembershipPlanProfessionalComponent,
    TestimonialComponent,
    SearchvaluemiiComponent,
    LeadsComponent,
    ListingsShowingRealtorsComponent,
    RealtorMembershipUpgradeComponent,
    ReplacePipe,
    RealtorProfessionalInviteComponent,
    RealtorTestimonialComponent,
    RealtorTemplatesComponent,
    OnboardComponent,
    PostcardPaymentComponent,
    UpgradePaymentComponent,
    AddZipCodesComponent,
    CertificateHomeValuationComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNGModule,
    NGXModule,
    ToastrModule.forRoot({ timeOut: 3000, enableHtml: true }),
    NgxMaskModule.forRoot(),
    MsalModule.forRoot(
      {
        auth: {
          clientId: '8870b782-a80e-47b1-9b0d-77576cdc2751',
          // Domii Production - https://account.domii.io
          // Cocoearly Production - https://account.cocoearly.com
          // Domii Staging - https://account-staging.cocoearly.com
          // Cocoearly Staging - https://account-staging.cocoearly.com
          // Development - http://localhost:4200
           //redirectUri: 'https://account-staging.domii.io',
           //postLogoutRedirectUri: 'https://account-staging.domii.io',
          // redirectUri: 'https://account.domii.io',
          // postLogoutRedirectUri: "https://account.domii.io",
          redirectUri: (/^.*localhost.*/.test(window && window.location && window.location.hostname)) === true ? 'http://localhost:4200' : environment.RedirectUrl,
          postLogoutRedirectUri: (/^.*localhost.*/.test(window && window.location && window.location.hostname)) === true ? 'http://localhost:4200' : environment.RedirectUrl,
          validateAuthority: false,
          navigateToLoginRequestUrl: true,
        },
        framework: {
          isAngular: true,
        },
        cache: {
          cacheLocation: 'sessionStorage',
        },
      },
      {
        consentScopes: [
          'https://ia9b2c.onmicrosoft.com/backend/user_impersonation',
        ],
      }
    ),
  ],
  providers: [DatePipe, CurrencyPipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
