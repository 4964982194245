<nav class="navbar navbar-dark bg-dark header">
    <div class="navbar-brand paddingStart">
        <img src="../../../assets/images/domii-logo.svg" alt="Brand" class="brandImg">
    </div>
    <ul class="header_menu"
        *ngIf="router.url === '/dashboard' || router.url === '/listings' || router.url.split('/')[1].split('?')[0] === 'dashboard'">
        <li>
            <div class="dropdown">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
                    <img style="height: 40px;" src="../../../assets/images/prof_landing.svg" alt="">
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" *ngFor="let m of menuList" (click)="loadMenu(m)">
                        <img class="menuImg" src="{{m.img}}">
                        <span>{{m.menu}}</span>
                    </a>
                </div>
            </div>
        </li>
    </ul>
    <div class="navbar-toggler content-mobile" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
        *ngIf="router.url === '/dashboard' || router.url === '/listings' || router.url.split('/')[1].split('?')[0] === 'dashboard'">
        <img src="../../../assets/images/menu-toggler.svg">
    </div>
</nav>
<div class="content-desktop"
    *ngIf="router.url === '/dashboard' || router.url === '/listings' || router.url.split('/')[1].split('?')[0] === 'dashboard'">
    <div class="desktopMenu">
        <app-sidebar></app-sidebar>
    </div>
</div>
<div class="content-mobile"
    *ngIf="router.url === '/dashboard' || router.url === '/listings' || router.url.split('/')[1].split('?')[0] === 'dashboard'">
    <div class="mobileMenu collapse" id="navbarNav">
        <div class="toggler">
            <img src="../../../assets/images/cross.svg" data-toggle="collapse" data-target="#navbarNav">
        </div>
        <app-sidebar></app-sidebar>
        <div id="greyOutContent"></div>
    </div>
</div>