<div *ngIf="visible" id="toggle-toast">
    <div class="toast-container">
        <div *ngIf="toastType == 'success'" class="successToast">
            <div>
                <img src="../../../assets/images/success-check-circle.svg" alt="success-check-circle">
            </div>
            <div class="toastMsg">
                {{content}}
            </div>
        </div>
        <div *ngIf="toastType == 'error'" class="errorToast">
            <div>
                <img src="../../../assets/images/error-x-circle.svg" alt="error-x-circle">
            </div>
            <div class="toastMsg">
                {{content}}
            </div>
        </div>
    </div>
</div>