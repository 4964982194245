<app-header></app-header>
<div class="back_btn">
    <a routerLink="/dashboard">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="DashmainContainer">
    <div class="container">
        <div class="col-lg-12">
            <p-orderList [value]="associatedusers" [listStyle]="{'min-height':'10rem'}" header="My Leads">
                <ng-template let-lead pTemplate="item">
                    <div class="product-item">
                        <div class="image-container">
                            <img src="{{lead.Person.picture}}" alt="" class="product-image" />
                        </div>
                        <div class="product-list-detail">
                            <span class="product-name">
                                {{lead.Person.firstName}} {{lead.Person.lastName}}
                            </span>
                            <span class="product-category">
                                <i class="pi pi-phone product-category-icon"></i>
                                <a href="{{'tel:' + lead.Person.phone}}">{{lead.Person.phone}}</a>
                            </span>
                            <span class="product-category">
                                <i class="pi pi-envelope product-category-icon"></i>
                                <a href="{{'mailto:' + lead.Person.email }}">{{lead.Person.email}}</a>
                            </span>
                        </div>
                        <div class="product-list-action" (click)="showDetailDialog(lead)">
                            <i class="pi pi-chevron-right product-category-icon"></i>
                        </div>
                    </div>
                </ng-template>
            </p-orderList>
        </div>
    </div>
</div>
<section>
    <p-dialog header="MY LEADS" [modal]="true" [(visible)]="displayBasic" [style]="{width: '400px', padding: '10px'}"
        [baseZIndex]="10000" position="top" [draggable]="false" [resizable]="false">
        <div class="main-content">
            <div class="lead-details">
                <div class="image-container">
                    <img src="{{associateduserDetails.Person?.picture}}" alt="" class="product-image" />
                </div>
                <div class="lead-details-name">
                    <span>
                        {{associateduserDetails.Person?.firstName}} {{associateduserDetails.Person?.lastName}}
                    </span>
                </div>
            </div>
            <div class="lead-details-contact">
                <span>
                    <a href="{{'tel:' + associateduserDetails.Person?.phone}}">
                        {{associateduserDetails.Person?.phone }}
                    </a>
                    <i class="pi pi-phone product-category-icon"></i>
                </span>
                <span>
                    <a href="{{'mailto:' + associateduserDetails.Person?.email }}">
                        {{associateduserDetails.Person?.email }}
                    </a>
                    <i class="pi pi-envelope product-category-icon"></i>
                </span>
            </div>
            <div class="lead-details-date">
                <span>
                    Connected On: {{associateduserDetails?.CreationDate | date: 'dd/MM/yyyy'}}
                </span>
                <span>
                    Last Request: {{associateduserDetails?.LastUsedDate | date: 'dd/MM/yyyy'}}
                </span>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>
<app-footer></app-footer>