import { Component, isDevMode, OnInit } from '@angular/core';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { Papa } from 'ngx-papaparse';
import { Router } from '@angular/router';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-intance-access',
  templateUrl: './intance-access.component.html',
  styleUrls: ['./intance-access.component.scss']
})
export class IntanceAccessComponent implements OnInit {

  lisitings: any;
  cols: any[];
  exportColumns: any[];
  userProfile: any;
  currentDate: string = '';
  currentTime: string = '';
  token: string = '';
  xClientId: string;
  ListingSummary: any;
  dropDownMenuIt: string = 'all';
  feedbacks: any = [];
  agentsList: any = [];
  displayBasic: boolean = false;
  displayAgents: boolean = false;
  displayShowings: boolean = false;
  displayBookedShowings: boolean = false;
  feedbackDetails: any = [];
  totalBookedShowings: any = [];
  summaries: any = [];
  _listingId: any;
  items: MenuItem[];
  activeItem: MenuItem;
  userObject: any;
  mailBasic: boolean = false;
  mailForm: FormGroup;
  submitted = false;
  defaultText: string = '';
  selected: any[];
  showingAgentName: string = '';
  showingAgentEmail: string = '';
  showingPhoneNumber: string = '';
  showingDateTime: string[];

  constructor(
    private primengConfig: PrimeNGConfig,
    private spinner: NgxSpinnerService,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private realtorService: RealtorService,
    private papa: Papa,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.currentDate = moment().format('L');
    this.currentTime = moment().format('LT');
    this.token = sessionStorage.getItem('msal.idtoken');
    this.mailForm = this.formBuilder.group({
      tos: new FormControl('', [Validators.required]),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', [Validators.required])
    });
  }

  get form() { return this.mailForm.controls; }

  ngOnInit(): void {
    if (isDevMode()) {
      this.xClientId = 'FEE8CC43-AFD8-4DA8-A4E3-6639745C4428';
    } else {
      this.xClientId = 'C606AF7F-9DA0-4360-B878-27DFA8A43238';
    }
    this.primengConfig.ripple = true;
    this.getUserDetails();
    this.getAllListings('all');
  }

  getUserDetails() {
    this.spinner.show();
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.professionalService.getUserInfo(url, this.token).subscribe(
      (response) => {
        this.userProfile = response;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getAllListings(commend) {
    switch (commend) {
      case 'all': {
        this.dropDownMenuIt = 'All';
        break;
      }
      case 'active': {
        this.dropDownMenuIt = 'Active';
        break;
      }
      case 'activeundercontract': {
        this.dropDownMenuIt = 'Active under contract';
        break;
      }
      case 'closed': {
        this.dropDownMenuIt = 'Closed';
        break;
      }
      case 'expired': {
        this.dropDownMenuIt = 'Expired';
        break;
      }
      case 'withdrawn': {
        this.dropDownMenuIt = 'Withdrawn';
        break;
      }
    }
    let params = {
      cmd: commend,
      date: JSON.stringify(new Date()).slice(1, 11),
    };

    let url =
      this.environmentService.getIA9LightningUrl() +
      'dashboard/property/listings/status/' +
      params.cmd +
      '/' +
      params.date +
      '?code=' +
      this.environmentService.getIA9LightningKey();
    this.professionalService
      .getALlListing(url, this.token, this.xClientId)
      .subscribe(
        (response: any) => {
          this.lisitings = response.data;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  getDate(date) {
    if (date) {
      return new Date(date).toISOString().slice(0, 10);
    } else {
      return null;
    }
  }

  downloadListingAsPDF() {
    const csvColumns = [
      'LISTING ID',
      'ADDRESS',
      'SELLER',
      'LIST DATE',
    ];
    const csvRows: string[][] = [];
    let row: string[] = [];
    this.lisitings.forEach((x) => {
      row = [];
      row.push(x.listingId);
      row.push(x.address.full);
      row.push(x.seller?.sellerName);
      row.push(this.getDate(x.listDate));
      csvRows.push(row);
    });
    const reportCsv = this.papa.unparse({ fields: csvColumns, data: csvRows });
    const blob = new Blob([reportCsv], { type: 'text/csv; charset=utf-8' });
    FileSaver.saveAs(blob, 'Listing' + '.csv');
  }

  listingShowing(list) {
    if (isDevMode()) {
      this.router.navigate(['/listing-showings'], { queryParams: { listingId: list.listingId, address: list.address.full } });
    } else {
      this.router.navigate(['/listing-showings'], { queryParams: { listingId: list.listingId, address: list.address.full } });
    }
  }

  redirect(list) {
    for (let index = 0; index < this.userProfile.Affiliations.length; index++) {
      if (this.userProfile.Affiliations[index].Name === "nne") {
        let url = "";
        let queryparam = `${"?oid=" + 4816 + "&mid=" + parseInt(list.listingId) + "&aid=" + parseInt(this.userProfile.Affiliations[index].AgentNumber) + "&code=" + this.environmentService.getIA9LightningKey()}`;
        if (isDevMode()) {
          url = "https://staging.domii.io/lightning-setup/edit" + queryparam;
        } else {
          url = "https://secure.domii.io/lightning-setup/edit" + queryparam;
        }
        window.open(url, "_self");
      } 
      // else if (this.userProfile.Affiliations[index].length === 0 || this.userProfile.Affiliations[index].Name !== "nne") {
      //   let url = "";
      //   if (isDevMode()) {
      //     url = `https://staging.domii.io/listing/${list.listingId}?idType=listingId`;
      //   } else {
      //     url = `https://secure.domii.io/listing/${list.listingId}?idType=listingId`;
      //   }
      //   window.open(url, "_self");
      // }
    }
  }

  getAgents(list) {
    this._listingId = list.listingId;
    this._showingsAddress = list.address.full;
    this.displayAgents = true;
    this.summaries = [];
    this.agentsList = [];
    this.selected = [];
    let url = this.environmentService.getIA9LightningUrl() + 'dashboard/property/listings/' + parseInt(list.listingId) + '/' + 'agents' + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.realtorService.getListingAgents(url, this.token, this.xClientId).subscribe(
      (response: any) => {
        this.agentsList = response.data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
    this.items = [
      {
        label: "Agents", icon: 'pi pi-users', command: (event) => {
          this.summaries = [];
          this.agentsList = [];
          let url = this.environmentService.getIA9LightningUrl() + 'dashboard/property/listings/' + parseInt(list.listingId) + '/' + 'agents' + '?code=' + this.environmentService.getIA9LightningKey();
          this.spinner.show();
          this.realtorService.getListingAgents(url, this.token, this.xClientId).subscribe(
            (response: any) => {
              this.agentsList = response.data;
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
            }
          );
        }
      },
      {
        label: 'Summary', icon: 'pi pi-book', command: (event) => {
          this.agentsList = [];
          this.summaries = [];
          let url = this.environmentService.getIA9LightningUrl() + 'dashboard/property/listings/' + parseInt(list.listingId) + '/' + 'summaries' + '?code=' + this.environmentService.getIA9LightningKey();
          this.spinner.show();
          this.realtorService.getShowings(url, this.token, this.xClientId).subscribe(
            (response: any) => {
              this.summaries.push(response.data);
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
            }
          );
        }
      },
    ];
    this.activeItem = this.items[0];
  }

  // Booked Showings
  _showingsAddress: string = ''
  getShowings() {
    this.displayShowings = true;
    this.totalBookedShowings = [];
    let url = this.environmentService.getIA9LightningUrl() + 'dashboard/property/listings/' + parseInt(this._listingId) + '/' + 'booked' + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.realtorService.getListingAgents(url, this.token, this.xClientId).subscribe(
      (response: any) => {
        this.totalBookedShowings = response.data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  // Feedbacks
  getFeedbacks() {
    this.displayBasic = true;
    this.feedbackDetails = [];
    let url = this.environmentService.getIA9LightningUrl() + 'dashboard/property/listings/' + parseInt(this._listingId) + '/' + 'feedbacks' + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.realtorService.getListingFeedbacks(url, this.token, this.xClientId).subscribe(
      (response: any) => {
        this.feedbackDetails = response.data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  /*  
  * Send Email Function
  * Share Multiple Function  
  * Share Single Function 
  * Get Agent Detail
  * Hide Send Email Diolog
  */

  shareMultiple() {
    if (this.selected.length !== 0) {
      this.mailBasic = true;
      for (let index = 0; index < this.selected.length; index++) {
        let selectedMails = this.selected.map(x => x.buyerAgent.eml.toLowerCase());
        let removeDuplicateMails = selectedMails.filter((el, i, a) => i === a.indexOf(el));
        this.mailForm.patchValue({
          "tos": removeDuplicateMails
        });
        this.getAgentDetails();
      }
    } else {
      this.toastr.error('Select atleast one!...');
    }
  }

  shareSingle(agent) {
    this.mailBasic = true;
    this.mailForm.patchValue({
      "tos": Array(agent.buyerAgent.eml.toLowerCase())
    });
    this.getAgentDetails();
  }

  sendMail() {
    this.submitted = true;
    if (this.mailForm.invalid) {
      return false;
    }
    this.xClientId = this.environmentService.getXClientId();
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'tools/sendmail?code=' + this.environmentService.getIA9LightningKey();
    this.realtorService.sendEmails(url, this.mailForm.value, this.xClientId, this.token).subscribe(response => {
      if (response['isSuccess'] === true) {
        this.submitted = false;
        this.mailForm.get("tos").setValue('');
        this.mailForm.get("subject").setValue('');
        this.mailForm.get("message").setValue(this.defaultText);
        this.spinner.hide();
        this.mailBasic = false;
        this.toastr.success('Mail sent successfully.');
      } else {
        this.toastr.warning('Something went wrong!..');
        this.spinner.hide();
      }
    }, error => {
      this.toastr.warning('Something went wrong!..');
      this.spinner.hide();
    });
  }

  getAgentDetails() {
    this.xClientId = this.environmentService.getXClientId();
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'agent/buyer/signin/' + '?code=' + this.environmentService.getIA9LightningKey();
    if (this.token) {
      this.realtorService.getRealtorProfileInfo(url, this.xClientId, this.token).subscribe(res => {
        if (res && res.data) {
          this.defaultText = `\n \n \n \n \n Thanks, \n ${res.data.agentName}, \n ${res.data.businessName} \n ${res.data.agentPhoneNumber}`
          this.spinner.hide();
        }
      }, error => {
        this.spinner.hide();
      })
    } else {
      this.spinner.hide();
      this.toastr.warning('Something went wrong!...');
    }
  }

  hideEmailDialog() {
    this.mailBasic = false;
  }

  /*  
   * Booked
   * Hide Booked dialog
   */

  totalBooked(agentData:any, showingDateTime:string[]) {
    this.showingAgentName = agentData.fn + " " + agentData.ln;
    this.showingAgentEmail = agentData.eml;
    this.showingPhoneNumber = agentData.phn;
    this.showingDateTime = showingDateTime;    
    this.displayBookedShowings = true;
  }
}
