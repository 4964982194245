export const PlanDetails = {
    PROF_SIGNUP_FEATURES: [
        "Congratulations! You've been personally selected to enroll in your agent's preferred professional team",
        "Once enrolled, your agent's customers will contact you directly from their app.",
        "And, as a bonus, you can receive live leads from Domii as well. Simply ACCEPT the lead and it's yours. All for $0.00/month."
    ],
    REALTOR_SIGNUP_FEATURES: [
        // 'Live Seller & Buyer leads based on your GPS location',
        // 'In-app sign-able contracts/sharing',
        // 'Instant Plot Plans',
        // 'Free Credit Reports',
        // 'Mortgage Calculator',
        // 'Lock-in Buyers',
        // 'Digital Business Card',
        // 'Get Pre-approvals',
        // 'Instant Directions',
        // 'Team Sharing - Pros'
        'Live Leads based on your GPS location. 100% unbiased',
        'Monthly Pre-foreclosures of homeowners going to foreclosure.',
        'Buyer-lock in. Share your app code with them and they are locked in.',
        'Sign-able and shareable contracts. No more paper, sign in-app.',
        'Invite Your Team to be on your app: Bankers, Home Inspectors, painters, lawyers, painters and landscapers. Share your code by sending them a text and they will appear on your app for your clients to see. It costs you nothing to invite them. It costs them $0 a month to appear on your app, and they may even get Domii leads as well.',
        'Plot Plans – fully illuminated plot plans for buyers to see perimeters.',
        'Tax Records – updated tax records to assist buyers and agents.',
        'Valuemii is Domii’s instant algorithm to determine home value ranges',
        'Agent Header – personalized photo and contact data for customers.',
        'Open House Platform – Buyers walk in, sign in and instantly they get a ”shared text” to download your app and a 7 day drip campaign.',
        'Set up your showings instantly and directly from your app, so easy!'
    ],
    DOMII_BASIC_FEATURES: [
        'In-App Scheduling',
        'Instant Access Showing System MLS',
        'Search Tool',
        '200 Vendors',
        'Mortgage Calculator',
        'GPS Directions',
        'Scheduling Showings',
        'Digital/Voice Notebooks',
        'Plot Plans'
    ],
    DOMII_PRO_FEATURES: [
        'Personalized Header',
        'Digital Business Card',
        'Share app W/Customer',
        'Customer Lock-In',
        'Business Pros - Referral Team',
        'In-app Contracts',
        'In-app Client Center',
        'In-app CMA Requests',
        'In-app Apps'
    ],
    DOMII_ADVANTAGE_FEATURES: [
        'Live GPS Leads'
    ],
    GPS_LEADS_DESC: 'These are leads that come through Domii that have not yet been assigned to an agent. Based on your global positioning at the time the lead comes in, you may get pinged to ACCEPT the lead. They are showing requests or MORE INFO requests from consumers. If you are the closest agent to the home being requested, you will be the first to get the ping notice (doorbell sound).',
    BUYER_NOTE: '** If your state permits cash back awards to buyers at closing. Domii requires you to give 10% of your gross fee earned to the buyer at the time of closing. See user agreement for details. (Gross commission: 200k sale x 2% = $4.000, buyer to receive $400 Cashback award)'
}
