import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { Papa } from 'ngx-papaparse';
import * as moment from 'moment';

@Component({
  selector: 'app-realtor-testimonial',
  templateUrl: './realtor-testimonial.component.html',
  styleUrls: ['./realtor-testimonial.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RealtorTestimonialComponent implements OnInit {

  testimonials: any = [];
  associateduserDetails: any = [];
  token: string = '';
  xClientId: string = '';
  mailBasic: boolean = false;
  displayErrorPopup: boolean = false;
  mailForm: FormGroup;
  submitted = false;
  defaultText: string = '';
  currentDate = new Date();

  constructor(
    private environmentService: EnvironmentService,
    private realtorService: RealtorService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private papa: Papa
  ) {
    this.token = sessionStorage.getItem('msal.idtoken');
    this.xClientId = this.environmentService.getXClientId();
  }

  ngOnInit(): void {
    this.testimonialList();
    this.initializeMailForm();
  }

  initializeMailForm() {
    this.mailForm = this.formBuilder.group({
      tos: new FormControl('', [Validators.required]),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', [Validators.required])
    });
  }

  get form() { return this.mailForm.controls; }

  testimonialList() {
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'agent/testimonials/' + '?code=' + this.environmentService.getIA9LightningKey();
    this.realtorService.getTestimonials(url, this.token, this.xClientId).subscribe(
      response => {
        this.testimonials = response['data'];
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      });
  }

  deleteTestimony(testimonial) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinner.show();
        const url = this.environmentService.getIA9LightningUrl() + 'agent/testimonials/' + testimonial.uid + '?code=' + this.environmentService.getIA9LightningKey();
        this.realtorService.deleteTestimonials(url, this.token, this.xClientId).subscribe(
          response => {
            this.testimonials = response['data'];
            this.messageService.add({ severity: 'info', summary: 'Delete', detail: 'Testimony Deleted ' });
            this.spinner.hide();
          },
          error => {
            this.spinner.hide();
            this.displayErrorPopup = true;
          });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected.' });
      }
    });
  }

  shareSingle(testimonial) {
    this.mailBasic = true;
    this.getAgentDetails(testimonial);
  }

  getAgentDetails(testimonial) {
    this.xClientId = this.environmentService.getXClientId();
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'agent/buyer/signin/' + '?code=' + this.environmentService.getIA9LightningKey();
    if (this.token) {
      this.realtorService.getRealtorProfileInfo(url, this.xClientId, this.token).subscribe(res => {
        this.spinner.hide();
        if (res && res.data) {
          this.defaultText = ` Hi there,\n Here's my testimonial: \n "${testimonial.testimony} -- ${testimonial.name}, ${testimonial.cityTown} (${moment(this.currentDate).format('YYYY-MM-DD')})"  \n \n \n \n Thanks, \n ${res.data.agentName}, \n ${res.data.businessName}, ${res.data.agentEmail}  \n ${res.data.agentPhoneNumber}`
        }
      }, error => {
      })
    } else {
      this.spinner.hide();
      this.toastr.warning('Something went wrong!...');
    }
  }

  sendMail() {
    this.submitted = true;
    if (this.mailForm.invalid) {
      return false;
    }
    this.mailForm.patchValue({
      "tos": Array(this.mailForm.value.tos)
    });
    this.xClientId = this.environmentService.getXClientId();
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'tools/sendmail?code=' + this.environmentService.getIA9LightningKey();
    this.realtorService.sendEmails(url, this.mailForm.value, this.xClientId, this.token).subscribe(response => {
      if (response['isSuccess'] === true) {
        this.submitted = false;
        this.mailForm.get("tos").setValue('');
        this.mailForm.get("subject").setValue('');
        this.mailForm.get("message").setValue(this.defaultText);
        this.spinner.hide();
        this.mailBasic = false;
        this.toastr.success('Mail sent successfully.');
      } else {
        this.toastr.warning('Something went wrong!..');
        this.spinner.hide();
      }
    }, error => {
      this.toastr.warning('Something went wrong!..');
      this.spinner.hide();
    });
  }

  hideEmailDialog() {
    this.mailBasic = false;
  }

  export() {
    const csvColumns = [
      'Name',
      'TESTIMONY',
      'LOCATION'
    ];
    const csvRows: string[][] = [];
    let row: string[] = [];
    this.testimonials.forEach((x) => {
      row = [];
      row.push(x.name);
      row.push(x.testimony);
      row.push(x.cityTown);
      csvRows.push(row);
    });
    const reportCsv = this.papa.unparse({ fields: csvColumns, data: csvRows });
    const blob = new Blob([reportCsv], { type: 'text/csv; charset=utf-8' });
    FileSaver.saveAs(blob, 'Testimonials' + '.csv');
  }
}
