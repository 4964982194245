import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonConstants } from 'src/app/constants/commonConstants';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { LoginService } from 'src/app/services/login/login.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { ConsumersRegModel } from 'src/app/models/consumersModel';
import { ConsumerService } from 'src/app/services/consumer.service';

@Component({
  selector: 'app-consumer-signup',
  templateUrl: './consumer-signup.component.html',
  styleUrls: ['./consumer-signup.component.scss']
})
export class ConsumerSignupComponent implements OnInit {

  consumerSignUpForm: FormGroup;
  showBenefits: boolean = true;
  isMobile: boolean = false;
  submitted = false;
  xClientId: string;
  mlsAffiliates: any[];
  termsAndConditons: any;
  tcURL: SafeResourceUrl;
  tcHeader: string = '';
  androidStoreUrl: string;
  iOSStoreUrl: string;
  displayEmailError: boolean = false;
  errorMessage: string = '';
  uniqueId: string;
  registrationCode: string;
  constructor(
    private formBuilder: FormBuilder,
    private consumerService: ConsumerService,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private loginService: LoginService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.uniqueId = sessionStorage.getItem('UNIQUE_ID');
    this.registrationCode = routeParams.get('regCode');
    console.log(this.registrationCode);
    this.xClientId = this.environmentService.getXClientId();
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    this.androidStoreUrl = this.environmentService.getAndroidStoreUrl();
    this.iOSStoreUrl = this.environmentService.getiOSStoreUrl();
    this.getMLSAffilates();
    this.buildSignUpForm();
    this.showHideBenefits(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showHideBenefits(event.target.innerWidth);
  }

  showHideBenefits(width) {
    if (width <= 1023) {
      if (this.isMobile == false) {
        this.isMobile = true;
        this.showBenefits = false;
      }
    }
    else {
      this.isMobile = false;
      this.showBenefits = true;
    }
  }

  getMLSAffilates() {
    const url = this.environmentService.getIA9V2Url() + "MlsAffiliates/?code=" + this.environmentService.getIA9V2Key();
    this.spinner.show();
    this.professionalService.getMLSAffiliates(url).subscribe(response => {
      this.spinner.hide();
      this.mlsAffiliates = response;
    });
  }

  buildSignUpForm() {
    this.consumerSignUpForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl(null, [Validators.required, Validators.pattern("[0-9\+\-]{10,12}")]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required]),
      registrationCode: new FormControl(''),
      agreeTandC: new FormControl(false, [Validators.requiredTrue])
    }, {
      validator: this.mustMatch('password', 'confirmPassword')
    });

    if(this.uniqueId){
      this.consumerSignUpForm.patchValue({
        "registrationCode": this.uniqueId
      });
    }
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (group: FormGroup) => {
      const control = group.controls[controlName];
      const matchingControl = group.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      }
      else {
        matchingControl.setErrors(null);
      }
    }
  }

  get form() { return this.consumerSignUpForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.consumerSignUpForm.invalid) {
      return;
    }
    this.registerUser();
  }

  registerUser() {
    const consumerPayload = new ConsumersRegModel();
    consumerPayload.firstName = this.consumerSignUpForm.get('firstName').value;
    consumerPayload.lastName = this.consumerSignUpForm.get('lastName').value;
    consumerPayload.phone = this.consumerSignUpForm.get('phone').value;
    consumerPayload.email = this.consumerSignUpForm.get('email').value;
    consumerPayload.password = this.consumerSignUpForm.get('password').value;
    consumerPayload.registrationCode = this.consumerSignUpForm.get('registrationCode').value;
    this.spinner.show();
    const url = this.environmentService.getIA9LightningUrl() + 'buyer/register?code=' + this.environmentService.getIA9LightningKey();
    this.consumerService.registerConsumer(url, consumerPayload, this.xClientId).subscribe(response => {
      this.spinner.hide();
      document.getElementById('openRegSuccessBtn').click();
      this.redirectToHome();
    }, error => {
      console.log(error);
      this.spinner.hide();
      document.getElementById('openRegFailedBtn').click();
    });
  }

  redirectToHome() {
    setTimeout(() => {
      if ($('#regSuccess').hasClass('show')) {
        document.getElementById('openRegSuccessBtn').click();
      }
      this.router.navigate(["/"]).then(result=>{window.location.href = this.environmentService.getFreeRedirectUrl();});
    }, 2000);
  }

  openTerms(event, type) {
    event.preventDefault();
    document.getElementById('openTermsConditionsBtn').click();
    (document.getElementById('t&CLogo') as HTMLImageElement).src = CommonConstants.imageBasePath + this.environmentService.getCompanyLogo();
    let url = '';
    if (type == 'TC') {
      this.tcHeader = 'Terms and Conditions';
      url = this.environmentService.getEULAUrl();
    }
    else if (type == 'PP') {
      this.tcHeader = 'Privacy Policy';
      url = this.environmentService.getPrivacyUrl();
    }
    this.tcURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  login() {
    this.loginService.login();
  }

  openAppStore() {
    window.open(this.iOSStoreUrl);
  }

  openPlayStore() {
    window.open(this.androidStoreUrl);
  }

  validateEmail(event: any) {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/validate/email/' + event.target.value + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.professionalService.getValidEmail(url, this.xClientId).subscribe((response) => {
      this.displayEmailError = false;
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      if (error.error.statusCode === 8002) {
        this.displayEmailError = true;
        this.errorMessage = 'An account with email address already exists.';
      }
    })
  }

  redirectToLogin() {
    this.loginService.login();
    this.displayEmailError = false;
  }

}
