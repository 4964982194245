<!-- Header -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
<nav class="navbar navbar-expand-md">
  <div class="container">
    <a class="navbar-brand" href="#"><img src="../../../assets/images/logo.png" alt="" /></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <div class="hea_con">
        <p>1013 Centre Road Suite 403-A, Wilmington, DE 19805</p>
        <p>
          <a href="tel:1-866-366-4446" style="color: #808080; text-decoration: none">1-1-866-366-4446</a>
          &nbsp; &nbsp; &nbsp;
          <span style="color: #f75a72"><strong> | &nbsp;&nbsp;&nbsp;</strong>&nbsp;
          </span>
          <a _ngcontent-c11="" class="email-style" href="mailto:support@ia9inc.com"
            style="color: #808080; text-decoration: none">support@ia9inc.com
          </a>
          &nbsp;&nbsp;&nbsp;
          <span style="color: #f75a72"><strong> |</strong>&nbsp;</span>
          &nbsp;&nbsp;&nbsp; Open 9AM-6PM Monday-Friday
        </p>
      </div>
    </div>
  </div>
</nav>

<!-- Main Section -->
<div class="home_banner">
  <div class="container">
    <h2>You're On Your Way!</h2>
  </div>
</div>
<section class="v-sec" style="padding-bottom: 200px">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <iframe src="https://www.youtube.com/embed/WAI-euh3Q5c" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <h2>The Domii App</h2>
        <p>
          <span style="font-weight: bold; color: #494949">Additional information about your new
            membership</span><br /><span style="color: #494949">Through the Domii mobile app, you can use Instant Access
            just as
            you would on a desktop. Additionally, you will be automatically
            enrolled in a 30-Day Free Trial of our all-access features. Domii's
            Mobile Office is designed to make stay-at-home work the new normal
            and improve efficiency, simplicity, and accessibility.
          </span>
        </p>
      </div>
      <div class="col-md-4">
        <iframe src="https://www.youtube.com/embed/TBt7umj-yrE" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <h2>Scheduling Platform</h2>
        <p>
          <span style="font-weight: bold; color: #494949">Additional information about your new
            membership</span><br /><span style="color: #494949">Through the Domii mobile app, you can use Instant Access
            just as
            you would on a desktop. Additionally, you will be automatically
            enrolled in a 30-Day Free Trial of our all-access features. Domii's
            Mobile Office is designed to make stay-at-home work the new normal
            and improve efficiency, simplicity, and accessibility.</span>
        </p>
        <h2>Need Help? Contact Us</h2>
        <ul class="social_icon">
          <li>
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <a href="mailto:support@ia9inc.com" style="color: black; text-decoration: none">support@ia9inc.com
            </a>
          </li>
          <li>
            <i class="fa fa-phone" aria-hidden="true"> </i>
            <a style="color: black" href="tel:1-866-366-4446">1-866-366-4446</a>
          </li>
        </ul>
      </div>
      <div class="col-md-4">
        <iframe src="https://www.youtube.com/embed/QYLacyABW5w" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <h2>Enroll Now</h2>
        <form [formGroup]="nerenForm">
          <div class="form_box">
            <ul>
              <li>
                <label for="text">Company Name*</label><br />
                <input type="text" formControlName="businessName" />
                <div *ngIf="
                    (nerenForm.get('businessName').invalid &&
                      nerenForm.get('businessName').touched) ||
                    nerenForm.get('businessName').dirty
                  ">
                  <small *ngIf="nerenForm.get('businessName').errors?.required" class="text-danger">Company Name is
                    required</small>
                </div>
              </li>
              <li>
                <label for="text">Full Name*</label><br />
                <input type="text" formControlName="fullname" />
                <div *ngIf="
                    (nerenForm.get('fullname').invalid &&
                      nerenForm.get('fullname').touched) ||
                    nerenForm.get('fullname').dirty
                  ">
                  <small *ngIf="nerenForm.get('fullname').errors?.required" class="text-danger">Full Name is
                    required</small>
                </div>
              </li>
              <li>
                <label for="text">Mobile Phone Number*</label><br />
                <input type="text" formControlName="mobileNo" mask="(000) 000-0000" />
                <div *ngIf="
                    (nerenForm.get('mobileNo').invalid &&
                      nerenForm.get('mobileNo').touched) ||
                    nerenForm.get('mobileNo').dirty
                  ">
                  <small *ngIf="nerenForm.get('mobileNo').errors?.required" class="text-danger">Mobile is
                    required</small>
                </div>
              </li>
              <li>
                <label for="text">Email*</label><br />
                <input type="text" formControlName="agentEmail" />
                <div *ngIf="
                    (nerenForm.get('agentEmail').invalid &&
                      nerenForm.get('agentEmail').touched) ||
                    nerenForm.get('agentEmail').dirty
                  ">
                  <small class="text-danger" *ngIf="nerenForm.get('agentEmail').errors?.required">Email address is
                    required</small>
                </div>
              </li>

              <li>Please complete all required fields.</li>

              <li>
                <button type="submit" (click)="onSubmit()" [disabled]="!nerenForm.valid">
                  Submit
                </button>
              </li>
            </ul>
          </div>
        </form>
        <!-- <p><b>Already have an account? Sign in</b></p> -->
      </div>
    </div>
  </div>
</section>

<!-- Footer -->
<!-- <div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <img src="../../../assets/images/logo-footer.svg" alt="" />
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12">
        <div class="footer_icon">
          <ul>
            <li>
              <i class="fa fa-facebook" aria-hidden="true"></i>
              <a href="https://www.facebook.com/domiihomes"></a>
            </li>
            <li>
              <i class="fa fa-instagram" aria-hidden="true"></i>
              <a href="https://www.instagram.com/domiihomes/"></a>
            </li>
            <li>
              <i class="fa fa-twitter" aria-hidden="true"></i>
              <a href="https://twitter.com/domiihomes"></a>
            </li>
            <li>
              <i class="fa fa-youtube-play" aria-hidden="true"></i>
              <a
                href="https://www.youtube.com/channel/UCQMLFzGp3RTmIPs0FGVhh-g"
              ></a>
            </li>
            <li>
              <i class="fa fa-pinterest-p" aria-hidden="true"></i>
              <a
                href="https://www.pinterest.com/domiihomes/?invite_code=8bc8b29b536845d3b1cd73111c35119f&sender=731975883094771765"
              >
              </a>
            </li>
          </ul>
          <p>
            <i class="fa fa-phone" aria-hidden="true"></i
            ><a style="color: white" href="tel:1-866-366-4446">1-866-366-4446</a>
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-5 col-sm-12">
        <ul class="menu">
          <li><a href="https://domii.io/about-us/">About</a></li>
          <li><a href="https://domii.io/blog/">Blog</a></li>
          <li><a href="https://domii.io/contact-us/">Contact</a></li>
          <li><a href="https://account.domii.io/home">Login</a></li>
        </ul>
      </div>
    </div>
  </div>
</div> -->
<!--
<div class="btm_fotter">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <p>
          Copyright © 2021 All rights reserved | For
          <a href="https://secure.domii.io/real-estate-agent">
            Real Estate Agents
          </a>
          |
          <a href="https://domii.io/privacy-policy/">Privacy Policy </a>
        </p>
      </div>
      <div class="col-md-6">
        <img src="../../assets/images/poweredBy-ia9.svg" alt="" />
      </div>
    </div>
  </div>
</div> -->