import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RealtorService } from '../../services/realtors/realtor.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
	FormBuilder,
} from '@angular/forms';

@Component({
	selector: 'app-certificate-home-valuation',
	templateUrl: './certificate-home-valuation.component.html',
	styleUrls: ['./certificate-home-valuation.component.scss'],
})
export class CertificateHomeValuationComponent implements OnInit {
	submitted: boolean = false;
	xClientId: string;
	leadId: string = '';
	address: string = '';
	firstName: string = '';
	valueRange: string = '';
	agentName: string = '';
	companyName: string = '';
	phoneNumber: string = '';
	errorMessage: string = '';
	url: string = '';
	hasAtLeastOneValue: boolean;
	hasValidZipcodeFormat: boolean;
	currentDate: Date;

	toastVisible = false;
	toastMessage = '';
	toastType = '';

	constructor(
		private realtorService: RealtorService,
		private environmentService: EnvironmentService,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService,
		private fb: FormBuilder
	) {
		console.log(this.route);
		this.leadId = this.route.snapshot.params['leadid'];
		this.xClientId = this.environmentService.getXClientId();
		this.currentDate = new Date();
	}

	ngOnInit() {
		// this.allowMoreZipCodes = true;
		this.url = this.environmentService.getIA9LightningUrl() + 'cma/' + this.leadId + '&code=' + this.environmentService.getIA9LightningKey();
		this.spinner.show();
		this.realtorService.validateCode(this.url, this.xClientId).subscribe((response: any) => {
			this.spinner.hide();
			if(response?.statusCode === 2000){
				this.firstName = response?.address;
				this.address = response?.firstName;
				this.valueRange = response?.valueRange;
				this.agentName = response?.agentName;
				this.companyName = response?.companyName;
				this.phoneNumber = response?.phoneNumber;
			}
		},
		(error) => {
			this.spinner.hide();
			this.errorMessage = error.error.description;
			document.getElementById('openAddZipCodeFailedBtn').click();
		});
	}
}
