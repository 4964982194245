<app-header></app-header>
<div class="mainContainer">
    <!-- <app-plan-ending-alert *ngIf="showPlanAlert"></app-plan-ending-alert> -->
    <div class="card noBorder">
        <div class="card-body">
            <div class="logoutMsg">Do you really want to logout?</div>
            <div class="actions">
                <button class="btn buttons" style="margin-right: 20px;" (click)="cancel()">Cancel</button>
                <br>
                <button class="btn buttons" (click)="logout()">Logout</button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>