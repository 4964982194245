<div>
    <div class="column">
        <div class="content one">
        </div>
    </div>
    <div class="column">
        <div class="content two">
            <div class="text-left">
                <ul class="nav navbar-nav" class="padnull listStyleNone">
                    <p-accordion class="accordion_set">

                        <!-- Realtor's  menuItem -->
                        <p-accordionTab header="Dashboard" [selected]="true" *ngIf="userProfile?.UserLevel === 1">
                            <li class="menuList" routerLink="/listings">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Listings</span>
                                </div>
                            </li>
                            <li class="menuList" routerLink="/realtor-instant-access">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Instant Access</span>
                                </div>
                            </li>
                            <li class="menuList" (click)="menu1.toggle($event)">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Open Houses</span>
                                </div>
                            </li>
                            <p-menu #menu1 [popup]="true" [model]="items"></p-menu>
                        </p-accordionTab>

                        <!-- Professional's  menuItem -->
                        <p-accordionTab header="Dashboard" [selected]="true" *ngIf="userProfile?.UserLevel > 1">
                            <li class="menuList" routerLink="/pricingPlans">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Service Requests</span>
                                </div>
                            </li>
                            <li class="menuList" routerLink="/leads">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">My Leads</span>
                                </div>
                            </li>
                        </p-accordionTab>

                        <!-- Setting's Menu both for realtor's and professionals -->
                        <p-accordionTab header="Settings" *ngIf="userProfile?.UserLevel">
                            <li class=" menuList" routerLink="/profile">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Profile</span>
                                </div>
                            </li>
                            <li class="menuList" *ngIf="userProfile?.UserLevel > 1"
                                routerLink="/membership/professional">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Membership</span>
                                </div>
                            </li>
                            <!-- <li class="menuList" *ngIf="userProfile?.UserLevel === 1" routerLink="/membership/realtors">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Membership</span>
                                </div>
                            </li> -->
                            <li class="menuList" *ngIf="userProfile?.UserLevel === 1" routerLink="/testimonials">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Testimonials</span>
                                </div>
                            </li>
                            <!-- <li class="menuList" *ngIf="userProfile?.UserLevel === 1" routerLink="/templates">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Templates</span>
                                </div>
                            </li> -->
                            <li class="menuList" routerLink="/logout">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Logout</span>
                                </div>
                            </li>
                        </p-accordionTab>

                        <!-- <p-accordionTab header="Profile Settings" *ngIf="isMobile">
                            <li class="menuList" *ngFor="let m of menuList" (click)="loadMenu(m)">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="{{m.img}}">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">{{m.menu}}</span>
                                </div>
                            </li>
                        </p-accordionTab> -->
                        <!-- <p-accordionTab header="Notifications" *ngIf="isMobile">
                            <li class="menuList">
                                <div class="col-md-12" class="menuItem">
                                    <span class="col-md-2">
                                        <img class="menuImg" src="../../../assets/images/mask-group.svg">
                                    </span>
                                    <span class="col-md-10 padnull vertAlignMiddle">Notifications</span>
                                </div>
                            </li>
                        </p-accordionTab> -->
                    </p-accordion>
                </ul>
            </div>
            <app-plan-ending-alert *ngIf="showPlanAlert"></app-plan-ending-alert>
        </div>
    </div>
</div>