<p-toast></p-toast>
<div class="container-fluid Business-professionalcontainer" *ngIf="userProfile?.UserType === 'CompanyAdmin'">
    <div class="row">
        <div class="col-md-5  col-xl-6 col-xs-12 padtop3 textCenter ipadfullwidth" style="background-image: url(../../../assets/images/homepage.jpg);background-size: cover;
        background-position: center center;">
            <div class="logo-section">
                <img class="signUpImg  logo-img" src="../../../assets/images/logo.svg" alt="signup page image">
            </div>
            <h2 class="wlcmMsg">Domii Onboarding</h2>
            <div class="gps-leadsdiv"></div>
        </div>

        <!-- Tell us about your company -->
        <div class="col-md-7 col-xl-6 col-xs-12 backgroundWhite ipadfullwidth"
            style="background: #ffeff1 0% 0% no-repeat padding-box; padding: 2%;">
            <div class="card noBorder">
                <div class="card-body">
                    <div style="text-align:center">
                        <div class="col-xs-12">
                            <h4 class="card-title">Please verify your company information</h4>
                        </div>
                    </div>
                    <form [formGroup]="whiteLabelAdminOnboardingForm">
                        <!-- row 1 -->
                        <div class="row">
                            <!-- Company Name -->
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="companyName">Company Name</label>
                                <input type="text" class="form-control" formControlName="companyName" id="companyName"
                                    placeholder="Enter Company Name"
                                    [ngClass]="{'is-invalid': submitted && form.companyName.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.companyName.errors?.required">
                                    <span>Please enter company name</span>
                                </div>
                            </div>
                            <!-- Address -->
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="address">Address</label>
                                <input type="text" class="form-control" formControlName="address" id="address"
                                    placeholder="Enter Full Address"
                                    [ngClass]="{'is-invalid': submitted && form.address.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.address.errors?.required">
                                    <span>Please enter full address</span>
                                </div>
                            </div>
                        </div>
                        <!-- row 2 -->
                        <div class="row">
                            <!-- Telephone Number -->
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="telno">Telephone Number</label>
                                <input type="telno" class="form-control" formControlName="telno" id="telno"
                                    placeholder="Enter Telephone Number"
                                    [ngClass]="{'is-invalid': submitted && form.telno.errors}">
                                <div class="invalid-feedback"
                                    *ngIf="(submitted || form.telno.touched) && form.telno.invalid">
                                    <div *ngIf="form.telno.errors?.required">Please enter telephone number</div>
                                    <div *ngIf="form.telno.errors?.pattern">Please enter 10 digit telephone number</div>

                                </div>
                            </div>
                            <!-- Website -->
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="website">Website</label>
                                <input type="website" class="form-control" formControlName="website" id="website"
                                    placeholder="Enter Website URL"
                                    [ngClass]="{'is-invalid': submitted && form.address.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.website.errors?.required">
                                    <span>Please web url</span>
                                </div>
                            </div>
                        </div>
                        <!-- row 3 -->
                        <div class="row">
                            <!-- Fax Number -->
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="fax">Fax Number</label>
                                <input type="fax" class="form-control" formControlName="fax" id="fax"
                                    placeholder="Enter Fax no" [ngClass]="{'is-invalid':submitted && form.fax.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.fax.errors?.required">
                                    <span>Please enter fax number</span>
                                </div>
                            </div>
                            <!-- Email Address -->
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="email">Email Address</label>
                                <input type="email" class="form-control" formControlName="email" id="email"
                                    placeholder="Enter Email Address"
                                    [ngClass]="{'is-invalid': (submitted || form.email.dirty) && form.email.errors}"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
                                <div class="invalid-feedback"
                                    *ngIf="(submitted || form.email.dirty) && form.email.errors">
                                    <span *ngIf="form.email.errors.required">Please enter email</span>
                                    <span *ngIf="form.email.errors.pattern">Please enter valid email</span>
                                </div>
                            </div>
                        </div>
                        <!-- row 4 -->
                        <div class="row">
                            <!-- company logo -->
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="logo">Company Logo
                                  <span class="tooltip-container">
                                    <img src="../../../assets/images/question-circle.svg" alt="help" />
                                    <span class="tooltiptext">Ideal size is 128(w) x 33(h)</span>
                                  </span>
                                </label>

                                <div class='file'>
                                    <label for='logo' class="comapny-name">
                                        <img src="../../../../assets/images/camera.svg"
                                            class="company-logo">{{fileName||"Upload Company Logo"}}
                                            <a *ngIf="imageData" href="{{imageData}}" download="{{fileName}}" target="_blank" style="float: right; padding: 4px;">View</a>
                                    </label>
                                    <input type='file' class="form-control" id="logo" placeholder="Upload Company Logo"
                                        accept="image/jpg,image/jpeg,image/png"
                                        (change)="onFileChange($event)" />

                                </div>
                            </div>
                            <!-- Verification Code -->
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="verificationCode">Verification Code</label>
                                <input type="text" class="form-control" formControlName="verificationCode"
                                    id="verificationCode" placeholder="Enter Verification Code"
                                    [ngClass]="{'is-invalid': submitted && form.verificationCode.errors}"
                                    [readonly]="true">
                            </div>
                        </div>

                        <!-- Add Social Links -->
                        <section>
                            <div class="font16px">
                                <h4 class="admin-info">Social Info</h4>
                            </div>
                            <div class="row">
                                <!-- Facebook -->
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="facebook">Facebook</label>
                                    <input type="text" class="form-control" formControlName="facebook" id="facebook"
                                        placeholder="Enter Facebook Url"
                                        [ngClass]="{'is-invalid': submitted && form.facebook.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.facebook.errors?.required">
                                        <span>Please enter facebook url</span>
                                    </div>
                                </div>
                                <!-- Instagram -->
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="instagram">Instagram</label>
                                    <input type="text" class="form-control" formControlName="instagram" id="instagram"
                                        placeholder="Enter Instagram Url"
                                        [ngClass]="{'is-invalid': submitted && form.instagram.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.instagram.errors?.required">
                                        <span>Please enter instagram url</span>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <!-- Twitter -->
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="twitter">Twitter</label>
                                    <input type="text" class="form-control" formControlName="twitter" id="twitter"
                                        placeholder="Enter Twitter Url"
                                        [ngClass]="{'is-invalid': submitted && form.twitter.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.twitter.errors?.required">
                                        <span>Please enter twitter url</span>
                                    </div>
                                </div>

                                <!-- Youtube -->
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="youtube">Youtube</label>
                                    <input type="text" class="form-control" formControlName="youtube" id="youtube"
                                        placeholder="Enter youtube Url"
                                        [ngClass]="{'is-invalid': submitted && form.youtube.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.youtube.errors?.required">
                                        <span>Please enter youtube url</span>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <!-- Pinterest -->
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="pinterest">Pinterest</label>
                                    <input type="text" class="form-control" formControlName="pinterest" id="pinterest"
                                        placeholder="Enter Pinterest Url"
                                        [ngClass]="{'is-invalid': submitted && form.pinterest.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.pinterest.errors?.required">
                                        <span>Please enter pinterest url</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="flexDspl row" style="margin-bottom: 100px;">
                            <div class="col-md-6 col-xs-12 ipadfullwidth">
                                <button type="submit" class="btn submitButtonnew" (click)="onSubmit()">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section>
    <p-dialog header="" [modal]="true" [(visible)]="welcomePopup" [style]="{width: '50%', padding: '60px'}"
        [baseZIndex]="10000" position="top" [draggable]="false" [resizable]="true"
        [breakpoints]="{'960px': '100vw', '640px': '100vw'}" contentStyleClass="admin-company-dialog">
        <div class="welcome-popup">
            <h5 class="welcome-heading">Welcome</h5>
            <a [href]="" download="file.txt" class="DownloadTemplate">Download this user migration template to start
                collecting your Domii users information
            </a>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Ok" iclass="p-button-text" (click)="welcomePage()"></button>
        </ng-template>
    </p-dialog>
</section> -->

<section *ngIf="userProfile?.UserType !== 'CompanyAdmin'">
    <p-dialog header="" [modal]="true" [(visible)]="companyAdmin" [style]="{width: '575px', padding: '10px'}"
        [baseZIndex]="10000" position="center" [draggable]="false" [resizable]="false">
        <div class="ErrorContainer">
            <div class="container">
                <div class="col-lg-12">
                    <section>
                        <p>Sorry, this page is for Company Admin only, you are not allowed to access this page.</p>
                    </section>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="backToHome()" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>

<!-- Error PopUp -->
<section>
    <p-dialog header="" [modal]="true" [(visible)]="displayErrorPopup" [style]="{width: '575px', padding: '10px'}"
        [baseZIndex]="10000" position="center" [draggable]="false" [resizable]="false">
        <div class="ErrorContainer">
            <div class="container">
                <div class="col-lg-12">
                    <section>
                        <p>Something went wrong. Please try again later or contact customer service,
                            <a href="tel:1-866-366-4446">1-866-366-4446</a>,
                            <a href="support@ia9inc.com">support@ia9inc.com</a>.
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="backToHome()" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>
