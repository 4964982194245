<div *ngIf="realtorProfile" class="alertContainer">
    <div class="card">
        <div class="alertIcon">
            <img src="../../../assets/images/alert_excl.svg">
        </div>
        <div class="card-body" style="padding-top: 0;">
            <div class="textCenter boldFont">
                <span>Free Membership Plan</span>
            </div>
            <div class="textCenter martop10px boldFont">
                <span>{{daysLeft}} days left!</span>
            </div>
            <div class="textCenter martop10px">
                <button type="button" class="btn upgradeBtn" data-toggle="modal" data-target="#comingSoon">Upgrade</button>
            </div>
        </div>
    </div>
</div>