<div class="certificate">
    <div class="header">
		<h1>Certificate</h1>
		<h2>Home Valuation</h2>
    </div>
    <div class="content">
        <p>{{ currentDate | date }}</p>
		<p>Property Address: {{ address }}</p>
		<br/>
		<p>Hi {{ firstName }}: </p>
		<br />
		<p>
			This evaluation is based on homes that have sold within the last six months and are similar in size, condition and location. Of course, no two homes are identical and usually offer different qualities and features that increase your price range instantly.
		</p>
		<br />
		<p>Value Range: <b>{{ valueRange }}</b></p>
		<br />
		<p>I am hoping you are enjoying this monthly update. It's so important to reflect on the value of your assets from time to time.</p>
		<br />
		<p>Feel free to reach out to me if you have any questions or would like to schedule an actual home evaluation at no charge</p>
		<br />
    </div>
    <div class="signature">
        <div style="display: flex; flex-direction: row; flex-wrap: nowrap;">
			<div style="align-self: auto; width: 40%;">
				<p>Sincerely,</p>
				<br />
				<span style="font-family: 'Lucida Handwriting', 'Apple Chancery', cursive; font-size: 24px;">
					{{ agentName }}
				</span><br />
				<p>
					{{ agentName }}<br />
					{{ companyName }}<br />
					{{ phoneNumber }}<br />
				</p>
			</div>
		</div>
    </div>
</div>