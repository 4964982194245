<div class="container-fluid Business-professionalcontainer">
	<div class="container add-zip-code-container">
		<form [formGroup]="addZipCodesForm">
			<div role="main" style="margin: 0 auto;">
				<div class="logo-section" style="width: 100%; text-align: center; margin: 10px; margin-bottom: 50px;">
					<img src="https://ia9incextra.blob.core.windows.net/blob/logo-header.svg" style="max-width: 100%; width: 450px;" />
				</div>
				<div class="form-all" style="box-shadow: 0 4px 4px rgba(87,100,126,.21); padding: 20px; text-align: center;">
					<div class="row" style="color: #962D53; border-bottom: 1px solid #C2708E;">
						<div class="col-md-12 col-xs-12 font16px mbrshipSection">
							<h3 class="select-planhead" style="font-size: 2em !important; margin: 0; font-weight:600 !important; line-height: 1.45; padding: 28px 14px;">Desired Zip Codes for Seller-Prospects</h3>
							<br />
						</div>
					</div>
					<div class="row" style="color: #962D53; border-bottom: 1px solid #C2708E; ">
						<div class="col-md-12 col-xs-12 font16px mbrshipSection">
							<p style="font-size: 1.125em !important; margin: 0; font-weight:600 !important; line-height: 1.45; padding: 28px 14px;">
								Welcome to domii®, the ultimate prospector! Please enter your top 5 zip
								codes to start receiving real-time prospects. Your personal domii®
								research-team will get to work as soon as your selections have been
								made.
							</p>
						</div>
					</div>
					<div style="padding: 28px 14px; color: #962D53; border-bottom: 1px solid #C2708E;">
						<div class="row">
							<div class="form-group col-lg-12" style="text-align: left !important;">
								<label for="fullName" style="color: #962D53;">Full Name</label>
								<div class="form-control" >{{fullName}}</div>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-lg-12" style="text-align: left !important;">
								<label for="email" style="color: #962D53;">Email</label>
								<div class="form-control" >{{ emailAddress }}</div>
							</div>
						</div>
						<div class="invalid-feedback text-center text-bold" *ngIf="submitted && !hasAtLeastOneValue">
							<span>Enter at least 1 zip code.</span>
						</div>
						<div class="invalid-feedback text-center text-bold" *ngIf="submitted && !hasValidZipcodeFormat">
							<span>Invalid US Zip Code format.</span>
						</div>
						<div formArrayName="keyValue" *ngFor="let fg of keyValueArrayFGControls; let i = index">
							<div class="form-group" [formGroup]="fg">	
								<div class="row">
									<div class="col-md-3 col-xs-12" style="text-align: left !important;">
										<label for="zipCode" style="color: #962D53;">Zip Code #{{ i + 1 }}:</label>
									</div>
									<div class="col-md-9 col-xs-12">
										<input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code" [ngClass]="{ 'is-invalid': submitted && (!hasAtLeastOneValue || !hasValidZipcodeFormat) }" />
									</div>
									<button *ngIf="i > 4" type="button" class="btn btn-warning" (click)="removeNewKeyValueFG(i)">Remove</button>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="form-group col-md-12 col-xs-12" *ngIf="allowMoreZipCodes">
								<button type="button" class="btn btn-secondary" (click)="addNewKeyValueFG()">Add Zip Code Field</button>
							</div>
						</div>
					</div>
					<div class="row" style="padding: 28px 14px; margin: 0 auto; text-align: center;">
						<div class="col-lg-12 ipadfullwidth">
							<button type="submit" class="btn btn-primary btn-lg submitButtonnew" (click)="onSubmit()">Submit</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<button id="openAddZipCodeSuccessBtn" [hidden]="true" data-toggle="modal"
		data-target="#addZipCodeSuccess">AddZipCodeSuccess</button>
	<div class="modal fade" id="addZipCodeSuccess" tabindex="-1" role="dialog" aria-labelledby="AddZipCodement Success"
		aria-hidden="true" #addZipCodeSuccess>
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content confirmationModal">
				<div class="textCenter">
					<img src="../../../assets/images/pay_success.svg" alt="Add Zip Code success" class="cnfrmImg">
				</div>
				<div class="martop20px cnfrmMsg">
					<strong>Add Zip Code Successful</strong>
				</div>
			</div>
		</div>
	</div>
	<button id="openAddZipCodeFailedBtn" [hidden]="true" data-toggle="modal"
		data-target="#addZipCodeFailed">AddZipCodeFailed</button>
	<div class="modal fade" id="addZipCodeFailed" tabindex="-1" role="dialog" aria-labelledby="AddZipCodement Failed"
		aria-hidden="true" #addZipCodeFailed>
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content confirmationModal">
				<div class="textCenter">
					<img src="../../../assets/images/pay_failed.svg" alt="Add Zip Code failed" class="cnfrmImg">
				</div>
				<div class="martop20px cnfrmMsg" style="text-align: center;">
					<strong>{{errorMessage}}</strong>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <app-footer></app-footer> -->
<div class="footer">
	<div class="footerRow1">
	  <div class="row col-md-12 col-xs-12 p-0">
		<div class="col-md-4 col-xs-12 ipadfullwidth p-0">
		  <div class="footerSec footertopsection">
			<div class="footerItem">
			  <img [src]="companyBrand" alt="Brand" id="footerBrand" class="brandImg" />
			</div>
			<div class="socialLinks footerItem">
			  <ul class="list-inline">
				<li class="list-inline-item">
				  <a href="https://www.facebook.com/domiihomes" target="_blank">
					<img
					  src="../../../assets/images/facebook.svg"
					  alt="facebook"
					/>
				  </a>
				</li>
				<li class="list-inline-item">
				  <a href="https://www.instagram.com/domiihomes/" target="_blank">
					<img
					  src="../../../assets/images/instagram.svg"
					  alt="instagram"
					/>
				  </a>
				</li>
				<li class="list-inline-item">
				  <a href="https://twitter.com/domiihomes" target="_blank">
					<img src="../../../assets/images/twitter.svg" alt="twitter" />
				  </a>
				</li>
				<li class="list-inline-item">
				  <a
					href="https://www.youtube.com/channel/UCQMLFzGp3RTmIPs0FGVhh-g" target="_blank"
				  >
					<img src="../../../assets/images/youtube.svg" alt="youtube" />
				  </a>
				</li>
				<li class="list-inline-item">
				  <a
					href="https://www.pinterest.com/domiihomes/?invite_code=8bc8b29b536845d3b1cd73111c35119f&sender=731975883094771765"
					target="_blank"><img
					  src="../../../assets/images/pinterest.svg"
					  alt="pinterest"
					/>
				  </a>
				</li>
			  </ul>
			</div>
		  </div>
		</div>
		<div class="row col-md-8 col-xs-12 ipadfullwidth p-0">
		  <div
			class="col-md-6 col-xs-12 ipadfullwidth d-block d-sm-none ipadvisible"
		  >
			<div class="footerSec footerItem footerappicon">
			  <img
				src="../../../assets/images/download-apple-store.png"
				alt="app store"
				class="appStore"
				(click)="openAppStore()"
			  />
			  <img
				src="../../../assets/images/download-google-play.png"
				alt="play store"
				class="playStore"
				(click)="openPlayStore()"
			  />
			</div>
		  </div>
		  <div class="col-md-8 col-xs-12 ipadfullwidth">
			<div class="footerSec ipadinline">
			  <div class="footerItem">
				<span><img src="../../../assets/images/phone.svg" /></span>
				<span class="contactNumber">
				  <a href="tel:1-866-366-4446" style="color: #ffffff"
					>1-866-366-4446</a
				  >
				</span>
			  </div>
			  <!-- <div class="footerItem">
							  <a href="mailto:support@ia9inc.com" style="color: #FFFFFF;">support@ia9inc.com</a>
						  </div> -->
			  <!-- <div class="footerItem">IA9 Inc. Wilmington DE 19805</div> -->
			  <div class="footerItem">
				<a href="https://account.domii.io/home">For Buyers</a>
			  </div>
			  <div class="footerItem">
				<a href="https://domii.io/about-us/">About</a>
			  </div>
			  <div class="footerItem">
				<a href="https://domii.io/blog/">Blog</a>
			  </div>
			  <div class="footerItem">
				<a href="https://domii.io/contact-us/">Contact</a>
			  </div>
			</div>
		  </div>
		  <div
			class="
			  col-md-4 col-xs-12
			  ipadfullwidth
			  d-none d-sm-block
			  ipadhidden
			  pr-0
			"
		  >
			<div class="footerSec footerItem footerappicon">
			  <img
				src="../../../assets/images/download-apple-store.png"
				alt="app store"
				class="appStore"
				(click)="openAppStore()"
			  />
			  <img
				src="../../../assets/images/download-google-play.png"
				alt="play store"
				class="playStore"
				(click)="openPlayStore()"
			  />
			</div>
		  </div>
		</div>
	  </div>
	</div>
	<div class="footerRow2">
	  <div class="col-md-12 col-xs-12">
		<div
		  class="footerItem footerbotrt ipadcenter d-block d-sm-none ipadvisible"
		>
		  <span>
			<img
			  src="../../../assets/images/poweredBy-ia9.svg"
			  class="poweredByIa9"
			  alt="powered by IA9"
			/>
		  </span>
		</div>
		<div class="footerItem footerbotlt">
		  <span class="ipadfullwidth"
			>Copyright &#169; {{ year }} All rights reserved</span
		  >
		  <span class="footerItem ipadfullwidth">
			<span class="separator1">|</span>
			<span
			  >For<a
				style="color: #ffffff"
				href="https://secure.domii.io/real-estate-agent"
			  >
				Real Estate Agents
			  </a></span
			>
			<span class="separator2">|</span>
			<span
			  ><a style="color: #ffffff" href="https://domii.io/privacy-policy/"
				>Privacy Policy
			  </a></span
			>
		  </span>
		</div>
  
		<div
		  class="footerItem footerbotrt ipadcenter d-none d-sm-block ipadhidden"
		>
		  <span>
			<img
			  src="../../../assets/images/poweredBy-ia9.svg"
			  class="poweredByIa9"
			  alt="powered by IA9"
			/>
		  </span>
		</div>
	  </div>
	</div>
  </div>
  