<app-header></app-header>
<div class="back_btn">
    <a routerLink="/dashboard">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="DashmainContainer">
    <div class="tbl_des">
        <div class="tbl">
            <div>
                <h1>Instant Access</h1>
                <h3>{{dropDownMenuIt}} Listings</h3>
            </div>
            <div class="d-flex">
                <div class="dropdown">
                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle">
                        {{ dropDownMenuIt }}
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item ng-star-inserted" (click)="getAllListings('all')">All</a>
                        <a class="dropdown-item ng-star-inserted" (click)="getAllListings('active')">Active</a>
                        <a class="dropdown-item ng-star-inserted" (click)="getAllListings('activeundercontract')">Active
                            under
                            contract</a>
                        <a class="dropdown-item ng-star-inserted" (click)="getAllListings('closed')">Closed</a>
                        <a class="dropdown-item ng-star-inserted" (click)="getAllListings('expired')">Expired</a>
                        <a class="dropdown-item ng-star-inserted" (click)="getAllListings('withdrawn')">Withdrawn</a>
                    </div>
                </div>
                <button class="btn export_btn" (click)="downloadListingAsPDF()">
                    <img src="../../../assets/images/ic-file-download.svg" alt="" />
                    Export
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="table-responsive">
                    <div class="card">
                        <p-table #dt [value]="lisitings" [rows]="5" responsiveLayout="scroll"
                            [showCurrentPageReport]="true" [paginator]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="listingId">LISTING ID<p-sortIcon field="listingId">
                                        </p-sortIcon>
                                    </th>
                                    <th>ADDRESS</th>
                                    <th>SELLER</th>
                                    <th>LIST DATE</th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td style="text-decoration: underline;color: #f75a72; cursor: pointer;"
                                        (click)="redirect(list)">
                                        {{ list.listingId }}
                                    </td>
                                    <td>{{ list.address.full }}</td>
                                    <td>{{ list.seller?.sellerName }}</td>
                                    <td>{{ getDate(list.listDate) }}</td>
                                    <td>
                                        <button (click)="getAgents(list)" pButton label="Showings"
                                            class="p-button-text">
                                        </button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Feedback Details -->
<section *ngIf="feedbackDetails">
    <p-dialog header="Feedbacks" [modal]="true" [(visible)]="displayBasic" [style]="{width: '40%', padding: '10px'}"
        [baseZIndex]="10000" position="top" [draggable]="false" [resizable]="false"
        [breakpoints]="{'960px': '100vw', '640px': '100vw'}">
        <div *ngIf="feedbackDetails.length !== 0">
            <div class="col-sm-12 box" *ngFor="let feedback of feedbackDetails ; let i = index;">
                <section>
                    <p>Agent Name :- {{feedback.buyerAgent.fn}} {{ feedback.buyerAgent.ln}}</p>
                    <p>Email Address :-
                        <a href="{{'mailto:' + feedback.buyerAgent.eml }}">
                            {{feedback.buyerAgent.eml}}
                        </a>
                    </p>
                    <p>Phone No :-
                        <a href="{{'tel:' + feedback.buyerAgent.phn}}">
                            {{feedback.buyerAgent.phn}}
                        </a>
                    </p>
                </section>
                <section>
                    <p>Property Address :- {{feedback.propertyAddress.full}}</p>
                </section>
                <section>
                    <p>Time :-
                        <span>{{feedback.showingCalendar.edt | date:'shortTime'}}</span> -
                        <span>{{feedback.showingCalendar.sdt | date:'shortTime'}}</span>
                    </p>
                </section>
                <section>
                    <p>Feedback :-</p>
                    <section *ngFor="let detail of feedback.feedbacks; let i = index;">
                        <p>
                            {{i + 1}}. {{ detail.que }} {{ detail.ans }}
                        </p>
                    </section>
                </section>
            </div>
        </div>
        <p *ngIf="feedbackDetails.length === 0" style="
        font-weight: bold;
        letter-spacing: 1px;
      ">No Feedback Yet!...</p>
        <ng-template pTemplate="footer">
            <p-button (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>

<!--Showing's > Agents List + summaries -->
<section>
    <p-dialog header="" [modal]="true" [(visible)]="displayAgents" [style]="{width: '50%', padding: '10px'}"
        [baseZIndex]="10000" position="top" [draggable]="false" [resizable]="true"
        [breakpoints]="{'960px': '100vw', '640px': '100vw'}">
        <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
        <div class="tbl_des" style="margin-bottom: 0;">
            <div class="row" *ngIf="agentsList.length > 0">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="table-responsive">
                        <div class="card">
                            <p-table [value]="agentsList" [(selection)]="selected" dataKey="buyerAgent.aid"
                                responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>
                                        <th>Agent Name</th>
                                        <th>Phone No</th>
                                        <th>Email Address</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-agent>
                                    <tr>
                                        <td>
                                            <p-tableCheckbox [value]="agent"></p-tableCheckbox>
                                        </td>
                                        <td>{{ agent.buyerAgent.fn }} {{ agent.buyerAgent.ln}}</td>
                                        <td>{{ agent.buyerAgent.phn }}</td>
                                        <td>{{ agent.buyerAgent.eml  }}</td>
                                        <td>
                                            <button style="padding: 0;
                                            margin: 0; border-radius: 0;" pButton (click)="shareSingle(agent)">
                                                <img style="width:30px; height:30px;"
                                                    src="../../../assets/images/share.png">
                                            </button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <button pButton pRipple label style="margin: 16px;" label="Share" icon="pi pi-check"
                                        class="p-button-text" (click)="shareMultiple(agent)">
                                    </button>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="summaries.length > 0">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="table-responsive">
                        <div class="card">
                            <p-table [value]="summaries">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Completed Showings</th>
                                        <th>Buyer Agents</th>
                                        <th>Total Showings</th>
                                        <th>Feedbacks</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-summary>
                                    <tr>
                                        <td style="text-decoration: underline;color:#f75a72;cursor: pointer;z-index: 999999;"
                                            (click)="getShowings()">
                                            <a>{{ summary.totalBookedShowings }}</a>
                                        </td>
                                        <td>{{ summary.totalBuyerAgents }}</td>
                                        <td>{{ summary.totalShowings }}</td>
                                        <td style="text-decoration: underline; color:#f75a72;cursor: pointer;z-index: 999999;"
                                            (click)="getFeedbacks()">
                                            <a>{{ summary.totalFeedbacks }}</a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="displayAgents=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>

<!-- Total Booked Showings -->
<section *ngIf="totalBookedShowings">
    <p-dialog header="Completed Showings for {{_showingsAddress}}" [modal]="true" [(visible)]="displayShowings"
        [style]="{width: '50%', padding: '10px'}" [baseZIndex]="10000" position="top" [draggable]="false"
        [resizable]="false" [breakpoints]="{'960px': '100vw', '640px': '100vw'}">
        <div class="tbl_des" style="margin-bottom: 0;">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="table-responsive">
                        <div class="card">
                            <p-table #dt [value]="totalBookedShowings">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th scope="col">Buyer Agent</th>
                                        <th scope="col">Seller</th>
                                        <th scope="col">Booked</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-agent>
                                    <tr>
                                        <td>
                                            {{ agent.buyerAgent.fn }}
                                            {{ agent.buyerAgent.ln }} <br>
                                            {{ agent.buyerAgent.phn }} <br>
                                            {{ agent.buyerAgent.eml }}
                                        </td>
                                        <td>
                                            {{ agent.seller.sellerName }}
                                            {{ agent.seller.sellersPhoneNumber }} <br>
                                            {{ agent.seller.sellersEmailAddress }}
                                        </td>
                                        <td style="text-decoration: underline; color:#f75a72;cursor: pointer;z-index: 999999;"
                                            (click)="totalBooked(agent.buyerAgent, agent.showingsDateTime)">
                                            <a>{{ agent.bookedCount }}</a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="displayShowings=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>

<!-- Send Email -->
<section>
    <p-dialog header="Share" [modal]="true" [(visible)]="mailBasic" [style]="{width: '80%', padding: '10px'}"
        [baseZIndex]="10000" position="top" [draggable]="false" [resizable]="false"
        [breakpoints]="{'960px': '100vw', '640px': '100vw'}">
        <div class="">
            <div class="">
                <form [formGroup]="mailForm">
                    <div class="form-group col-xs-12">
                        <textarea type="text" class="form-control" formControlName="tos" id="tos" placeholder="To"
                            [ngClass]="{'is-invalid': submitted && form.tos.errors}"></textarea>
                        <div class="invalid-feedback" *ngIf="submitted && form.tos.errors?.required">
                            <span>Please provide the email</span>
                        </div>
                    </div>
                    <div class="form-group  col-xs-12">
                        <input type="text" class="form-control" formControlName="subject" id="subject"
                            placeholder="Subject" [ngClass]="{'is-invalid': submitted && form.subject.errors}">
                        <div class="invalid-feedback" *ngIf="submitted && form.subject.errors?.required">
                            <span>Please mention the subject</span>
                        </div>
                    </div>
                    <div class="form-group  col-xs-12">
                        <textarea type="text" class="form-control" formControlName="message" id="message"
                            placeholder="Message" [ngClass]="{'is-invalid': submitted && form.message.errors}" rows="10"
                            cols="100" [(ngModel)]="defaultText">
                        </textarea>
                        <div class="invalid-feedback" *ngIf="submitted && form.message.errors?.required">
                            <span>Please mention the message</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Close" icon="pi pi-times" class="p-button-text"
                (click)="hideEmailDialog()"></button>
            <button pButton pRipple label="Send" icon="pi pi-check" class="p-button-text" (click)="sendMail()"></button>
        </ng-template>
    </p-dialog>
</section>

<!-- Total Booked Agent Details -->
<section>
    <p-dialog header="Booking Agent Details" [modal]="true" [(visible)]="displayBookedShowings"
        [style]="{width: '40%', padding: '10px'}" [baseZIndex]="10000" position="top" [draggable]="false"
        [resizable]="false" [breakpoints]="{'960px': '100vw', '640px': '100vw'}">
        <div class="col-sm-12 box">
            <section>
                <p>
                    <span>Agent Name</span> :- {{showingAgentName}}
                </p>
                <p>
                    <span>Email Address</span> :-
                    <a>{{showingAgentEmail}}</a>
                </p>
                <p>
                    <span>Phone No</span> :-
                    <a>{{showingPhoneNumber}}</a>
                </p>
                <p>
                    <span>Showings Date/Time</span> :-
                </p>
                <ul>
                    <li *ngFor="let dateTime of showingDateTime;">{{dateTime}}</li>                    
                </ul>
            </section>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="displayBookedShowings=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
</section>
<app-footer></app-footer>