import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as dropin from 'braintree-web-drop-in';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-renew-membership-plan-professional',
  templateUrl: './renew-membership-plan-professional.component.html',
  styleUrls: ['./renew-membership-plan-professional.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class RenewMembershipPlanProfessionalComponent implements OnInit {

  membershipProfessionalPlans: [] = [] as any;
  xClientId: string;
  dropInInstance: dropin.DropinModel;
  token: string = '';
  activeMembership: boolean;
  _email: string = '';
  _agentId: string = '';
  _payload = {
    "email": '',
    "paymentMethodToken": '',
    "MembershipPlanCode": '',
    "MembershipPlanPrice": '',
    "AgentId": ''
  }
  displayErrorPopup: boolean = false;

  constructor(
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private realtorService: RealtorService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.route.queryParams
      .subscribe(params => {
        this._email = params.email;
        this._agentId = params.agentid;
        this.activeMembership = params.activeMembership;
      });
    this._payload.email = this._email;
    this._payload.AgentId = this._agentId;
    this.token = sessionStorage.getItem('msal.idtoken');
    this.xClientId = this.environmentService.getXClientId();
  }

  ngOnInit(): void {
    // this.createDropIn();
    this.retrievePlans();
  }

  createDropIn() {
    const url = this.environmentService.getIA9LightningUrl() + 'registration/getpaymenttoken';
    this.professionalService.getClientAuthToken(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        dropin.create({
          authorization: response.data,
          container: document.getElementById('dropin-container')
        }).then((dropinInstance) => {
          this.dropInInstance = dropinInstance;
          this.spinner.hide();
        }).catch((error) => { console.log(error) });
      }
    }, (error) => {
      console.log(error);
    });
  }

  // retrievePlans() {
  //   const url = this.environmentService.getIA9LightningUrl() + 'payment/professional/plans?code=' + this.environmentService.getIA9LightningKey();
  //   this.professionalService.getMembershipPlans(url, this.xClientId).subscribe((response) => {
  //     if (response?.data) {
  //       for (var i = 0; i < response?.data.length; i++) {
  //         response.data[i].selected = false;
  //         if(response.data[i].price !== 'Free'){
  //           this.membershipProfessionalPlans = response?.data
  //         }
  //       }
  //     }
  //   }, (error) => {
  //   })
  // }

  retrievePlans() {
    const url = this.environmentService.getIA9LightningUrl() + 'payment/professional/plans?code=' + this.environmentService.getIA9LightningKey();
    this.professionalService.getMembershipPlans(url, this.xClientId).subscribe((response) => {
      if (response?.data) {
        const plansWithPrices = response.data.filter(plan => plan.price !== 'Free');
        for (var i = 0; i < plansWithPrices.length; i++) {
          plansWithPrices[i].selected = false;
        }
        this.membershipProfessionalPlans = plansWithPrices;
      }
    }, (error) => {
    })
  }

  radioChecked(data, i) {
    this._payload.MembershipPlanPrice = data.price;
    if(data.price !== '$0'){
      this.spinner.show();
      this.createDropIn();
    }else{
      if(this.dropInInstance){
        this.dropInInstance.teardown(function(err) {
            if (err) { console.error('An error occurred during teardown:', err); }
        });
      }
    }

    this.membershipProfessionalPlans.forEach((item: any) => {
      if (item.uid !== data.uid) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    })
    this._payload.MembershipPlanCode = data.code;
  }

  onSubscribe() {
    if (this._payload.MembershipPlanCode === '') {
      this.toastr.error('Please select a plan!..');
      return false;
    }

    if(this._payload.MembershipPlanPrice !== '$0'){
      this.dropInInstance.requestPaymentMethod().then((payload) => {
        this.subscribeMembershipPlan(payload);
      }).catch((error) => { console.log(error) });
    }else{
      this.subscribeMembershipPlan();
    }
  }

  subscribeMembershipPlan(payload?) {
    this.confirmationService.confirm({
      target: payload?.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._payload.paymentMethodToken = '';
        if(this._payload.MembershipPlanPrice !== '$0'){
          this._payload.paymentMethodToken = payload.nonce;
        }
        const url = this.environmentService.getIA9LightningUrl() + 'payment/subscribe?code=' + this.environmentService.getIA9LightningKey();
        this.spinner.show();
        this.realtorService.subscribePlan(url, this._payload, this.xClientId, this.token).subscribe((response) => {
          this.spinner.hide();
          if (response['isSuccess'] === true) {
            this.messageService.add({ severity: 'info', summary: 'Subscribe', detail: 'Subscribed to new membership plan.' });
            this.router.navigate(['/dashboard']);
          }
        },
          (error) => {
            this.displayErrorPopup = true;
            this.spinner.hide();
          });
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected.' });
      }
    });
  }
}
