<div class="container-fluid Business-professionalcontainer">
    <div class="row">
        <div class="col-md-5  col-xl-6 col-xs-12 padtop3 textCenter marbot110px ipadfullwidth">
            <div class="logo-section">
                <img class="signUpImg  logo-img" src="../../../assets/images/logo.svg" alt="signup page image">
            </div>
            <h2 class="wlcmMsg">Welcome to domii<sup>&#174;</sup></h2>
            <div class="gps-leadsdiv">
                <!-- <div class="list-views">
                    
                    <ul>
                        <li>
                            <div><img src="../../../assets/images/bullet.svg"></div>
                            <p>
                                <b>domii<sup>&#174;</sup></b> empowers <u>YOU</u> to be Listing Champion.
                            </p>
                            <br>
                        </li>
                        <li>
                            <div><img src="../../../assets/images/bullet.svg"></div>
                            <p>
                                <b>Our All-in-One Mobile App</b> has 100 productivity tools.
                            </p>
                            <br>
                        </li>
                        <li>
                            <div><img src="../../../assets/images/bullet.svg"></div>
                            <p><b>KEY advantages:</b></p>
                            <div class="innerList">
                                <ul>
                                    <li>domiiDetect<sup>&#8482;</sup></li>
                                    <li>domiiVerify<sup>&#8482;</sup></li>
                                    <li>domiiIntel<sup>&#8482;</sup></li>
                                </ul>
                            </div><br><br><br><br><br>
                        </li>
                        <li>
                            <div><img src="../../../assets/images/bullet.svg"></div>
                            <p>
                                <b>PLUS...</b> AI Predictive Listing Prospects Daily!
                            </p>
                            <br>
                        </li>
                    </ul>
                </div> -->
                <div>
                    <div style="display: flex; align-items: center; width: 100%; text-align: left; margin-bottom: 10px;">
                        <div style="margin-right: 8px;">
                            <img src="../../../assets/images/bullet.svg" style="width: 24px; height: 24px;">
                        </div>
                        <div>
                            <b>domii<sup>&#174;</sup></b> empowers <u>YOU</u> to be a Listing Champion.
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; width: 100%; text-align: left; margin-bottom: 10px;">
                        <div style="margin-right: 8px;">
                            <img src="../../../assets/images/bullet.svg" style="width: 24px; height: 24px;">
                        </div>
                        <div>
                            <b>Our All-in-One Mobile App</b><br/> has 100 productivity tools.
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; width: 100%; text-align: left; margin-bottom: 10px;">
                        <div style="margin-right: 8px;">
                            <img src="../../../assets/images/bullet.svg" style="width: 24px; height: 24px;">
                        </div>
                        <div>
                            <b>KEY advantages:</b>
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; width: 100%; text-align: left; margin-bottom: 10px;">
                        <div style="margin-left: 32px;">
                            <ul>
                                <li>domiiDetect<sup>&#8482;</sup></li>
                                <li>domiiVerify<sup>&#8482;</sup></li>
                                <li>domiiIntel<sup>&#8482;</sup></li>
                            </ul>
                        </div>
                    </div>
                    <div style="display: flex; align-items: center; width: 100%; text-align: left; margin-bottom: 10px;">
                        <div style="margin-right: 8px;">
                            <img src="../../../assets/images/bullet.svg" style="width: 24px; height: 24px;">
                        </div>
                        <div>
                            <b>PLUS...</b> AI Predictive Listing Prospects Daily!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7 col-xl-6 col-xs-12 backgroundWhite marbot110px ipadfullwidth padtop3">
            <div class="card noBorder">
                <div class="card-body">
                    <div style="text-align:center">
                        <div class="col-xs-12">
                            <h4 class="card-title">Real Estate Agent Sign Up</h4>
                        </div>
                    </div>
                    <form [formGroup]=" realtorSignUpForm">
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="firstName">First Name</label>
                                <input type="text" class="form-control" formControlName="firstName" id="firstName"
                                    placeholder="Enter First Name"
                                    [ngClass]="{'is-invalid': submitted && form.firstName.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.firstName.errors?.required">
                                    <span>Please enter First Name</span>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="lastName">Last Name</label>
                                <input type="text" class="form-control" formControlName="lastName" id="lastName"
                                    placeholder="Enter Last Name"
                                    [ngClass]="{'is-invalid': submitted && form.lastName.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.lastName.errors?.required">
                                    <span>Please enter Last Name</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="mobileNumber">Mobile Number</label>
                                <input type="phone" class="form-control" formControlName="mobileNumber"
                                    id="mobileNumber" placeholder="Enter Mobile Number"
                                    [ngClass]="{'is-invalid': (submitted || form.mobileNumber.dirty) && form.mobileNumber.errors}"
                                    maxlength="12">
                                <div class="invalid-feedback"
                                    *ngIf="(submitted || form.mobileNumber.dirty) && form.mobileNumber.errors">
                                    <span *ngIf="form.mobileNumber.errors.required">Please enter Mobile Number</span>
                                    <span *ngIf="form.mobileNumber.errors.pattern">Please enter Valid Mobile
                                        Number</span>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="emailId">Email Address</label>
                                <input type="email" class="form-control" formControlName="emailId" id="emailId"
                                    placeholder="Enter Email Address"
                                    [ngClass]="{'is-invalid': (submitted || form.emailId.dirty) && form.emailId.errors}"
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                                    (change)="validateEmail($event)">
                                <div class="invalid-feedback"
                                    *ngIf="(submitted || form.emailId.dirty) && form.emailId.errors">
                                    <span *ngIf="form.emailId.errors.required">Please enter Email</span>
                                    <span *ngIf="form.emailId.errors.pattern">Please enter Valid Email</span>
                                </div>
                                <div class="invalid-feedback" *ngIf="displayEmailError">
                                    <p>{{errorMessage }}<span style="text-decoration: underline;
                                    color: black;cursor: pointer;" (click)="redirectToLogin()"> Sign In</span></p>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="companyUrl">Company/Personal Website URL</label>
                                <input type="text" class="form-control" formControlName="companyUrl" id="companyUrl"
                                    placeholder="Enter Company/Personal Website URL"
                                    [ngClass]="{'is-invalid': (submitted || form.companyUrl.dirty) && form.companyUrl.errors }">
                                <div class="invalid-feedback" *ngIf="(submitted || form.companyUrl.dirty) && form.companyUrl.errors">
                                    <span>Please enter a valid Company/Personal Website URL</span>
                                </div>
                            </div>
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="licenseNumber">License Number (Optional)</label>
                                <input type="text" class="form-control" formControlName="licenseNumber"
                                    id="licenseNumber" placeholder="(Optional) License Number"
                                    [ngClass]="{'is-invalid': submitted && form.licenseNumber.errors}">
                                <div class="invalid-feedback"
                                    *ngIf="submitted && form.licenseNumber.errors?.required">
                                    <span>Please enter License Number</span>
                                </div>
                            </div>
                        </div>
                        <div class="veiwmorebtn">
                            <span class="viewMoreTxt" (click)="showMoreFields = !showMoreFields">
                                {{ !showMoreFields ? 'View Less': '(Optional) complete signup'}}
                                <img
                                    [src]="!showMoreFields ? '../../../assets/images/chevron-up.svg' : '../../../assets/images/chevron-down.svg'">
                            </span>
                        </div>
                        <div class="mbrshipSection" style="padding: 0;" [style.display]="(showMoreFields) ? 'none' : 'block' ">
                            <div class="additional">
                                <div class="font16px">
                                    <h4 class="license-info">License Info</h4>
                                </div>
                                <div class="row martop10px">
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="licenseState">State</label>
                                        <select class="form-control" formControlName="licenseState" id="licenseState"
                                            [ngClass]="{'is-invalid': submitted && form.licenseState.errors}">
                                            <option value="" class="option">Select State</option>
                                            <option *ngFor="let state of states" class="option" value="{{state.value}}">
                                                {{state.name}}</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="submitted && form.licenseState.errors?.required">
                                            <span>Please select State</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-xs-12">
                                        <label for="licenseExpiration">Expiration Date</label>
                                        <input type="date" class="form-control" formControlName="licenseExpiration"
                                            id="licenseExpiration" placeholder="YYYY-MM-DD" [min]="tomorrowDate"
                                            (change)="isExpirationDateValid()"
                                            [ngClass]="{'is-invalid': submitted && form.licenseExpiration.errors}">
                                        <div class="invalid-feedback" *ngIf="submitted && form.licenseExpiration.errors?.required">
                                            <span>Please enter Expiration Date</span>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="submitted && form.licenseExpiration.errors?.min">
                                            <span>Expiration Date has passed</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [style.display]="(showMoreFields) ? 'none' : 'block' " class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="mlsAffiliation">Primary MLS Affiliation</label>
                                <select class="form-control" formControlName="mlsAffiliation" id="mlsAffiliation"
                                    [ngClass]="{'is-invalid': submitted && form.mlsAffiliation.errors}" (change)="onChange($event)">
                                    <option value="" class="option">Select MLS Affiliation</option>
                                    <option *ngFor="let mls of mlsAffiliates" class="option" value="{{mls.vendorName}}">
                                        {{mls.vendorName}}</option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submitted && form.mlsAffiliation.errors?.required">
                                    <span>Please select MLS Affiliation</span>
                                </div>
                            </div>
                        </div>
                        <div [style.display]="(showMoreFields) ? 'none' : 'block' ">
                            <div class="font12px">
                                <h4 class="license-info">Company Info</h4>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="companyName">Company Name</label>
                                    <input type="text" class="form-control" formControlName="companyName" id="companyName"
                                        placeholder="Enter Company Name"
                                        [ngClass]="{'is-invalid': submitted && form.companyName.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.companyName.errors?.required">
                                        <span>Please enter Company Name</span>
                                    </div>
                                </div>
                                <!-- <div class="form-group col-md-6 col-xs-12">
                                    <label for="companyUrl">Company/Personal Website URL</label>
                                    <input type="text" class="form-control" formControlName="companyUrl" id="companyUrl"
                                        placeholder="Enter Company/Personal Website URL"
                                        [ngClass]="{'is-invalid': (submitted || form.companyUrl.dirty) && form.companyUrl.errors }">
                                    <div class="invalid-feedback" *ngIf="(submitted || form.companyUrl.dirty) && form.companyUrl.errors">
                                        <span>Please enter a valid Company/Personal Website URL</span>
                                    </div>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="address">Address1</label>
                                    <input type="text" class="form-control" formControlName="address" id="address"
                                        placeholder="Enter Address"
                                        [ngClass]="{'is-invalid': submitted && form.address.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.address.errors?.required">
                                        <span>Please enter Address</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="address2">Address2</label>
                                    <input type="text" class="form-control" formControlName="address2" id="address2"
                                        placeholder="Enter Address"
                                        [ngClass]="{'is-invalid': submitted && form.address2.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.address2.errors?.required">
                                        <span>Please enter Address</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="city">City</label>
                                    <input type="text" class="form-control" formControlName="city" id="city"
                                        placeholder="Enter City" [ngClass]="{'is-invalid': submitted && form.city.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.city.errors?.required">
                                        <span>Please enter City</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="state">State</label>
                                    <select class="form-control" formControlName="state" id="state"
                                        [ngClass]="{'is-invalid': submitted && form.state.errors}">
                                        <option value="" class="option">Select State</option>
                                        <option *ngFor="let state of states" class="option" value="{{state.value}}">
                                            {{state.name}}</option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="submitted && form.state.errors?.required">
                                        <span>Please select State</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="zip">Zip Code</label>
                                    <input type="text" class="form-control" formControlName="zip" id="zip"
                                        placeholder="Enter Zip Code"
                                        [ngClass]="{'is-invalid': submitted && form.zip.errors}">
                                    <div class="invalid-feedback" *ngIf="submitted && form.zip.errors?.required">
                                        <span>Please enter Zip Code</span>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-xs-12">
                                    <label for="preferredTms">Document Management</label>
                                    <!-- <p-multiSelect id="preferredTms" [options]="tmsArray" formControlName="preferredTms" optionLabel="value" optionValue="viewValue" [selectionLimit]="1" placeholder="Select a Document Manager" [(ngModel)]="defaultTMS"></p-multiSelect> -->
                                    <!-- <p-dropdown 
                                        id="preferredTms" 
                                        [options]="tmsArray" 
                                        formControlName="preferredTms" 
                                        optionLabel="value" 
                                        optionValue="viewValue" 
                                        placeholder="Select a Document Manager" 
                                        [(ngModel)]="defaultTMS">
                                    </p-dropdown> -->
                                    <select class="form-control" formControlName="preferredTms" id="preferredTms">
                                        <option value="None" selected>None</option>
                                        <option value="DotLoop">DotLoop</option>
                                        <option value="DocuSign">DocuSign</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-xs-12">
                                <div style="width: 100%;">
                                    <label for="promocode" >Promo Code</label>
                                    <div *ngIf="errorPromoCodeMessage !== ''"
                                        style="float:right; font-size: large; font-weight: bolder; margin-right: 70px; color: #ce0000 !important; ">
                                        <span>{{errorPromoCodeMessage}}</span>
                                    </div>
                                    <div *ngIf="validPromoCodeMessage !== ''"
                                        style="float:right; font-size: large; font-weight: bolder; margin-right: 70px; color: #28a745 !important; ">
                                        <span>{{validPromoCodeMessage}}</span>
                                    </div>
                                </div>
                                <div class="input-group mb-3">
                                    <input type="text" formControlName="promocode" class="form-control" placeholder="Promo Code"
                                        aria-label="Promo Code" aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary btn-outline-secondary" type="button"
                                            (click)="onApplyPromoCode()">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-xs-12 font16px mbrshipSection" *ngIf="membershipRealtorsPlans.length !== 0">
                            <h3 class="select-planhead">Select your Plan</h3>
                            <div class="row col-md-12 col-xs-12 feeDetails">
                                <div class="plan-area" *ngFor="let plan of membershipRealtorsPlans ; let i = index"
                                    [ngStyle]="{
                                    'border': plan.selected === true ? '1px solid #F75A72' : ''
                                  }">
                                    <div class="rdio rdio-primary radio-inline">
                                        <input [id]="'radio-' + i" type="radio" name="membershipPlan"
                                            [value]="plan.code"
                                            [ngClass]="{'is-invalid': submitted && form.membershipPlan.errors}"
                                            (change)="radioChecked(plan, i)" [checked]="plan.selected">
                                        <label [for]="'radio-' + i" [innerHTML]="plan.description">
                                        </label>
                                        <div class="rightside-area" *ngIf="plan.price !== 'Free'">
                                            <p>{{ plan.price }}
                                                <sub>/{{ plan.frequency }}</sub>
                                            </p>
                                        </div>
                                        <div class="rightside-area" *ngIf="plan.price === 'Free'">
                                            <p>{{ plan.price }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="invalid-feedback" *ngIf="submitted && form.membershipPlan.errors?.required">
                                    <span>Please select a plan</span>
                                </div>
                                <p class="corporate-pricing">
                                    <span class="">*Corporate Pricing available</span>,
                                    Please contact our Customer Service at
                                    <a href="tel:8663664446">1-866-366-4446</a>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div id="dropin-container"></div>
                        </div>
                        <div class="flexDspl  agree-termarea">
                            <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" formControlName="agreeTandC"
                                    id="agreeTandCCheck">
                                <label class="custom-control-label" for="agreeTandCCheck">
                                    I Agree to all the
                                    <span class="tCtext" (click)="openTerms($event, 'TC')">Terms and
                                        Conditions</span>
                                    and
                                    <span class="tCtext" (click)="openTerms($event, 'PP')">Privacy Policy</span>
                                </label>
                            </div>
                        </div>
                        <span class="invalid-feedback" *ngIf="submitted && form.agreeTandC.errors">*Please accept the
                            Terms</span>
                        <div class="flexDspl row">
                            <div class="col-md-6 col-xs-12 ipadfullwidth">
                                <button type="submit" class="btn submitButtonnew" (click)="onSubmit()" [disabled]="displayEmailError">Create
                                    Account
                                </button>
                                <p class="already-account">Already have an account? <a (click)="login()">Sign In</a></p>
                            </div>
                            <div class="col-md-6 col-xs-12 ipadfullwidth">
                                <span class="invalid-feedback" *ngIf="submitted && realtorSignUpForm.invalid">*Enter All
                                    the
                                    Fields</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<button id="openRegFailedBtn" [hidden]="true" data-toggle="modal" data-target="#regFailed">RegFailed</button>
<div class="modal fade" id="regFailed" tabindex="-1" role="dialog" aria-labelledby="Registration Failed"
    aria-hidden="true" #regFailed>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content confirmationModal">
            <div class="textCenter">
                <img src="../../../assets/images/pay_failed.svg" alt="registration failed" class="cnfrmImg">
            </div>
            <div class="martop20px cnfrmMsg">
                <strong>{{errorMsg}}</strong>
            </div>
        </div>
    </div>
</div>
<button id="openTermsConditionsBtn" [hidden]="true" data-toggle="modal" data-target="#termsConditions">T&C</button>
<div class="modal fade" id="termsConditions" tabindex="-1" role="dialog" aria-labelledby="Terms and Conditions"
    aria-hidden="true" #termsConditions>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header col-xs-12 col-md-12 modalTitle">
                <div class="col-md-4 col-xs-4 padnull">
                    <img src="" alt="Logo" id="t&CLogo" class="tcLogo">
                </div>
                <span class="col-md-4 col-xs-4 textCenter">{{tcHeader}}</span>
                <div class="col-md-4 col-xs-4" style="text-align: end;">
                    <img src="../../../assets/images/cross.svg" data-toggle="modal" data-target="#termsConditions">
                </div>
            </div>
            <div class="modal-body">
                <iframe [src]="tcURL" class="tcIFrame"></iframe>
            </div>
        </div>
    </div>
</div>
<button id="openSignupSuccessBtn" [hidden]="true" data-toggle="modal" data-target="#signupSuccess">SignupSuccess</button>
<div class="modal fade" id="signupSuccess" tabindex="-1" role="dialog" aria-labelledby="Signup Success"
  aria-hidden="true" data-backdrop="static" data-keyboard="false" #signupSuccess>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="background-color: #f75a72; align-items: center; padding: 2%; font-weight: 600;">
      <div class="textCenter">
         <h2 class="text-white">SUCCESS!<br/>Thank you for signing up!</h2>
      </div>
      <div class="textCenter" style="background-color: #fff; padding: 2%; min-width: 99%;">
        <h2 style="color: #f75a72;">DOWNLOAD domii<sup>&#174;</sup><br/>now and sign in!</h2>
            <img
                src="../../../assets/images/download-apple-store.png"
                alt="app store"
                class="appStore"
                (click)="openAppStore()"
                style="width: 250px"
            /><br/><br/>
            <img
                src="../../../assets/images/download-google-play.png"
                alt="play store"
                class="playStore"
                (click)="openPlayStore()"
                style="width: 250px"
            />
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
