import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserProfileModel } from '../../models/userProfileModel';
import { MsalService } from '@azure/msal-angular';
import { LoginService } from 'src/app/services/login/login.service';
import { RealtorService } from 'src/app/services/realtors/realtor.service';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import * as states from '../../../assets/data/states.json';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { TmsModel } from 'src/app/models/TmsModel';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  userLoggedIn: boolean;
  userProfile: UserProfileModel;
  response: any;
  userData: any;
  realtorProfile: boolean = false;
  submitted = false;
  imageSrc: string;
  states = [];
  mlsAffiliates: any[];
  // loading = false;
  showPlanAlert = false;
  data: any;
  tomorrowDate: string = '';
  toastVisible = false;
  toastMessage = '';
  toastType = '';
  listingInfo: any = {};
  token: any;
  openHouseForm: any;
  show: boolean;
  displayErrorPopup: boolean;
  toastr: any;
  xClientId: string;
  userType: string = '';
  displayPreviewHeader: boolean = false;
  headerbgColor: string = '';
  color: string = '';
  defaultColor: string = '';
  fontName: string = '';
  fontSize: string = '';
  urlPattern = '(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?';

  @ViewChild('file') file: ElementRef;
  @ViewChild('companyLogo') companyLogo: ElementRef;
  @ViewChild('licenseImg') licenseImg: ElementRef;
  @ViewChild('profileForm') profileForm: NgForm;


  tmsArray: TmsModel[] = [
    { value: 'None', viewValue: "None" },
    { value: 'DotLoop', viewValue: "DotLoop" },
    { value: 'DocuSign',viewValue: "DocuSign" }
  ];

  constructor(
    private msalService: MsalService,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private loginService: LoginService,
    private realtorService: RealtorService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {
    this.token = sessionStorage.getItem('msal.idtoken');
    console.log("Token", this.token);
    this.xClientId = this.environmentService.getXClientId();
    const paramToken = this.route.snapshot.queryParamMap.get("token");
    console.log("paramToken", paramToken);
    if (paramToken !== null) {
      this.token = paramToken;
      // this.tokenService.token = paramToken;
      sessionStorage.setItem('msal.idtoken', this.token);
    }
  }

  ngOnInit(): void {
    this.xClientId = this.environmentService.getXClientId();
    this.userProfile = new UserProfileModel();
    this.states = (states as any).default;
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    this.tomorrowDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    const user: any = this.msalService.getAccount();
    this.userLoggedIn = this.loginService.token !== '' || this.token != '';
    this.showHidePlanAlert(window.innerWidth);
    if(this.loginService.profileInfo==null){
      this.professionalService.getUserProfileParam(this.environmentService.getIA9CoreUrl() + 'User', this.token, this.xClientId).subscribe(
        response => {
          this.loginService.profileInfo = response;
          console.log("profile retrieved", this.loginService.profileInfo);
          this.bindB2Cdata();
        },
        error => {
          console.log("error while calling api")
        }
      );
    }
    console.log("profile after", this.loginService.profileInfo);
    if (this.userLoggedIn && this.loginService.profileInfo != null) {
      this.bindB2Cdata();
      console.log("binding b2c profile data")
    }
    this.getProfileInfo();
    this.loginService.profileUpdated.subscribe(
      (response) => {
        if (response != null) {
          this.userProfile.id = response.PersonId;
          this.userProfile.firstName = response.FirstName;
          this.userProfile.lastName = response.LastName;
          this.userProfile.emailId = response.Email;
          this.userProfile.mobileNumber = response.Phone;
          console.log("fetching updated profile")
          this.getProfile();
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  showDialog() {
    this.displayPreviewHeader = true;
  }

  getProfileInfo() {
    this.spinner.show();
    const url =
      this.environmentService.getIA9LightningUrl() +
      'agent/buyer/signin/' +
      '?code=' +
      this.environmentService.getIA9LightningKey();
    this.realtorService
      .getRealtorProfileInfo(url, this.xClientId, this.token)
      .subscribe(
        (res) => {
          if (res.data) {
            this.listingInfo = res.data;
            this.imageSrc = res.data.agentPhoto
              ? res.data.agentPhoto
              : '../../../assets/images/default.png';
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showHidePlanAlert(event.target.innerWidth);
  }

  showHidePlanAlert(width) {
    if (width <= 768) {
      this.showPlanAlert = true;
    } else {
      this.showPlanAlert = false;
    }
  }

  selectImage() {
    this.file.nativeElement.click();
  }

  selectCompanyPhoto() {
    this.companyLogo.nativeElement.click();
  }

  selectLicenseImg() {
    this.licenseImg.nativeElement.click();
  }

  bindB2Cdata() {
    console.log("b2C data bind")
    const response = this.loginService.profileInfo;
    this.userProfile.id = response.PersonId;
    this.userProfile.firstName = response.FirstName;
    this.userProfile.lastName = response.LastName;
    this.userProfile.emailId = response.Email;
    this.userProfile.mobileNumber = response.Phone;
    this.getProfile();
  }

  onFileChange(event, type) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const fileName = file.name;
      const index = fileName.lastIndexOf('.') + 1;
      const ext = fileName.substr(index, fileName.length).toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg') {
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (type == 'profileImg') {
            this.imageSrc = (reader.result as string).replace('jpeg', 'jpg');
            this.updatePhoto(this.imageSrc);
          } else if (type == 'companyLogo') {
            this.userProfile.companyPhoto = (reader.result as string).replace(
              'jpeg',
              'jpg'
            );
          } else if (type == 'licenseImg') {
            // add license photo here
          }
        };
      }
    }
  }

  updatePhoto(photoString) {
    const url = this.environmentService.getIA9CoreUrl() + 'AgentPhoto';
    const body = {
      photo: photoString,
    };
    this.spinner.show();
    // this.loading = true;
    this.professionalService.updatePhoto(url, body, this.token).subscribe(
      (response) => {
        // this.loading = false;
        this.toastVisible = true;
        this.toastMessage = 'Success!!! Photo updated';
        this.toastType = 'success';
        this.spinner.hide();
      },
      (error) => {
        // this.loading = false;
        this.toastVisible = true;
        this.toastMessage = 'Error!!! Try again later';
        this.toastType = 'error';
        this.spinner.hide();
      }
    );
  }

  getProfile() {
    this.xClientId = this.environmentService.getXClientId();
    //console.log("Get a profile")
    // this.loading = true;
    this.spinner.show();
    const url =
      this.environmentService.getIA9CoreUrl() +
      'AgentProfile?code=' +
      this.environmentService.getIA9CoreKey();
    this.professionalService.getUserProfileParam(url, this.token, this.xClientId).subscribe(
      (response) => {
        // this.loading = false;
        this.bindProfileData(response);
        this.spinner.hide();
      },
      (error) => {
        // console.log(error);
        // this.loading = false;
        this.spinner.hide();
      }
    );
  }

  bindProfileData(profile: any) {
    //console.log('bind profile data')
    //console.log(profile);
    this.userProfile.id = profile.AgentId;
    this.userProfile.companyName = profile.CompanyName;
    this.userProfile.companyUrl = profile.CompanyUrl;
    this.userProfile.companyPhoto = profile.CompanyPhoto;
    this.userProfile.mlsAffiliation = profile.MlsAffiliation;

    if(profile.PreferredTms==null){
      this.userProfile.preferredTms =  "None";
    }

    let tmsPresent = false;
    this.tmsArray.forEach(element => {
      if(element.viewValue==profile.PreferredTms){
        this.userProfile.preferredTms = element.viewValue;
        return;
      }
    });
    this.userProfile.licenses = profile.License;
    this.userProfile.licenseNumber = profile.License[0]?.LicenseNumber;
    this.userProfile.paymentToken = profile.PaymentToken;
    this.userProfile.locationOption = profile.LocationOption;
    this.userProfile.type = profile.AgentType;
    this.userProfile.address1 = profile.Address1;
    this.userProfile.address2 = profile.Address2;
    this.userProfile.city = profile.City;
    this.userProfile.state = profile.State;
    this.userProfile.zip = profile.Zip;
    this.userProfile.photo = profile.Photo;
    this.imageSrc = profile.Photo;
    this.userProfile.planId = profile.PlanId;
    this.headerbgColor = profile.UserPreference.backgroundColor;
    this.defaultColor = profile.UserPreference.backgroundColor;
    this.fontSize = profile.UserPreference.fontSize;
    this.fontName = profile.UserPreference.fontName;
    this.realtorProfile = this.userProfile.type === 1;
    //console.log(this.userProfile);
    if (this.realtorProfile) {
      sessionStorage.setItem('userType', '1');
      this.professionalService.emitRealtorProfile({
        AgentType: 1,
        b2cProfile: this.loginService.profileInfo,
      });
      this.getMLSAffilates();
    }
    for (const license of this.userProfile.licenses) {
      license.LicenseExpiration = this.datePipe.transform(
        license.LicenseExpiration,
        'yyyy-MM-dd'
      );
    }
  }

  selectedTransaction(event){
    this.userProfile.preferredTms = event.itemValue;
  }

  getMLSAffilates() {
    // this.loading = true;
    this.spinner.show();
    const url =
      this.environmentService.getIA9V2Url() +
      'MlsAffiliates/?code=' +
      this.environmentService.getIA9V2Key();
    this.professionalService.getMLSAffiliates(url).subscribe(
      (response) => {
        // this.loading = false;
        this.mlsAffiliates = response;
        this.spinner.hide();
      },
      (error) => {
        // console.log(error);
        // this.loading = false;
        this.spinner.hide();
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    const url =
      this.environmentService.getIA9CoreUrl() +
      'AgentProfile?code=' +
      this.environmentService.getIA9CoreKey();
    const body = {
      AgentId: this.userProfile.id,
      AgentType: this.userProfile.type,
      CompanyName: this.userProfile.companyName,
      CompanyUrl: this.userProfile.companyUrl,
      License: this.getLicenses(),
      PaymentToken:
        this.userProfile.paymentToken == null
          ? ''
          : this.userProfile.paymentToken,
      MlsAffiliation: this.userProfile.mlsAffiliation,
      PreferredTms: this.userProfile.preferredTms,
      LocationOption: this.userProfile.locationOption,
      CompanyPhoto: this.userProfile.companyPhoto,
      Address1: this.userProfile.address1,
      Address2: this.userProfile.address2,
      City: this.userProfile.city,
      State: this.userProfile.state,
      Zip: this.userProfile.zip,
      PlanId: this.userProfile.planId
    };
    //console.log(this.userProfile);

    // this.loading = true;
    this.spinner.show();
    this.professionalService.updateUserProfile(url, body, this.token).subscribe(
      (response) => {
        // this.loading = false;
        this.toastVisible = true;
        this.toastMessage = 'Success!!! Profile updated';
        this.toastType = 'success';
        this.spinner.hide();
      },
      (error) => {
        // console.log(error);
        // this.loading = false;
        this.toastVisible = true;
        this.toastMessage = 'Error!!! Try again later';
        this.toastType = 'error';
        this.spinner.hide();
      }
    );
  }

  getLicenses() {
    if (this.realtorProfile) {
      return this.userProfile.licenses;
    }
    if (this.userProfile.licenses.length > 0) {
      return [
        {
          LicenseState: this.userProfile.licenses[0].LicenseState,
          LicenseNumber: this.userProfile.licenseNumber,
          LicenseExpiration: this.userProfile.licenses[0].LicenseExpiration,
          Photo: this.userProfile.licenses[0].Photo,
        },
      ];
    } else {
      return [
        {
          LicenseState: null,
          LicenseNumber: this.userProfile.licenseNumber,
          LicenseExpiration: null,
          Photo: null,
        },
      ];
    }
  }

  addLicense() {
    this.userProfile.licenses.push({
      LicenseState: '',
      LicenseNumber: '',
      LicenseExpiration: '',
      Photo: null,
    });
  }

  removeLicense(index) {
    this.userProfile.licenses.splice(index, 1);
  }

  closeToast() {
    this.toastVisible = false;
  }

  isNumber(event) {
    const regex = new RegExp('[0-9]+');
    return regex.test(event.key);
  }

  isExpirationDateValid(event) {
    const expDate = new Date(this.profileForm.form.get(event.target.id).value);
    if (expDate.getTime() < new Date(this.tomorrowDate).getTime()) {
      this.profileForm.form.get(event.target.id).setErrors({ min: true });
    }
  }

  pickColour(value) {
    this.headerbgColor = value;
  }

  updateBGColor() {
    this.color = '';
    this.displayPreviewHeader = false;
    let _userPreference = {
      backgroundColor: this.headerbgColor,
      fontName: this.fontName,
      fontSize: this.fontSize
    };
    const url = this.environmentService.getIA9LightningUrl() + 'preference?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.realtorService.updateUserPreference(url, _userPreference, this.xClientId, this.token).subscribe((response) => {
      if (response['isSuccess'] === true) {
        this.getProfile();
        this.spinner.hide();
      }
    },
      (error) => {
        this.spinner.hide();
      });
  }

  cancel() {
    this.headerbgColor = this.defaultColor;
    this.color = '';
    this.displayPreviewHeader = false;
  }
}
