export class CompanyOnboardingModel {
  MembershipId: string;
    CompanyName: string;
    Address: string;
    MobilePhoneNumber: string;
    Website: string;
    FaxNumber: string;
    CompanyEmailAddress: string;
    OfficePhoneNumber:string
    FacebookUrl: string;
    InstagramUrl: string;
    TwitterUrl: string;
    YoutubeUrl: string;
    PintrestUrl: string;

    IsApproved: boolean;
    CompanyLogoFile: CompanyLogoFileModel;
}

export class CompanyLogoFileModel {
    description: string;
    fileName: string;
    docType: string;
    base64: string;
}
