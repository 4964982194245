<div class="container-fluid">
    <div class="row">
        <div class="col-md-7 col-xs-12 padtop3 textCenter marbot110px">
            <h2 class="wlcmMsg">Welcome to</h2>
            <img src="" id="companyLogo" alt="Logo">
            <img class="signInImg" src="../../../assets/images/login-wlc.svg" alt="SignIn page image">
        </div>
        <div class="col-md-5 col-xs-12 backgroundWhite marbot110px">
            <div class="card noBorder">
                <div class="card-body">
                    <h4 class="card-title" style="font-weight: 600;">Sign In</h4>
                    <form [formGroup]="loginForm" class="martop20px">
                        <div class="row">
                            <div class="form-group col-md-8 col-xs-12">
                                <label for="emailId">Email ID</label>
                                <input type="email" class="form-control inputField" formControlName="emailId" id="emailId" placeholder="Enter Email ID" [ngClass]="{'is-invalid': (submitted || form.emailId.dirty) && form.emailId.errors}"
                                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
                                <div class="invalid-feedback" *ngIf="(submitted || form.emailId.dirty) && form.emailId.errors">
                                    <span *ngIf="form.emailId.errors.required">Please enter Email</span>
                                    <span *ngIf="form.emailId.errors.pattern">Please enter Valid Email</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-8 col-xs-12">
                                <label for="password">Password</label>
                                <input type="password" class="form-control inputField" formControlName="password" id="password" placeholder="Enter Password" [ngClass]="{'is-invalid': submitted && form.password.errors}">
                                <div class="invalid-feedback" *ngIf="submitted && form.password.errors?.required">
                                    <span>Please enter Password</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 col-xs-12">
                                <a routerLink="/forgotPassword" class="fgtpwd">Forgot Password?</a>
                            </div>
                        </div>
                        <div class="flexDspl row martop20px">
                            <div class="col-md-8 col-xs-12">
                                <button type="submit" class="btn submitButton" (click)="onSubmit()">Sign In</button>
                            </div>
                            <span class="formErrMsg" *ngIf="submitted && loginForm.invalid">*Enter All the Fields</span>
                        </div>
                    </form>
                    <div class="martop20px">
                        Don't have an account, <a routerLink="/home"><strong>Sign Up</strong></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>