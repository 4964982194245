<app-header></app-header>
<div class="back_btn">
    <a routerLink="/realtor-instant-access">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="DashmainContainer">
    <section class="charts">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-6">
                <div class="bar-title">
                    <h1>7 Days Showings Performance</h1>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-6">
                <p-dropdown [options]="dateList" [(ngModel)]="selectedDate" optionLabel="name"
                    (onChange)="getDateChanged(selectedDate)">
                </p-dropdown>
                <!-- <p-calendar [(ngModel)]="_date" [maxDate]="dibaleFutureDates" [readonlyInput]="true" showIcon="true"
                    dateFormat="yy-mm-dd" placeholder="Date Range" (onSelect)="getDateChanged(_date)" inputId="range">
                </p-calendar> -->
            </div>
        </div>
        <br><br>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12 mb-xl-0 mb-lg-0 mb-md-0 mb-4">
                <p-chart type="bar" [data]="showingsData" [options]="basicOptions"></p-chart>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <p-chart type="pie" [data]="pieChartData" [options]="chartOptions" [style]="{'width': '50%'}"></p-chart>
            </div>
        </div>
        <br><br>
    </section>
    <div class="tbl_des">
        <div class="tbl">
            <h1>Instant Access Showings for {{_listingId}} - {{_Address}}</h1>
            <button class="btn export_btn" (click)="export()">
                <img src="../../../assets/images/ic-file-download.svg" alt="">Export
            </button>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="table-responsive">
                    <div class="card">
                        <p-table #dt [value]="lisitings">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th scope="col">Agent Name</th>
                                    <th scope="col">Phone No</th>
                                    <th scope="col">Email Address</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td>{{ list.fn }} {{ list.ln}}</td>
                                    <td>{{ list.phn }}</td>
                                    <td>{{ list.eml }}</td>
                                    <td>{{ list.st }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>