import { Component, isDevMode, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { Papa } from 'ngx-papaparse';
import { RealtorService } from 'src/app/services/realtors/realtor.service';

interface Dates {
  name: string,
  code: string
}

@Component({
  selector: 'app-listings-showing-realtors',
  templateUrl: './listings-showing-realtors.component.html',
  styleUrls: ['./listings-showing-realtors.component.scss']
})
export class ListingsShowingRealtorsComponent implements OnInit {

  token: string = '';
  xClientId: string;
  lisitings: any;
  _listingId: any = '';
  _Address: any = '';
  showingsData: any;
  basicOptions: any;
  chartOptions: any;
  _date: Date;
  // dibaleFutureDates = new Date();
  pieChartData: any;
  dateList: Dates[];
  selectedDate: Dates;

  constructor(
    private spinner: NgxSpinnerService,
    private realtorService: RealtorService,
    private environmentService: EnvironmentService,
    private route: ActivatedRoute,
    private papa: Papa
  ) {
    this.route.queryParams
      .subscribe(params => {
        this._listingId = params.listingId;
        this._Address = params.address;
      });
    this.token = sessionStorage.getItem('msal.idtoken');
  }

  ngOnInit(): void {
    if (isDevMode()) {
      this.xClientId = 'FEE8CC43-AFD8-4DA8-A4E3-6639745C4428';
    } else {
      this.xClientId = 'C606AF7F-9DA0-4360-B878-27DFA8A43238';
    }
    this._date = new Date();
    var _startDate = new Date();
    _startDate.setDate(_startDate.getDate() - 7);
    var _endDate = new Date();
    _endDate.setDate(_endDate.getDate() - 1);
    this.getDailySummary(moment(_startDate).format('YYYY-MM-DD'), moment(_endDate).format('YYYY-MM-DD'));
    this.getShowings();
    // this.dibaleFutureDates.setDate(this.dibaleFutureDates.getDate());
    this.pieChartData = {
      labels: ['A', 'B', 'C'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FFA726"
          ],
          hoverBackgroundColor: [
            "#64B5F6",
            "#81C784",
            "#FFB74D"
          ]
        }
      ]
    };
    this.dateList = [
      { name: '7 - days Performance', code: '7-days' },
      { name: '1 - month Performance', code: '1-month' },
      { name: '3 - month Performance', code: '3-month' },
      { name: '6 - month Performance', code: '6-month' }
    ];
  }

  getShowings() {
    let url = this.environmentService.getIA9LightningUrl() + 'dashboard/property/listings/' + parseInt(this._listingId) + '/' + 'showings' + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.realtorService.getListingShowings(url, this.token, this.xClientId).subscribe(
      (response: any) => {
        this.lisitings = response.data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getDateChanged(selectedDate): void {
    this._date = new Date();
    var _startDate = new Date();
    _startDate.setDate(_startDate.getDate() - 7);
    var _endDate = new Date();
    _endDate.setDate(_endDate.getDate() - 1);
    if (selectedDate.code === '7-days') {
      this.getDailySummary(moment(_startDate).format('YYYY-MM-DD'), moment(_endDate).format('YYYY-MM-DD'));
    } else if (selectedDate.code === '1-month') {
      this.getDailySummary(moment(_endDate).subtract(1, 'months').format('YYYY-MM-DD'), moment(_endDate).format('YYYY-MM-DD'));
    } else if (selectedDate.code === '3-month') {
      this.getDailySummary(moment(_endDate).subtract(3, 'months').format('YYYY-MM-DD'), moment(_endDate).format('YYYY-MM-DD'));
    } else if (selectedDate.code === '6-month') {
      this.getDailySummary(moment(_endDate).subtract(6, 'months').format('YYYY-MM-DD'), moment(_endDate).format('YYYY-MM-DD'));
    }
    // this._date = selectedDate;
    // var _pastDate = new Date(this._date);
    // _pastDate.setDate(_pastDate.getDate() - 7);
    // this.getDailySummary(moment(this._date).format('YYYY-MM-DD'), moment(_pastDate).format('YYYY-MM-DD'));
  }

  getDailySummary(_startDate, _endDate) {
    let url = this.environmentService.getIA9LightningUrl() + 'dashboard/property/listings/' + parseInt(this._listingId) + '/' + 'showings' + '/' + `${_startDate}` + '/' + `${_endDate}` + '?code=' + this.environmentService.getIA9LightningKey();
    this.spinner.show();
    this.realtorService.getShowingsDailySummary(url, this.token, this.xClientId).subscribe(
      (response: any) => {
        let _dateList: any = [];
        for (let index = 0; index < response.data.length; index++) {
          _dateList.push(moment(response.data[index].date).format('YYYY-MM-DD'));
          this.showingsData = {
            labels: _dateList,
            datasets: [
              {
                label: 'Showings',
                backgroundColor: '#f75a72',
                data: response.data[index].total
              }
            ]
          };
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  export() {
    const csvColumns = [
      'Agent Name',
      'Phone No',
      'Email Address',
      'Status'
    ];
    const csvRows: string[][] = [];
    let row: string[] = [];
    this.lisitings.forEach((x) => {
      row = [];
      row.push(x.fn + ' ' + x.ln);
      row.push(x.phn);
      row.push(x.eml);
      row.push(x.st);
      csvRows.push(row);
    });
    const reportCsv = this.papa.unparse({ fields: csvColumns, data: csvRows });
    const blob = new Blob([reportCsv], { type: 'text/csv; charset=utf-8' });
    FileSaver.saveAs(blob, 'listingShowings' + '.csv');
  }

  // Last7Dates() {
  //   var _date = new Date();
  //   let _currentDate = moment(_date).format('YYYY-MM-DD');
  //   _date.setDate(_date.getDate() - 7);
  //   let _weekAgoDate = moment(_date).format('YYYY-MM-DD');
  //   var day = 1000 * 60 * 60 * 24;
  //   let date1 = new Date(_weekAgoDate.toString());
  //   let date2 = new Date(_currentDate.toString());
  //   var diff = (date2.getTime() - date1.getTime()) / day;
  //   let _dateList: any = [];
  //   for (var i = 0; i <= diff; i++) {
  //     var xx = date1.getTime() + day * i;
  //     var yy = new Date(xx);
  //     _dateList.push(yy.getFullYear() + "-" + (yy.getMonth() + 1) + "-" + yy.getDate());
  //   }
  //   console.log(_dateList);
  // }
}
