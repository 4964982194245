<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#ff00a3" type="ball-atom" [fullScreen]="true">
  <p style="color: rgb(255, 10, 145)">domii®</p>
</ngx-spinner>

<!--remove the following code when the API is integrated to update plan-->
<div class="modal fade" id="comingSoon" tabindex="-1" role="dialog" aria-labelledby="Coming Soon" aria-hidden="true"
  #comingSoon>
  <div class="modal-dialog modal modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body textCenter" style="padding: 4rem">
        <img src="../../../assets/images/server_down.svg" alt="server error" />
        <div style="font-size: 20px; font-weight: bold">
          To upgrade your plan,<br /><span class="small">contact customer support @ 1-866-366-4446 or
            <a style="color: #007bff !important; text-decoration: none"
              href="mailto:support@ia9inc.com">support@ia9inc.com</a></span><br />
          Thank you.
        </div>
      </div>
    </div>
  </div>
</div>