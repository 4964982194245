import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabellingService {

  constructor(
    private http: HttpClient,
    private tokenService: TokenService) { }

  verifyMembership(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  updateMembership(url, payload, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.post(url, payload, { headers });
  }

  addUser(url, payload, token, clientId){
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
      return this.http.post(url, payload, { headers });
  }

  updateUser(url, payload, token, clientId){
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
      return this.http.post(url, payload, { headers });
  }

  user(url, token, clientId){
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
      return this.http.post(url, null ,{ headers });
  }

  getMembership(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  updateComapny(url, clientId, body, token) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + token)
      .set('X_CLIENT_ID', clientId);
    return this.http.post(url, body, { headers });
  }

  getUsers(url, token, clientId) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token);
    return this.http.get(url, { headers: headers }).pipe(map(res => res as any));
  }

  fileUpload(url, token, formData,clientId) {
    var headers = new HttpHeaders()
      .set('X_CLIENT_ID', clientId)
      .set('Authorization', 'Bearer ' + token)
      .set('Accept', 'application/json');
    return this.http.post(url, formData, { headers: headers }).pipe(map(res => res as any));
  }
}
