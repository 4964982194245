<div class="container-fluid">
    <div class="row">
        <div class="col-md-7 col-xs-12 padtop3 textCenter marbot110px">
            <h2 class="wlcmMsg">Welcome to</h2>
            <img src="" id="companyLogo" alt="Logo">
            <img class="mbrshpImg" src="../../../assets/images/mbrship-wlc.svg" alt="membership page image">
        </div>
        <div class="col-md-5 col-xs-12 backgroundWhite marbot110px">
            <div class="card noBorder">
                <div class="card-body">
                    <h4 class="card-title" style="font-weight: bold">
                        Hi Jerry Hart
                    </h4>
                    <div class="margin20px font16px">
                        You are Invited to get the PRO Membership with Domii, Please Click the Pay to get Membership. 
                    </div>
                    <div class="flexDspl font16px">
                        <div>Fee Details</div>
                        <div>
                            <p><strong>10$</strong><sub>per month</sub></p>
                        </div>
                    </div>
                    <form class="margin20px font16px" #mbrshipForm="ngForm" (ngSubmit)="goToPayment()">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="agreeTandC" id="agreeTandC" [(ngModel)]="agreedTC" 
                                #agreeTandC="ngModel" required>
                                <label class="form-check-label" for="agreeTandC">I Agree to all the <a href="#">Terms, Privacy Policy.</a></label>
                            </div>
                            <span class="formErrMsg" *ngIf="submitted && agreeTandC.invalid">Please accept the Terms</span>
                        </div>
                        <div class="martop20px">
                            <button type="submit" class="btn submitButton">Pay</button>
                        </div>
                        <div class="martop20px">
                            <button type="button" class="btn cancelButton">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>