<app-header></app-header>
<div class="back_btn">
    <a routerLink="/">
        <img src="../../../assets/images/back_arrow.svg" alt="">
    </a>
</div>
<div class="DashmainContainer">
    <div class="container">
        <div class="col-lg-12">
            <section>
                <p>Sorry, this page is for Realtors and Professionals only. Please login to your mobile device to use
                    your the Domii app.</p>
            </section>
        </div>
    </div>
</div>
<app-footer></app-footer>