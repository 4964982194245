import { Component, isDevMode, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfessionalService } from 'src/app/services/professional/professional.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { BroadcastService } from '@azure/msal-angular';
import { CurrencyPipe } from '@angular/common';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss'],
})
export class ListingsComponent implements OnInit {
  basicData: any;
  basicDataBar: any;
  basicOptions: any;
  multiAxisData: any;
  chartOptions: any;
  multiAxisOptions: any;
  stackedData: any;
  stackedOptions: any;
  horizontalOptions: any;
  subscription: Subscription;
  first = 0;
  rows = 10;
  lisitings: any;
  rangeDates: Date[];
  startDate: Date;
  endDate: Date;
  cols: any[];
  exportColumns: any[];
  userProfile: any;
  currentDate: string = '';
  currentTime: string = '';
  token: string = '';
  xClientId: string;
  ListingSummary: any;
  dropDownMenuIt: string = 'all';

  constructor(
    private primengConfig: PrimeNGConfig,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private professionalService: ProfessionalService,
    private environmentService: EnvironmentService,
    private broadcastService: BroadcastService,
    private currencyPipe: CurrencyPipe,
    private papa: Papa
  ) {
    this.startDate = moment().subtract(1, 'month').toDate();
    this.endDate = moment().toDate();
    this.currentDate = moment().format('L');
    this.currentTime = moment().format('LT');
    this.token = sessionStorage.getItem('msal.idtoken');
  }

  ngOnInit(): void {
    if (isDevMode()) {
      this.xClientId = 'FEE8CC43-AFD8-4DA8-A4E3-6639745C4428';
    } else {
      this.xClientId = 'C606AF7F-9DA0-4360-B878-27DFA8A43238';
    }
    this.primengConfig.ripple = true;

    this.getUserDetails();
    this.getDateChanged([null, null]);
    this.getAllListings('all');
    this.changeRange();
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.lisitings
      ? this.first === this.lisitings.length - this.rows
      : true;
  }

  isFirstPage(): boolean {
    return this.lisitings ? this.first === 0 : true;
  }

  getUserDetails() {
    this.spinner.show();
    const url = this.environmentService.getIA9CoreUrl() + 'User';
    this.professionalService.getUserInfo(url, this.token).subscribe(
      (response) => {
        this.userProfile = response;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  dateChanged() {
    this.spinner.show();
    let updatedRange = [];
    this.rangeDates.forEach((element) => {
      let date = JSON.stringify(new Date(element)).slice(1, 11);
      updatedRange.push(date);
    });

    const url =
      this.environmentService.getIA9LightningUrl() +
      'dashboard/property/listings/summaries/yearly/' +
      updatedRange[0] +
      '/' +
      updatedRange[1] +
      '?code=' +
      this.environmentService.getIA9LightningKey();
    this.professionalService
      .getWidgetInfo(url, this.token, this.xClientId)
      .subscribe(
        (response: any) => {
          // console.log(response.data);
          const responseData = [];
          response.data.map((list) => {
            let isExists = responseData.findIndex(element => element.status == list.status);
            if(isExists > -1){
              responseData[isExists].currYear = list.currYear;
              responseData[isExists].prevYear = list.prevYear;
              responseData[isExists].currCount += list.currCount;
              responseData[isExists].prevCount = list.prevCount;
              responseData[isExists].currPercentage = list.currPercentage;
              responseData[isExists].prevPercentage = list.prevPercentage;
              responseData[isExists].currIsUpThanPrev = list.currIsUpThanPrev;
            }else{
              responseData.push(list);
            }
          });
          this.ListingSummary = responseData;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }
  getDateChanged(range) {
    if (range[0] == null && range[1] == null) {
      var nowdate = new Date();
      var monthStartDay = new Date(
        nowdate.getFullYear(),
        nowdate.getMonth(),
        2
      );
      var monthEndDay = new Date();

      this.rangeDates = [monthStartDay, monthEndDay];
      this.dateChanged();
    } else if (range[0] != null && range[1] != null) {
      var monthStartDay = new Date(
        range[0].getFullYear(),
        range[0].getMonth(),
        2
      );
      var monthEndDay = new Date(
        range[1].getFullYear(),
        range[1].getMonth() + 1,
        1
      );
      this.rangeDates = [monthStartDay, monthEndDay];
      this.dateChanged();
    } else {
      return;
    }
  }
  changeRange(months?){
    this.startDate = moment(this.endDate).subtract(months?.target.value, 'month').toDate();
    this.getDateChanged([this.startDate,this.endDate]);
  }

  getAllListings(commend) {
    console.log(commend);
    switch (commend) {
      case 'all': {
        this.dropDownMenuIt = 'All';
        break;
      }
      case 'active': {
        this.dropDownMenuIt = 'Active';
        break;
      }
      case 'activeundercontract': {
        this.dropDownMenuIt = 'Active under contract';
        break;
      }
      case 'closed': {
        this.dropDownMenuIt = 'Closed';
        break;
      }
      case 'expired': {
        this.dropDownMenuIt = 'Expired';
        break;
      }
      case 'withdrawn': {
        this.dropDownMenuIt = 'Withdrawn';
        break;
      }
    }
    let params = {
      cmd: commend,
      date: JSON.stringify(new Date()).slice(1, 11),
    };

    let url =
      this.environmentService.getIA9LightningUrl() +
      'dashboard/property/listings/status/' +
      params.cmd +
      '/' +
      params.date +
      '?code=' +
      this.environmentService.getIA9LightningKey();
    this.professionalService
      .getALlListing(url, this.token, this.xClientId)
      .subscribe(
        (response: any) => {
          this.lisitings = response.data;
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  getDate(date) {
    if (date) {
      return new Date(date).toISOString().slice(0, 10);
    } else {
      return null;
    }
  }

  downloadListingAsPDF() {
    console.log('downloading...');
    const csvColumns = [
      'LISTING ID',
      'ADDRESS',
      'SELLER',
      'LIST DATE',
      'LIST PRICE',
      'CLOSING DATE',
    ];
    const csvRows: string[][] = [];
    let row: string[] = [];
    this.lisitings.forEach((x) => {
      row = [];
      row.push(x.listingId);
      row.push(x.address.full);
      row.push(x.seller?.sellerName);
      row.push(this.getDate(x.listDate));
      row.push(this.currencyPipe.transform(x.listPrice, 'USD'));
      row.push(this.getDate(x.closingDate));
      csvRows.push(row);
    });

    const reportCsv = this.papa.unparse({ fields: csvColumns, data: csvRows });
    const blob = new Blob([reportCsv], { type: 'text/csv; charset=utf-8' });
    FileSaver.saveAs(blob, 'Listing' + '.csv');
  }

  listingDetails(id) {
    let url = "";
    if (isDevMode()) {
      url = `https://staging.domii.io/listing/${id}` + '?idType=listingId';
    } else {
      url = `https://secure.domii.io/listing/${id}` + '?idType=listingId';
    }
    window.open(url, "_self");
  }
}
